import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import IconArchive from 'components/Icons/IconArchive';
import IconEyeClosed from 'components/Icons/IconEyeClosed';
import IconEyeOpened from 'components/Icons/IconEyeOpened';
import IconSushiRoll from 'components/Icons/IconSushiRoll';
import IconTrash from 'components/Icons/IconTrash';
import { useAccentColor, usePreferences } from 'hooks/usePreferences';
import { useAtomCallback, useAtomValue } from 'jotai/utils';
import Button from 'joy/Button';
import Dropdown, { DropdownItem } from 'joy/Dropdown';
import Tooltip from 'joy/Tooltip';
import React, { useCallback, useState } from 'react';
import { ARIA_LABEL_TODO_POPOVER_OPTIONS } from 'utils/constants';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import NewColorPicker from '../NewColorPicker';
import { SECTION_HEADER_ID } from './constants';
import { catIdMenuOpenAtom, showArchivedListsAtom } from './todosAtoms';

interface Props {
  onToggleDoneTodos?: () => void;
  toggleArchivedLists?: () => void;
  archiveList?: () => void;
  deleteList?: () => void;
  changeColor?: (value: ColorFamily) => void;
  colorFamily?: ColorFamily;
  className?: string;
  tooltipTextOverwrite?: string;
  menuId?: string;
}

export default React.memo(function CategoryOptions({
  onToggleDoneTodos,
  toggleArchivedLists,
  archiveList,
  deleteList,
  changeColor,
  colorFamily,
  className,
  tooltipTextOverwrite,
  menuId,
}: Props): JSX.Element {
  const defaultEventColor = useAccentColor();
  const color = colorFamily || defaultEventColor;
  const colorMap = EVENT_COLOR_MAP[color];

  const isSectionHeader = menuId === SECTION_HEADER_ID;
  const [colorPickerExpanded, setColorPickerExpanded] = useState(false);

  const preferences = usePreferences();
  const showDoneTodos = preferences.showDoneTodos;

  const showArchivedLists = useAtomValue(showArchivedListsAtom);

  const menuOpenCatId = useAtomValue(catIdMenuOpenAtom);
  const isMenuOpen = (menuOpenCatId && menuOpenCatId === menuId) || false;

  const toggleOptionsMenu = useAtomCallback(
    useCallback(
      (get, set) => {
        const menuOpenCatId = get(catIdMenuOpenAtom);

        if (menuOpenCatId) {
          set(catIdMenuOpenAtom, undefined);
        } else {
          set(catIdMenuOpenAtom, menuId);
        }
      },
      [menuId]
    )
  );

  const onMenuClose = useAtomCallback(
    useCallback((_, set) => {
      set(catIdMenuOpenAtom, undefined);
    }, [])
  );

  const onColorpickerOpen = useAtomCallback(
    useCallback(
      (_, set) => {
        setColorPickerExpanded(true);
        set(catIdMenuOpenAtom, menuId);
      },
      [menuId]
    )
  );

  const getMenuItems = () => {
    const items: DropdownItem[] = [];

    if (typeof onToggleDoneTodos === 'function') {
      // done todos can be toggled even when viewing archived categories
      items.push({
        type: 'option',
        value: showDoneTodos ? `Hide done todos` : `Show done todos`,
        onSelect: onToggleDoneTodos,
        icon: showDoneTodos ? (
          <IconEyeClosed className="h-4 w-4 p-px" />
        ) : (
          <IconEyeOpened className="h-4 w-4 p-px" />
        ),
      });
    }

    if (typeof toggleArchivedLists === 'function') {
      items.push({
        type: 'option',
        value: `View archived lists`,
        onSelect: toggleArchivedLists,
        icon: <IconArchive className="h-4 w-4" />,
      });
    }

    if (typeof changeColor === 'function') {
      items.push({
        type: 'option',
        value: 'Color',
        onSelect: onColorpickerOpen,
        icon: (
          <div
            className={classNames(
              'h-4 w-4 rounded-full border-2 text-[0px]',
              colorMap.colorSwatchBg,
              colorMap.border
            )}
          />
        ),
      });
    }

    if (typeof archiveList === 'function') {
      items.push({
        type: 'option',
        value: `${showArchivedLists ? `Unarchive` : `Archive`} list`,
        onSelect: archiveList,
        icon: <IconArchive className="h-4 w-4" />,
      });
    }

    if (typeof deleteList === 'function') {
      items.push({
        type: 'option',
        value: 'Delete list',
        onSelect: deleteList,
        icon: <IconTrash className="h-4 w-4" />,
        variant: 'red',
      });
    }

    return items;
  };

  return (
    <>
      {typeof changeColor === 'function' && colorPickerExpanded && (
        <NewColorPicker
          placement="bottom-start"
          value={color}
          clickedOutside={() => {
            setColorPickerExpanded(false);
            onMenuClose();
          }}
          onChange={(color) => {
            changeColor(color);
            setColorPickerExpanded(false);
            onMenuClose();
          }}
          expanded={colorPickerExpanded}
          isHotkeyEnabled={isMenuOpen}
        />
      )}
      <Dropdown
        className={classNames('h-6 w-6', className)}
        items={getMenuItems}
        onClose={onMenuClose}
      >
        <Tooltip content={tooltipTextOverwrite || 'Options'}>
          <Button
            className={classNames(
              'text-icons flex h-6 w-6 rotate-90 items-center justify-center rounded-lg',
              {
                [`opacity-0 transition-opacity group-hover:opacity-100 ${colorMap.todoButtonText} ${colorMap.todoButton}`]:
                  !isSectionHeader,
                'opacity-100': isMenuOpen,
                'hover:bg-gray-150 hover:text-gray-600 dark:hover:bg-gray-700 dark:hover:text-gray-200':
                  isSectionHeader,
              }
            )}
            ariaLabel={ARIA_LABEL_TODO_POPOVER_OPTIONS}
            onClick={toggleOptionsMenu}
          >
            <IconSushiRoll className="h-4 w-4" />
          </Button>
        </Tooltip>
      </Dropdown>
    </>
  );
});
