import { Getter, Setter } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { IGridEvent } from 'types/events';
import { dateYearOrdinal } from 'utils/time';
import { userEmailAtom, useUserEmail } from './auth/authAtoms';
import { isGridEventsReadyAtom } from './events/eventAtoms';
import {
  addBatchEvents,
  getGridEventsPool,
} from './events/helpers/eventAtomsHelpers';
import { isBetweenNowAndOneWeekFromNow } from './events/helpers/eventsHelpers';

type DehydratedIGridEvent = Omit<
  IGridEvent,
  'createdAt' | 'updatedAt' | 'startAt' | 'endAt' | 'prevStartAt' | 'prevEndAt'
> & {
  createdAt: string;
  updatedAt: string;
  startAt: string;
  endAt: string;
  prevStartAt: string;
  prevEndAt: string;
};

export function FetchCurrentWeekFromCache(): null {
  useFetchCurrentWeekFromCache();
  return null;
}

function useFetchCurrentWeekFromCache(): void {
  const email = useUserEmail();
  const restoreServerEventsFromCache = useAtomCallback(
    restoreServerEventsFromCacheAtomCallback
  );

  // Run this only on mount
  useEffect(() => {
    if (!email) {
      return;
    }
    restoreServerEventsFromCache(email);
  }, [email, restoreServerEventsFromCache]);
}

function restoreServerEventsFromCacheAtomCallback(
  _get: Getter,
  set: Setter,
  calendarId: string
): void {
  const savedServerEvents = getSavedServerEvents(calendarId);
  const isCacheValid = hasEventsForToday(savedServerEvents);
  if (!isCacheValid) {
    // The cache is too old
    return;
  }

  rehydrateGridAtoms(set, savedServerEvents);
  set(isGridEventsReadyAtom, true);
}
export function saveServerEventsToCacheAtomCallback(get: Getter): void {
  // Let's only save today +- 1week events to the cache
  const calendarId = get(userEmailAtom);
  if (!calendarId) {
    return;
  }
  console.log('SAVE EVENTS TO CACHE');
  const serverEvents = getGridEventsPool(get).filter(
    (event) =>
      event.calendarId === calendarId &&
      isBetweenNowAndOneWeekFromNow(event) &&
      event.status !== 'cancelled' &&
      !event.isDraft
  );
  saveServerEvents(calendarId, serverEvents);
}

const CACHE_EVENTS_KEY = 'amie:cached-events:v2';

function getSavedServerEvents(calendarId: string): IGridEvent[] {
  const savedServerEvents = localStorage.getItem(
    `${CACHE_EVENTS_KEY}:${calendarId}`
  );
  if (!savedServerEvents) {
    return [];
  }
  try {
    return JSON.parse(savedServerEvents).map((event: DehydratedIGridEvent) => ({
      ...event,
      createdAt: new Date(event.createdAt),
      updatedAt: DateTime.fromISO(event.updatedAt),
      startAt: DateTime.fromISO(event.startAt),
      endAt: DateTime.fromISO(event.endAt),
      prevStartAt: DateTime.fromISO(event.prevStartAt),
      prevEndAt: DateTime.fromISO(event.prevEndAt),
    }));
  } catch (e) {
    console.error(e);
    return [];
  }
}

function hasEventsForToday(savedServerEvents: IGridEvent[]): boolean {
  const todayOrdinal = dateYearOrdinal(DateTime.now());
  return savedServerEvents.some((event) => {
    return Math.abs(dateYearOrdinal(event.startAt) - todayOrdinal) <= 1;
  });
}

function rehydrateGridAtoms(
  set: Setter,
  savedServerEvents: IGridEvent[]
): void {
  addBatchEvents(set, savedServerEvents, 'server');
}

function saveServerEvents(
  calendarId: string,
  serverEvents: IGridEvent[]
): void {
  localStorage.setItem(
    `${CACHE_EVENTS_KEY}:${calendarId}`,
    JSON.stringify(serverEvents)
  );
}
