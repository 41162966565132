import { Category, CategoryUpsertInput, ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { atom, Getter } from 'jotai';
import {
  atomFamily,
  useAtomCallback,
  useAtomValue,
  useUpdateAtom,
} from 'jotai/utils';
import React, { ReactNode, useCallback } from 'react';
import { DraggableType } from 'types/drag-and-drop';
import { v4 as uuid } from 'uuid';
import { CategoryHeader } from './CategoryHeader';
import { CategoryWrapper } from './CategoryWrapper';
import { DRAFT } from './constants';
import { DropIndicator } from './DropIndicator';
import { useTodosDraggable } from './todos-dnd';
import {
  activeCategoryIdAtom,
  activeTodoIdAtom,
  draftCategoryIdAtom,
  dragCategoryAtom,
  dragOverCategoryAtom,
  optimisticTodosAtom,
  showArchivedListsAtom,
  todosAtom,
} from './todosAtoms';
import { DropDirection } from './types';
import { useUpdateTodos } from './useUpdateTodos';
import { colorFamilyToColor, useIsDraggingOver } from './utils';

interface CategoryItemProps {
  colorFamily?: Category['colorFamily'];
  name?: string | null;
  id: string;
  className?: string;
  autoFocus?: boolean;
  children?: ReactNode | undefined;
  onTitleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  style?: React.CSSProperties;
  isExpanded?: boolean;
  itemCount?: number;
  todos?: Array<{ id: string; __name?: string | null }>;
  handleRef?: React.MutableRefObject<HTMLElement | null>;
}
const categoryDropDirectionAtom = atom<DropDirection>((get) => {
  const dragCategory = get(dragCategoryAtom);
  const dragOverCategory = get(dragOverCategoryAtom);
  const { categories } = get(todosAtom);

  if (!dragCategory || !dragOverCategory) return 'none';

  const currentIndex = categories.findIndex((it) => it.id === dragCategory.id);
  const overIndex = categories.findIndex((it) => it.id === dragOverCategory.id);

  return overIndex >= currentIndex ? 'below' : 'above';
});

const categoryDropDirectionAtomFamily = atomFamily((id: string) =>
  atom((get: Getter) => {
    if (get(dragOverCategoryAtom)?.id !== id) return 'none';

    return get(categoryDropDirectionAtom);
  })
);

export const useCategoryDropDirection = (id: string) =>
  useAtomValue(categoryDropDirectionAtomFamily(id));

export const useUpdateCategory = (id: string | undefined) => {
  const update = useUpdateTodos();
  const optimisticUpdate = useUpdateAtom(optimisticTodosAtom);

  return useCallback(
    async (data: Partial<CategoryUpsertInput>) => {
      const category: Partial<CategoryUpsertInput> = {
        id,
        lastClientUpdate: new Date().toISOString(),
        ...data,
      };

      const objects = {
        categories: [category as CategoryUpsertInput],
      };

      optimisticUpdate(objects);

      await update(objects);
    },
    [id, optimisticUpdate, update]
  );
};

export const SortableCategoryItem = React.memo(SortableCategoryItemComponent);

function SortableCategoryItemComponent(props: CategoryItemProps) {
  const { setNodeRef: ref, setDragHandleRef } =
    useTodosDraggable<HTMLDivElement>(props.id, DraggableType.TODO_CATEGORY);

  const isDraggingTodoOver = useIsDraggingOver(props.id, [
    DraggableType.TODO,
    DraggableType.EVENT,
  ]);

  return (
    <CategoryItem
      className={classNames(props.className, {
        'bg-opacity-50 dark:bg-opacity-50':
          !props.isExpanded && isDraggingTodoOver,
      })}
      ref={ref}
      handleRef={setDragHandleRef}
      {...props}
    />
  );
}

export const hoveredCategoryIdAtom = atom<string | undefined>(undefined);
export const CategoryItem = React.memo(React.forwardRef(CategoryItemComponent));

export function CategoryItemComponent(
  {
    id,
    name,
    children,
    colorFamily,
    isExpanded = false,
    autoFocus,
    handleRef,
    className,
    ...props
  }: CategoryItemProps,
  ref: React.Ref<HTMLDivElement>
) {
  const isDraft = id === DRAFT;

  const showArchivedLists = useAtomValue(showArchivedListsAtom);

  const update = useUpdateTodos();
  const optUpdate = useUpdateAtom(optimisticTodosAtom);

  const resetDraftTodos = useAtomCallback((_, set) => {
    set(draftCategoryIdAtom, null);
    set(activeCategoryIdAtom, null);
    set(activeTodoIdAtom, null);
  });

  const updateCategory = useUpdateCategory(id);

  const onBlur = useAtomCallback(
    useCallback(
      async (_, set, event: React.FocusEvent<HTMLTextAreaElement>) => {
        const title = event.currentTarget.value;

        if (isDraft && !title) {
          // remove draft category if it was blurred and no title was entered
          set(draftCategoryIdAtom, null);
          set(activeCategoryIdAtom, null);
          set(activeTodoIdAtom, null);
        } else if (name !== title && (title.length > 0 || name?.length !== 0)) {
          if (isDraft) {
            // only do optimistic and server update when cat title is added
            // this way we ensure new categories would have titles
            const draftId = uuid();
            const objects = {
              categories: [
                {
                  name: title,
                  id: draftId,
                  createdAt: new Date().toISOString(),
                  lastClientUpdate: new Date().toISOString(),
                  after: null,
                },
              ],
            };

            // set focus on the first todo item in that category
            set(activeCategoryIdAtom, draftId); // set the active category
            // order here is important
            // set(draftCategoryIdAtom) should be set to null before we add the item to the list
            // that way we hide the draft category before the new one shows
            // once the draft is saved, remove draft placeholder
            set(draftCategoryIdAtom, null);

            optUpdate(objects);

            await update(objects);
          } else {
            updateCategory({ name: title });
          }
        }
      },
      [isDraft, name, optUpdate, update, updateCategory]
    )
  );

  const onExpandToggle = useAtomCallback(
    useCallback(
      (get) => {
        const objects = {
          categories: [
            {
              id,
              lastClientUpdate: new Date().toISOString(),
              expanded: !isExpanded,
            },
          ],
        };

        const showArchive = get(showArchivedListsAtom);
        // Only allow to expand category on server if it's not archive or draft
        if ((!showArchive || isExpanded) && !isDraft) update(objects);
        optUpdate(objects);
      },
      [id, isDraft, isExpanded, optUpdate, update]
    )
  );

  const onArchive = useCallback(() => {
    if (isDraft) return; // can't archive draft categories
    updateCategory({
      archivedAt: new Date().toISOString(),
      after: null,
      expanded: false,
    });
  }, [isDraft, updateCategory]);

  const onUnarchive = useCallback(
    () => updateCategory({ archivedAt: null, after: null, expanded: true }),
    [updateCategory]
  );

  const onDelete = useCallback(() => {
    if (isDraft) {
      return; // can't delete draft categories
    }
    updateCategory({
      deletedAt: new Date().toISOString(),
    });
    resetDraftTodos();
  }, [isDraft, resetDraftTodos, updateCategory]);

  const setHoveredTodoCategoryId = useUpdateAtom(hoveredCategoryIdAtom);

  const onMouseEnter = useCallback(() => {
    setHoveredTodoCategoryId(id);
  }, [id, setHoveredTodoCategoryId]);

  const onMouseLeave = useCallback(() => {
    setHoveredTodoCategoryId(undefined);
  }, [setHoveredTodoCategoryId]);

  const dropDirection = useCategoryDropDirection(id);

  return (
    <CategoryWrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      colorFamily={colorFamily}
      ref={ref}
      className={classNames(
        {
          'opacity-50': !isExpanded && isDraft,
          'animate-fadeInSpring': isDraft,
        },
        className
      )}
      {...props}
    >
      <DropIndicator
        direction={dropDirection}
        colorFamily={colorFamilyToColor(colorFamily) || ColorFamily.Gray}
      />
      <CategoryHeader
        expanded={isExpanded}
        setExpanded={onExpandToggle}
        onBlurCapture={onBlur}
        onDelete={onDelete}
        handleRef={handleRef}
        onArchive={showArchivedLists ? onUnarchive : onArchive}
        onColorChange={(color) =>
          updateCategory({
            colorFamily: color,
          })
        }
        defaultValue={name || ''}
        colorFamily={colorFamily}
        placeholder={isDraft ? 'New list' : 'Untitled list'}
        showCategoryOptions={!isDraft}
        shouldAutoFocus={autoFocus || isDraft}
        isDraft={isDraft}
        categoryId={id}
      />

      <motion.div
        className="flex h-fit-content flex-col gap-0.5"
        initial={isExpanded ? 'open' : 'collapsed'}
        animate={isExpanded ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: 'auto', display: 'flex' },
          collapsed: {
            opacity: 0,
            height: 0,
            transitionEnd: {
              display: 'none',
            },
          },
        }}
        transition={{
          duration: 0.2,
          ease: 'easeIn',
        }}
      >
        {children}
      </motion.div>
    </CategoryWrapper>
  );
}
