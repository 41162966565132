import { atom, useAtom } from 'jotai';

export enum TrappedMode {
  AddCalendar,
}

const trappedModeAtom = atom<TrappedMode | undefined>(undefined);

export default function useTrappedMode() {
  const [trappedMode, setTrappedMode] = useAtom(trappedModeAtom);

  return {
    trappedMode,
    setTrappedMode,
  };
}
