import IconTrash from 'components/Icons/IconTrash';
import { ListItem } from 'components/QuickMenu/types';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useUpdateModal } from 'hooks/useModal';
import React, { useCallback } from 'react';
import { IGridEvent } from 'types/events';

export const useDeleteEventAction = (fullEvents: IGridEvent[]): ListItem => {
  const { deleteGridEvent } = useUpdateGridEvent();
  const { closeModal } = useUpdateModal();

  const deleteEvents = useCallback((): void => {
    fullEvents.forEach((event) => {
      deleteGridEvent({ eventId: event.id });
    });

    closeModal();
  }, [closeModal, deleteGridEvent, fullEvents]);

  return {
    id: 'deleteEvent',
    title: `Delete event`,
    type: 'eventsSelectionOption',
    Icon: () => <IconTrash className="h-5 w-5 p-0.5 text-gray-400" />,
    actionNameOverride: 'Delete',
    actionOverride: deleteEvents,
  };
};
