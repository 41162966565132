import type { GetGMapsPlacesQuery } from 'graphql/queries/getGMapsPlaces.graphql';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IGridEvent } from 'types/events';
import { EventsWorkerAction, LocationWorkerAction } from 'types/worker';
import createCtx from './createCtx';

function useLocationWorker() {
  const workerRef = useRef<Worker | null>(null);
  const [locationResults, setLocationResults] = useState<
    GetGMapsPlacesQuery['getGMapsPlaces']['places']
  >([]);

  const handleWorkerResponse = useCallback((evt: MessageEvent) => {
    switch (evt.data.type) {
      case LocationWorkerAction.Search:
        setLocationResults(evt.data.payload);
        break;
      default:
        console.warn(
          'Could not find matching action type for worker response.'
        );
        break;
    }
  }, []);

  useEffect(() => {
    workerRef.current = new Worker(
      new URL('../workers/location', import.meta.url)
    );
    workerRef.current.onmessage = handleWorkerResponse;

    return () => {
      workerRef.current?.terminate();
    };
  }, [handleWorkerResponse]);

  const handleLocationSearch = useCallback(
    (payload: { text: string; token: string; limit?: number }) => {
      workerRef.current?.postMessage({
        type: LocationWorkerAction.Search,
        payload: payload,
      });
    },
    []
  );

  return {
    handleLocationSearch,
    locationResults,
  };
}

function useEventsWorker() {
  const workerRef = useRef<Worker | null>(null);
  const [eventResults, setEventResults] = useState<IGridEvent[]>([]);

  const handleWorkerResponse = useCallback((evt: MessageEvent) => {
    switch (evt.data.type) {
      case EventsWorkerAction.Search:
        setEventResults(evt.data.payload);
        break;
      case EventsWorkerAction.Precache:
        console.debug(evt.data.payload);
        break;
      default:
        console.warn(
          'Could not find matching action type for worker response.'
        );
        break;
    }
  }, []);

  useEffect(() => {
    workerRef.current = new Worker(
      new URL('../workers/events', import.meta.url)
    );
    workerRef.current.onmessage = handleWorkerResponse;

    return () => {
      workerRef.current?.terminate();
    };
  }, [handleWorkerResponse]);

  const handlePrecache = useCallback(() => {
    workerRef.current?.postMessage({
      type: EventsWorkerAction.Precache,
    });
  }, []);

  const handleEventsSearch = useCallback((payload: string) => {
    workerRef.current?.postMessage({
      type: EventsWorkerAction.Search,
      payload: payload,
    });
  }, []);

  return {
    handlePrecache,
    handleEventsSearch,
    eventResults,
  };
}

function useWorker() {
  const { handlePrecache, handleEventsSearch, eventResults } =
    useEventsWorker();
  const { locationResults, handleLocationSearch } = useLocationWorker();

  return {
    handlePrecache,
    handleEventsSearch,
    eventResults,
    locationResults,
    handleLocationSearch,
  };
}

type TWorkerContext = ReturnType<typeof useWorker>;

export const [useWorkerContext, WorkerContext] = createCtx<TWorkerContext>();

export const WorkerProvider: React.FC = ({ children }) => {
  const value = useWorker();

  return <WorkerContext value={value}>{children}</WorkerContext>;
};
