import React from 'react';

export default function IconSidebarOpen({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="4"
        y="4"
        width="12"
        height="12"
        rx="2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="10.5"
        y="4"
        width="5"
        height="12"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  );
}
