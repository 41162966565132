import {
  CategoryUpsertInput,
  ColorFamily,
  TodoUpsertInput,
  UpdateTodosInput,
} from '@graphql-types@';
import { atom } from 'jotai';
import { useAtomCallback, useResetAtom, useUpdateAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { createArray, uuid } from 'utils/toolbox';
import { optimisticTodosAtom, todosAtom } from './todosAtoms';

export const fakeTodosModeAtom = atom(false);

export function useFakeTodos() {
  const optUpdate = useUpdateAtom(optimisticTodosAtom);
  const reset = useResetAtom(todosAtom);

  const addFakeTodos = useAtomCallback(
    useCallback(() => {
      optUpdate(generateFakeTodos());
    }, [optUpdate])
  );

  const toggleFakeTodos = useAtomCallback(
    useCallback(
      (get, set) => {
        const fakeTodos = get(fakeTodosModeAtom);
        if (fakeTodos) {
          console.debug('Fake to-dos were reset');
          reset();
          set(fakeTodosModeAtom, false);
        } else {
          console.debug('Inserted fake to-dos');
          addFakeTodos();
          set(fakeTodosModeAtom, true);
        }
      },
      [addFakeTodos, reset]
    )
  );

  return { toggleFakeTodos };
}

function generateFakeTodos(): UpdateTodosInput {
  let prevCategoryId: string | null = null;
  let prevTodoId: string | null = null;

  const categories: CategoryUpsertInput[] = [];
  const todos: TodoUpsertInput[] = [];
  createArray(10).forEach((number) => {
    const categoryId = uuid();
    const data: CategoryUpsertInput = {
      id: categoryId,
      after: prevCategoryId,
      lastClientUpdate: new Date().toISOString(),
      expanded: true,
      colorFamily: ColorFamily.Purple,
      name: 'Fake Category ' + number,
    };
    prevCategoryId = categoryId;
    categories.push(data);

    prevTodoId = null;
    createArray(Math.round(100 * Math.random())).forEach((number) => {
      const id = uuid();
      const data: TodoUpsertInput = {
        id: id,
        after: prevTodoId,
        lastClientUpdate: new Date().toISOString(),
        name: 'Fake Todo ' + number,
        categoryId: categoryId,
      };

      prevTodoId = id;
      todos.push(data);
    });
  });

  return { todos, categories };
}
