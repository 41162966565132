import { isDraftEvent } from 'components/Grid/utils';
import { hoveredCategoryIdAtom } from 'components/Todos/CategoryItem';
import { hoveredTodoIdAtom } from 'components/Todos/TodoItem';
import { categoryFamily, todoFamily } from 'components/Todos/utils';
import { fullSelectionEventsAtom } from 'hooks/useEventsSelection';
import { useAtom } from 'jotai';
import {
  atomWithReset,
  useAtomCallback,
  useAtomValue,
  useResetAtom,
} from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { NavItem, NavItemType } from '../types';
import {
  getEventsBreadcrumbTitle,
  EventsBreadcrumbTooltipContent,
  getTodoCategoryBreadcrumbTitle,
  TodoCategoryBreadcrumbTooltipContent,
  getTodosBreadcrumbTitle,
  TodosBreadcrumbTooltipContent,
} from './navItemsHelpers';

export const quickMenuNavItemsAtom = atomWithReset<NavItem[]>([]);

export const useLastNavItem = () => {
  const navItems = useAtomValue(quickMenuNavItemsAtom);
  return useMemo(() => navItems[navItems.length - 1], [navItems]);
};

type AddNavItemArgs = {
  type?: NavItemType | undefined;
  fullObject?: NavItem;
};

export default function useSetNavItems() {
  const addNavItem = useAtomCallback(
    useCallback((get, set, props: AddNavItemArgs | null = null) => {
      const { type = undefined, fullObject = undefined } = props || {};

      switch (type) {
        case NavItemType.calendars: {
          break;
        }

        case NavItemType.timezones: {
          set(quickMenuNavItemsAtom, [
            {
              type: NavItemType.timezones,
              title: 'Timezones',
              inputPlaceholder: 'Choose a timezone',
              hidden: true,
            },
          ]);
          break;
        }

        case NavItemType.contactSearch:
        case NavItemType.textInput:
        case NavItemType.nestedActions:
        case NavItemType.locationPicker:
        case NavItemType.chronoActions: {
          if (fullObject == null)
            throw new Error('Cannot add null fullObject to quickmenu.');

          set(quickMenuNavItemsAtom, [fullObject]);
          break;
        }

        default: {
          // observes the state of the application and sets nav items accordingly
          const todoId = get(hoveredTodoIdAtom);

          if (todoId) {
            const todoForBreadcrumb = get(todoFamily(todoId));

            set(quickMenuNavItemsAtom, () => {
              return [
                {
                  type: NavItemType.todos,
                  title: getTodosBreadcrumbTitle(todoForBreadcrumb),
                  tooltipContent:
                    TodosBreadcrumbTooltipContent(todoForBreadcrumb),
                  todoId,
                },
              ];
            });

            return;
          }

          const todoCategoryId = get(hoveredCategoryIdAtom);

          if (todoCategoryId) {
            const todoCategoryForBreadcrumb = get(
              categoryFamily(todoCategoryId)
            );

            set(quickMenuNavItemsAtom, () => {
              return [
                {
                  type: NavItemType.todoCategory,
                  title: getTodoCategoryBreadcrumbTitle(
                    todoCategoryForBreadcrumb
                  ),
                  tooltipContent: TodoCategoryBreadcrumbTooltipContent(
                    todoCategoryForBreadcrumb
                  ),
                  todoCategoryId,
                },
              ];
            });

            return;
          }

          // observes the state of the application and sets nav items accordingly
          const eventsForBreadcrumb = get(fullSelectionEventsAtom).filter(
            (e) =>
              (!isDraftEvent(e) || e.title.trim().length > 0) &&
              (e.canEdit || e.isSelfAsAttendee) &&
              e.belongsToUserCalendar
          );

          if (eventsForBreadcrumb.length > 0) {
            set(quickMenuNavItemsAtom, () => {
              return [
                {
                  type: NavItemType.events,
                  title: getEventsBreadcrumbTitle(eventsForBreadcrumb),
                  tooltipContent:
                    EventsBreadcrumbTooltipContent(eventsForBreadcrumb),
                  eventIds: eventsForBreadcrumb.map((x) => x.id),
                },
              ];
            });
          }

          return;
        }
      }
    }, [])
  );

  const resetNavItems = useResetAtom(quickMenuNavItemsAtom);
  const [navItems, setNavItems] = useAtom(quickMenuNavItemsAtom);

  const deleteLastItem = useCallback(() => {
    if (navItems.length === 0 || navItems[navItems.length - 1].hidden) return;

    setNavItems(navItems.slice(0, navItems.length - 1));
  }, [navItems, setNavItems]);

  return { addNavItem, deleteLastItem, resetNavItems };
}
