import { isEqual } from 'lodash';
import { EventDiff, IGridEvent } from 'types/events';
import { isSameDateTime } from 'utils/time';
import { isNullUndefinedOrEmpty } from 'utils/toolbox';

export function getEventsDiff({
  oldEvent,
  newEvent,
  whitelistedKeys,
}: {
  oldEvent: Partial<IGridEvent>;
  newEvent: Partial<IGridEvent>;
  whitelistedKeys: Array<keyof IGridEvent>;
}): EventDiff {
  return whitelistedKeys.reduce((diffResult, key) => {
    switch (key) {
      case 'attendees':
        return {
          ...diffResult,
          ...getAttendeeDiff({ oldEvent, newEvent }),
        };
      case 'recurrenceRules':
        return {
          ...diffResult,
          ...getRecurrenceDiff({ oldEvent, newEvent }),
        };
      case 'isAllDay':
      case 'startAt':
      case 'endAt':
        return {
          ...diffResult,
          ...getTimeDiff({ oldEvent, newEvent }),
        };
      case 'location':
        return {
          ...diffResult,
          ...getLocationDiff({ oldEvent, newEvent }),
        };
      default:
        if (
          isNullUndefinedOrEmpty(oldEvent[key]) &&
          isNullUndefinedOrEmpty(newEvent[key])
        ) {
          return diffResult;
        }
        if (oldEvent[key] !== newEvent[key]) {
          return {
            ...diffResult,
            [key]: {
              old: oldEvent[key],
              new: newEvent[key],
            },
          };
        }
        return diffResult;
    }
  }, {} as EventDiff);
}

function getAttendeeDiff({
  oldEvent,
  newEvent,
}: {
  oldEvent: Partial<IGridEvent>;
  newEvent: Partial<IGridEvent>;
}): EventDiff | null {
  const oldAttendees = oldEvent.attendees || [];
  const newAttendees = newEvent.attendees || [];

  if (
    isEqual(
      new Set(oldAttendees.map((attendee) => attendee.email)),
      new Set(newAttendees.map((attendee) => attendee.email))
    )
  ) {
    return null;
  }

  return {
    attendees: {
      old: oldAttendees,
      new: newAttendees,
    },
  };
}

function getRecurrenceDiff({
  oldEvent,
  newEvent,
}: {
  oldEvent: Partial<IGridEvent>;
  newEvent: Partial<IGridEvent>;
}): EventDiff | null {
  const oldRecurrence = oldEvent.recurrenceRules || [];
  const newRecurrence = newEvent.recurrenceRules || [];

  if (isEqual(new Set(oldRecurrence), new Set(newRecurrence))) {
    return null;
  }

  return {
    recurrenceRules: {
      old: oldRecurrence,
      new: newRecurrence,
    },
  };
}

function getLocationDiff({
  oldEvent,
  newEvent,
}: {
  oldEvent: Partial<IGridEvent>;
  newEvent: Partial<IGridEvent>;
}): EventDiff | null {
  const oldLocation =
    oldEvent.location || oldEvent.videoConferences?.[0]?.link || '';
  const newLocation =
    newEvent.location || newEvent.videoConferences?.[0]?.link || '';

  if (oldLocation === newLocation) {
    return null;
  }

  return {
    location: {
      old: oldLocation,
      new: newLocation,
    },
  };
}

function getTimeDiff({
  oldEvent,
  newEvent,
}: {
  oldEvent: Partial<IGridEvent>;
  newEvent: Partial<IGridEvent>;
}): EventDiff | null {
  // Type check
  if (
    !oldEvent.startAt ||
    !oldEvent.endAt ||
    !newEvent.startAt ||
    !newEvent.endAt
  ) {
    return null;
  }

  // allDay changes
  if (!!oldEvent.isAllDay !== !!newEvent.isAllDay) {
    return {
      isAllDay: {
        old: !!oldEvent.isAllDay,
        new: !!newEvent.isAllDay,
      },
      startAt: {
        old: oldEvent.startAt,
        new: newEvent.startAt,
      },
      endAt: {
        old: oldEvent.endAt,
        new: newEvent.endAt,
      },
    };
  }

  if (
    isSameDateTime(oldEvent.startAt, newEvent.startAt) &&
    isSameDateTime(oldEvent.endAt, newEvent.endAt)
  ) {
    return null;
  }

  return {
    startAt: {
      old: oldEvent.startAt,
      new: newEvent.startAt,
    },
    endAt: {
      old: oldEvent.endAt,
      new: newEvent.endAt,
    },
  };
}
