import { selectedSlotIdAtom } from 'hooks/calendarLink/creation/useSelectedSlotId';
import { addCalendarSlotAtomCallback } from 'hooks/calendarLink/creation/useUpdateSlot';
import { calendarStartDateAtom, calendarEndDateAtom } from 'hooks/useCalendar';
import { Getter, Setter } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import {
  coordinatesToTargetDate,
  getTargetDimensions,
  MouseCoordinates,
} from 'utils/mouseEvents';

export function useClickToCreateSlot() {
  return useAtomCallback(handleClickToCreateAtomCallback);
}

function handleClickToCreateAtomCallback(
  get: Getter,
  set: Setter,
  {
    id,
    mouseEvent,
    initialCoordinates,
  }: {
    id: string;
    mouseEvent: React.MouseEvent<HTMLDivElement>;
    initialCoordinates: MouseCoordinates;
  }
) {
  // Clear eventsSelection and SlotSelection
  set(selectedSlotIdAtom, null);
  const gridDimensions = getTargetDimensions(mouseEvent);

  const targetDate = coordinatesToTargetDate({
    coordinates: initialCoordinates,
    width: gridDimensions.width,
    height: gridDimensions.height,
    startDay: get(calendarStartDateAtom),
    endDay: get(calendarEndDateAtom),
    roundToNearestQuarterHour: true,
  });
  if (!targetDate) {
    return;
  }

  addCalendarSlotAtomCallback(get, set, { startAt: targetDate, id });
}
