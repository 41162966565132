import { NewEventRsvpEnum } from '@graphql-types@';
import { isDraftEvent } from 'components/Grid/utils';
import { preferencesAtom } from 'hooks/preferences/preferencesAtoms';
import { eventsSelectionAtom } from 'hooks/useEventsSelection';
import { userAtom } from 'hooks/user/userAtoms';
import { Getter, Setter } from 'jotai';
import { DateTime } from 'luxon';
import { IGridEvent } from 'types/events';
import { eventIdsPoolAtom, optimisticEventsFamily } from '../eventAtoms';
import {
  generateEventUUID,
  getVisibilityAsEnum,
} from '../helpers/eventsHelpers';

export function createDraftEventAtomCallback(
  get: Getter,
  set: Setter,
  props: Partial<IGridEvent>
): void {
  const user = get(userAtom);
  const calendarId = user?.email;

  if (!calendarId) {
    console.error(
      '_createDraftEvent: no email found on user to set the calendarId'
    );
    return;
  }

  let eventId = props.id || generateEventUUID(calendarId);
  // If there it's not a compoundId we add the calendarId to the eventId
  if (!eventId.includes(':')) {
    eventId = `${eventId}:${calendarId}`;
  }

  const newDraftEvent = {
    ...draftEvent,
    isDraft: true,
    id: eventId,
  };

  const userPreferences = get(preferencesAtom);
  set(optimisticEventsFamily(newDraftEvent.id), {
    ...newDraftEvent,
    calendarId,
    createdAt: new Date(),
    visibility: getVisibilityAsEnum(userPreferences?.todoPrivacy),
    ...props,
  });
  set(eventIdsPoolAtom, (prevIds) => new Set([...prevIds, newDraftEvent.id]));
}

export function deleteDraftEventAtomCallback(get: Getter, set: Setter): void {
  const calendarId = get(userAtom)?.email;
  if (!calendarId) {
    console.error(
      '_createDraftEvent: no email found on user to set the calendarId'
    );
    return;
  }
  set(eventsSelectionAtom, (value) =>
    value.filter((id) => !get(optimisticEventsFamily(id))?.isDraft)
  );
  set(eventIdsPoolAtom, (prevIds) => {
    prevIds.forEach((id) => {
      const event = get(optimisticEventsFamily(id));
      if (!event) {
        return;
      } else if (isDraftEvent(event) && !event.title) {
        prevIds.delete(id);
        set(optimisticEventsFamily(id), null);
      } else if (isDraftEvent(event)) {
        set(optimisticEventsFamily(id), (draft) => ({
          ...draft,
          status: 'cancelled',
        }));
      }
    });
    return new Set(prevIds);
  });
}

type INewGridEvent = Omit<
  IGridEvent,
  | 'calendarId'
  | 'createdAt'
  | 'startAt'
  | 'endAt'
  | 'dayIndex'
  | 'schedule'
  | 'visibility'
>;

export const draftEvent: INewGridEvent = {
  id: 'no_id',
  title: '',
  updatedAt: DateTime.now(),
  isAllDay: false,
  status: 'confirmed',
  videoConferences: [],
  description: 'Created with Amie (https://amie.so)',
  isOwnEvent: true,
  isSelfAsAttendee: true,
  allOtherGuestsDeclined: false,
  attendees: [],
  location: '',
  colorFamily: undefined, // Because it should inherit color of it's calendar
  canEdit: true,
  belongsToUserCalendar: true,
  prevEndAt: DateTime.now(),
  prevStartAt: DateTime.now(),
  rsvp: NewEventRsvpEnum.Yes,
  isDraft: true,
};
