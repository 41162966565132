import React, { useRef } from 'react';
import { HookProps, ListGroup } from '../types';
import useTodoCategoryActions from './useTodoCategoryActions';
import useTodoActions from './useTodoActions';

export default function useTodoActionsGroup({
  enabled,
  eventIds,
  searchTerm,
}: HookProps): ListGroup {
  const listGroupRef = useRef<ListGroup>({
    title: 'Todos',
    items: [],
  });

  listGroupRef.current.items.length = 0;

  const todoActions = useTodoActions({ enabled, eventIds, searchTerm });
  const todoCategoryActions = useTodoCategoryActions({
    enabled,
    eventIds,
    searchTerm,
  });

  listGroupRef.current.items = [...todoActions, ...todoCategoryActions];
  return listGroupRef.current;
}
