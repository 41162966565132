import { useSetContactReminderMutation } from 'graphql/mutations/UpdateContact.graphql';
import {
  ContactReminderEventQueryVariables,
  useContactReminderEventQuery,
} from 'graphql/queries/contacts.graphql';
import { useCallback, useEffect, useMemo, useState } from 'react';
import RRule, { Frequency, rrulestr, Weekday } from 'rrule';
import { WeekdayStr } from 'rrule/dist/esm/src/weekday';
import * as Urql from 'urql';
import { DateTime } from 'luxon';
import useTimezone from './useTimeZone';

export enum FrequencyOption {
  WEEKLY,
  MONTHLY,
  QUARTERLY,
  YEARLY,
  CUSTOM,
}

interface UseContactReminderReturn {
  isFetching: boolean;
  setReminder: (frequency: FrequencyOption | undefined) => Promise<void>;
  frequency: FrequencyOption | undefined;
  ruleStr?: string | undefined;
}

function getWeekdayOrdinal(date: DateTime) {
  const day = date.weekday;
  const startOfMonthDate = date.startOf('month');
  const currentDate = date.day;
  let count = 0;

  for (let i = 0; i <= currentDate; i++) {
    const cDay = startOfMonthDate.plus({ days: i }).weekday;
    if (cDay === day) count++;
  }

  return count;
}

function getReccurenceRule(
  frequency: FrequencyOption | undefined,
  timezone: string
): RRule | undefined {
  const today = DateTime.now().setZone(timezone);
  const weekdayStr = today
    .toFormat('EEE')
    .slice(0, 2)
    .toUpperCase() as WeekdayStr;
  const weekday = Weekday.fromStr(weekdayStr);
  const weekdayOrdinal = getWeekdayOrdinal(today);

  if (frequency === undefined) return undefined;

  switch (frequency) {
    case FrequencyOption.WEEKLY: {
      return new RRule({ freq: Frequency.WEEKLY, byweekday: [weekday] });
    }
    case FrequencyOption.MONTHLY: {
      return new RRule({
        freq: Frequency.MONTHLY,
        bysetpos: weekdayOrdinal <= 4 ? weekdayOrdinal : -1,
        byweekday: [weekday],
      });
    }
    case FrequencyOption.QUARTERLY: {
      return new RRule({
        freq: Frequency.MONTHLY,
        bysetpos: weekdayOrdinal <= 4 ? weekdayOrdinal : -1,
        byweekday: [weekday],
        interval: 3,
      });
    }
    case FrequencyOption.YEARLY: {
      return new RRule({ freq: Frequency.YEARLY });
    }
  }
}

function getFrequencyOption(
  rrule: RRule | undefined
): FrequencyOption | undefined {
  if (!rrule) return undefined;

  const frequency = rrule.options.freq;

  switch (frequency) {
    case Frequency.WEEKLY:
      return FrequencyOption.WEEKLY;
    case Frequency.YEARLY:
      return FrequencyOption.YEARLY;
    case Frequency.MONTHLY:
      if (rrule.options.interval === 3) return FrequencyOption.QUARTERLY;
      return FrequencyOption.MONTHLY;
    default:
      return FrequencyOption.CUSTOM;
  }
}

type QueryType = Omit<
  Urql.UseQueryArgs<ContactReminderEventQueryVariables>,
  'query'
>;

export function useContactReminder(
  contactId: string | undefined
): UseContactReminderReturn {
  const options = useMemo<QueryType>(
    () =>
      contactId
        ? {
            variables: { id: contactId },
            context: {
              requestPolicy: 'cache-and-network' as const,
            },
          }
        : {
            pause: true,
          },
    [contactId]
  );

  const [reminderQuery, refetchReminder] =
    useContactReminderEventQuery(options);

  const [, setReminderMutation] = useSetContactReminderMutation();

  const reminderData = reminderQuery.data?.contactReminder;
  const recurrenceRules = reminderData?.recurrenceRules?.[0];
  const rrule = recurrenceRules ? rrulestr(recurrenceRules) : undefined;
  const initialOption = getFrequencyOption(rrule);
  const [frequencyDisplay, setFrequencyDisplay] = useState<
    FrequencyOption | undefined
  >(initialOption);
  const timezone = useTimezone();

  useEffect(() => {
    return () => setFrequencyDisplay(undefined);
  }, [contactId]);

  useEffect(() => {
    if (reminderQuery.fetching === false) {
      setFrequencyDisplay(initialOption);
    }
  }, [reminderQuery.fetching, initialOption]);

  const setReminder = useCallback(
    async (newFrequency: FrequencyOption | undefined) => {
      if (!contactId) return;
      setFrequencyDisplay(newFrequency);

      const rrule = getReccurenceRule(newFrequency, timezone);
      const recurrenceRules = rrule ? [rrule.toString()] : [];

      await setReminderMutation({
        contactId,
        recurrenceRules,
      });
      await refetchReminder();
    },
    [contactId, refetchReminder, setReminderMutation, timezone]
  );

  return {
    isFetching: reminderQuery.fetching,
    frequency: frequencyDisplay,
    setReminder,
    ruleStr: rrule?.toText(),
  };
}
