import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import { useOwnCalendars } from 'hooks/useCalendars';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { EventColor } from 'types/color';
import { ColorFamily } from '@graphql-types@';
import PageControlHeader from '../PageControlHeader';
import Input from 'joy/Input';
import { AnimatePresence, motion } from 'framer-motion';
import IconCheckCircle from 'components/Icons/IconCheckCircle';
import { useRetrieveColorNicknamesQuery } from 'graphql/queries/colorNickname.graphql';
import {
  useDeleteColorNicknameMutation,
  useUpsertColorNicknameMutation,
} from 'graphql/mutations/ColorNickname.graphql';
import { atomFamily, useAtomValue, useUpdateAtom } from 'jotai/utils';
import { atom } from 'jotai';

export default React.memo(function EventColorRename(): JSX.Element | null {
  const ownCalendars = useOwnCalendars();

  if (!ownCalendars.length) {
    return null;
  }

  return (
    <div className="pb-10">
      <PageControlHeader
        title="Rename colors"
        description={
          <>
            Remember their context when color-coding. To change your default
            color, go to Accounts.
          </>
        }
      />
      <div className="grid grid-cols-2 gap-3">
        {Object.entries(EVENT_COLOR_MAP).map(([id, color]) => (
          <EventColorRenameItem
            key={id}
            color={color}
            name={id as ColorFamily}
          />
        ))}
      </div>
    </div>
  );
});

const optimisticColorNicknameFamily = atomFamily(
  (_color: ColorFamily | string) => atom<string | null>(null)
);

export const useColorNickname = (name: ColorFamily) => {
  const [{ data }] = useRetrieveColorNicknamesQuery();
  const [colorName, setColorName] = useState<string>(capitalize(name));
  const optimisticColorNickname = useAtomValue(
    optimisticColorNicknameFamily(name)
  );

  useEffect(() => {
    setColorName(
      capitalize(
        optimisticColorNickname ||
          data?.colorNickname.find((x) => x.color === name)?.nickname ||
          name
      )
    );
  }, [data, name, optimisticColorNickname]);

  return { colorName, setColorName };
};

export const useColorNicknameMap = () => {
  const nicknamedColorMap: Record<ColorFamily | string, string> = {
    [ColorFamily.Gray]: useColorNickname(ColorFamily.Gray).colorName,
    [ColorFamily.Green]: useColorNickname(ColorFamily.Green).colorName,
    [ColorFamily.Red]: useColorNickname(ColorFamily.Red).colorName,
    [ColorFamily.Orange]: useColorNickname(ColorFamily.Orange).colorName,
    [ColorFamily.Yellow]: useColorNickname(ColorFamily.Yellow).colorName,
    [ColorFamily.Blue]: useColorNickname(ColorFamily.Blue).colorName,
    [ColorFamily.Purple]: useColorNickname(ColorFamily.Purple).colorName,
    [ColorFamily.Pink]: useColorNickname(ColorFamily.Pink).colorName,
  };

  return nicknamedColorMap;
};

function EventColorRenameItem({
  color,
  name,
}: {
  name: ColorFamily;
  color: EventColor;
}): JSX.Element {
  const [, deleteNickname] = useDeleteColorNicknameMutation();
  const [, upsertNickname] = useUpsertColorNicknameMutation();
  const { colorName, setColorName } = useColorNickname(name);
  const [saved, setSaved] = useState(false);

  const setOptimisticColorNickname = useUpdateAtom(
    optimisticColorNicknameFamily(name)
  );

  const onSave = () => {
    let newColorName = colorName;

    if (newColorName.trim().length === 0) {
      newColorName = capitalize(name);
      setColorName(newColorName);
      deleteNickname({
        color: name,
      });
    } else {
      upsertNickname({
        color: name,
        nickname: colorName,
      });
    }

    setOptimisticColorNickname(newColorName);
    setSaved(true);
  };

  useEffect(() => {
    if (!saved) return;
    setTimeout(() => setSaved(false), 3000);
  }, [saved]);

  return (
    <div className={classNames('mb-3 rounded-md')}>
      <div className="flex items-center">
        <div className="relative flex w-full items-center font-medium capitalize">
          <div
            className={classNames(
              'absolute m-auto ml-3 h-5 w-5 scale-100 rounded-full border-2 text-[0px] transition-transform duration-75 active:scale-90',
              color.colorSwatchBg,
              color.border
            )}
          />

          <Input
            onBlur={onSave}
            className="w-full rounded-md   bg-gray-100 px-3 py-2.5  pl-10 text-sm outline-none hover:bg-gray-150 focus:bg-gray-150 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:bg-gray-600"
            value={colorName}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setColorName(e.target.value)
            }
          />
          <AnimatePresence initial={false}>
            {saved ? (
              <motion.div
                key={'saved'}
                transition={{ duration: 0.12 }}
                initial={{ y: 4, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.16 } }}
                exit={{ y: 4, opacity: 0 }}
                className="absolute right-0 m-auto flex space-x-1 pr-3 text-gray-500"
              >
                <IconCheckCircle className="h-4 w-4 " />
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
