import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import { PropsOf } from 'joy/utils';
import React from 'react';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
interface DropIndicatorProps extends Omit<PropsOf<'div'>, 'children'> {
  colorFamily: ColorFamily;
  direction?: 'above' | 'below' | 'none';
}

export function DropIndicator({
  colorFamily,
  direction = 'none',
  className,
  ...divProps
}: DropIndicatorProps) {
  if (direction === 'none') return null;

  const colorMap = EVENT_COLOR_MAP[colorFamily];

  return (
    <div
      className={classNames(
        'pointer-events-none absolute flex h-px w-full pl-7',
        {
          '-bottom-px': direction === 'below',
        }
      )}
    >
      <div
        className={classNames(
          className,
          colorMap.todoDropIndicator,
          'flex h-full grow rounded-lg'
        )}
        {...divProps}
      />
    </div>
  );
}
