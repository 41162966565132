import { Category, TodoUpsertInput } from '@graphql-types@';
import classNames from 'classnames';
import withEmojiPicker from 'components/EventPopover/withEmojiPicker';
import { sidebarWidthAtom } from 'components/Panels/SidePanel';
import { PreventAutocompleteAttributes } from 'components/PreventAutocompleteAttributes';
import emojiRegex from 'emoji-regex';
import useHotkey from 'hooks/useHotkey';
import { atom } from 'jotai';
import { useAtomCallback, useAtomValue, useUpdateAtom } from 'jotai/utils';
import Checkbox from 'joy/Checkbox';
import { spawnParticle } from 'joy/utils';
import { last } from 'lodash';
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { DraggableType } from 'types/drag-and-drop';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { v4 as uuid } from 'uuid';
import { ABOVE, BENEATH, BOTTOM, DRAFT, NEXT, PREV, TOP } from './constants';
import {
  activeCategoryIdAtom,
  activeTodoIdAtom,
  optimisticTodosAtom,
  showArchivedListsAtom,
  todoDraftPositionAtom,
} from './todosAtoms';
import { TodoItemT } from './types';
import { useFocusControl, useSetInputFocus } from './useFocusControl';
import { useUpdateTodos } from './useUpdateTodos';
import { categoryTodosIdsFamily, colorFamilyToColor } from './utils';

export interface TodoItemProps extends React.HTMLAttributes<HTMLDivElement> {
  colorFamily: Category['colorFamily'];
  categoryId: string;
  doneAt?: string;
  label?: string | null;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  prevId?: string | null;
  nextId?: string | null;
  className?: string;
  style?: CSSProperties | undefined;
  type?: DraggableType;
  index?: number;
  isLast?: boolean; // so we don't have to fetch all items in the category again
}

export const hoveredTodoIdAtom = atom<string | undefined>(undefined);
const TextareaWithEmojiPicker = withEmojiPicker('textarea');

export const useUpdateTodo = (id: string | undefined) => {
  const update = useUpdateTodos();
  const optimisticUpdate = useUpdateAtom(optimisticTodosAtom);

  return useCallback(
    (data: Partial<TodoUpsertInput>) => {
      const now = new Date().toISOString();

      const todo: Partial<TodoUpsertInput> = {
        id,
        lastClientUpdate: now,
        ...data,
      };

      const objects = {
        todos: [todo as TodoUpsertInput],
      };

      optimisticUpdate(objects);
      update(objects);
    },
    [id, optimisticUpdate, update]
  );
};

export function TodoItemComponent(
  {
    id,
    index = 0,
    categoryId,
    doneAt,
    label = '',
    colorFamily,
    className,
    isLast,
    prevId,
    nextId,
    ...props
  }: TodoItemProps,
  ref?: React.Ref<HTMLDivElement>
): JSX.Element {
  const isDraft = id === DRAFT;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);

  const focusData = useFocusControl(id, inputRef);
  const setInputFocus = useSetInputFocus();

  const [isFocused, setFocused] = useState(false);

  const color = colorFamilyToColor(colorFamily);
  const colorMap = EVENT_COLOR_MAP[color];
  const [isHovered, setHovered] = useState(false);
  const [value, setValue] = useState(label || '');

  // TODO Check do we actually need local state anymore
  useEffect(() => {
    setValue(label || '');
  }, [label]);

  const update = useUpdateTodos();
  const optUpdate = useUpdateAtom(optimisticTodosAtom);

  const getTodoById = useAtomCallback(
    useCallback((get, _, todoId: string): TodoItemT | undefined => {
      const { todos } = get(optimisticTodosAtom);
      return todos.find((todo) => todo.id === todoId);
    }, [])
  );

  const createTodo = useAtomCallback(
    useCallback(
      async (
        get,
        set,
        {
          newDraftId = uuid(),
          name,
          afterId: propAfterId,
          insertPos,
        }: {
          newDraftId?: string;
          name: string;
          afterId: string | null;
          insertPos?: 'above' | 'beneath' | 'top' | 'bottom';
        }
      ) => {
        const now = new Date().toISOString();
        const insertDirection = get(todoDraftPositionAtom);
        let afterId = propAfterId;

        if (insertDirection === ABOVE) {
          afterId = prevId || null;
        } else if (insertDirection === BOTTOM) {
          const categoryTodos = get(categoryTodosIdsFamily(categoryId));
          afterId = last(categoryTodos) || afterId;
        } else if (insertDirection === TOP) {
          afterId = null;
        }

        if (afterId !== undefined) {
          // can be null
          const objects = {
            todos: [
              {
                id: newDraftId,
                categoryId,
                lastClientUpdate: now,
                name,
                after: afterId,
              },
            ],
          };

          optUpdate(objects);
          update(objects);

          set(activeTodoIdAtom, newDraftId);
          if (insertPos) {
            set(todoDraftPositionAtom, insertPos);
          }
        }
      },
      [categoryId, optUpdate, prevId, update]
    )
  );

  const updateTodo = useUpdateTodo(id);

  const mergeTodos = useCallback(
    async ({ direction }: { direction: 'prev' | 'next' }) => {
      // do nothing (index < 0)
      // delete previous id if PREV (index > 0)
      // delete next id if NEXT (index < items.length -1)
      // update current id with merged name

      if (index > 0 && direction === PREV) {
        const prevTodo = prevId ? await getTodoById(prevId) : undefined;
        const prevTodoName = prevTodo?.name || '';
        const mergedName = `${prevTodoName}${label}`;
        const position = prevTodoName.length;

        // delete previous id
        if (prevId) {
          updateTodo({ id: prevId, deletedAt: new Date().toISOString() });
        }

        // update the current todo item
        updateTodo({ name: mergedName });
        setValue(mergedName);
        setInputFocus({ id, position });
      }

      if (!isLast && direction === NEXT) {
        const nextTodo = nextId ? await getTodoById(nextId) : undefined;
        const nextTodoName = nextTodo?.name || '';
        const mergedName = `${label}${nextTodoName}`;

        const position = label ? label.length : 0;

        // delete next id
        if (nextId) {
          updateTodo({ id: nextId, deletedAt: new Date().toISOString() });
        }

        // update the current todo item
        updateTodo({ name: mergedName });
        setValue(mergedName);
        setInputFocus({ id, position });
      }
    },
    [
      index,
      isLast,
      prevId,
      getTodoById,
      label,
      updateTodo,
      setInputFocus,
      id,
      nextId,
    ]
  );

  const splitTodo = useCallback(
    ({
      currentTodoTitle,
      newTodoTitle,
    }: {
      currentTodoTitle: string;
      newTodoTitle: string;
    }) => {
      // create a new todo item
      createTodo({
        name: currentTodoTitle,
        afterId: prevId ? prevId : null,
      });
      requestAnimationFrame(() => setInputFocus({ id, position: 0 }));
      // update the current todo item
      updateTodo({ name: newTodoTitle });
      setValue(newTodoTitle); // keep current todo title up to date after splitting it
    },
    [createTodo, id, prevId, setInputFocus, updateTodo]
  );

  const onDeleteKeyPress = useAtomCallback(
    useCallback(
      async (_, set, event: KeyboardEvent) => {
        if (inputRef.current == null) return;
        const rangeStartIndex = inputRef.current.selectionStart || 0;
        const rangeEndIndex = inputRef.current.selectionEnd || 0;
        const titleLength = inputRef.current.value.length || 0;

        if (titleLength === 0) {
          if (isDraft) {
            // +Todo draft
            set(activeTodoIdAtom, id);
            set(todoDraftPositionAtom, null);
            if (event.key === 'Backspace') {
              if (prevId != null) {
                event.preventDefault();
                setInputFocus({ id: prevId });
              }
            } else if (event.key === 'Delete') {
              if (nextId != null) {
                event.preventDefault();
                setInputFocus({ id: nextId, position: 0 });
              }
            }
          } else {
            // delete the current item
            updateTodo({
              deletedAt: new Date().toISOString(),
            });
            event.preventDefault(); // prevent deleting a letter
            if (nextId != null && event.key === 'Delete') {
              setInputFocus({ id: nextId, position: 0 });
            } else if (prevId != null && event.key === 'Backspace') {
              setInputFocus({ id: prevId });
            }
          }
        } else if (!isDraft) {
          if (
            event.key === 'Backspace' &&
            rangeStartIndex === 0 &&
            rangeEndIndex === 0
          ) {
            // delete previous item and append text to current item
            // if text is not selected
            // and cursor is at the start of the text
            // and there is a title
            event.preventDefault(); // prevent deleting a letter
            mergeTodos({ direction: PREV });
          } else if (
            event.key === 'Delete' &&
            rangeStartIndex === titleLength &&
            rangeEndIndex === titleLength
          ) {
            // delete next item and append text to current item
            // if text is not selected
            // and cursor is at the end of the text
            // and there is a title
            event.preventDefault(); // prevent deleting a letter
            mergeTodos({ direction: NEXT });
          }
        }
      },
      [id, isDraft, mergeTodos, nextId, prevId, setInputFocus, updateTodo]
    )
  );

  const onInputBlur = useAtomCallback(
    useCallback(
      (_, set, event: React.FocusEvent<HTMLTextAreaElement>) => {
        setFocused(false);
        const name = event.currentTarget.value;

        if (isDraft && !name) {
          // reset the draft position to remove the draft
          set(todoDraftPositionAtom, null);
        } else if (isDraft && name) {
          createTodo({ name, afterId: prevId || null, insertPos: BENEATH });
          set(todoDraftPositionAtom, null);
        } else if (!isDraft && name && name !== label) {
          // update current
          updateTodo({ name });
        } else if (!isDraft && !name) {
          // delete current
          updateTodo({
            deletedAt: new Date().toISOString(),
          });
        }
      },
      [createTodo, isDraft, label, prevId, updateTodo]
    )
  );

  const onInputClick = useAtomCallback(
    useCallback(
      (_, set) => {
        if (!isDraft) {
          set(activeTodoIdAtom, id);
          set(activeCategoryIdAtom, categoryId);
        }
      },
      [categoryId, id, isDraft]
    )
  );

  const onInputFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const playEffect = useCallback(() => {
    const current = checkboxRef.current;

    if (current != null) {
      const regexp = emojiRegex();

      const inputEmojis = inputRef.current?.value?.match(regexp);

      if (inputEmojis != null && inputEmojis.length > 0) {
        const box = current.getBoundingClientRect();
        const x = box.left + box.width / 2;
        const y = box.top + box.height / 2;
        for (let i = 0; i < 20; i++) {
          // We call the function createParticle multiple times
          // We pass the coordinates of the checkbox for x & y values
          spawnParticle(
            x,
            y,
            inputEmojis[Math.floor(inputEmojis.length * Math.random())]
          );
        }
      }
    }
  }, []);

  const onCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const objects = {
        todos: [
          {
            id,
            lastClientUpdate: new Date().toISOString(),
            doneAt: event.currentTarget.checked
              ? new Date().toISOString()
              : null,
          },
        ],
      };
      optUpdate(objects);
      update(objects);

      // Particles
      if (event.currentTarget.checked) {
        playEffect();
      }
    },
    [id, optUpdate, playEffect, update]
  );

  const setHoveredTodoId = useUpdateAtom(hoveredTodoIdAtom);

  const onMouseEnter = useCallback(() => {
    setHovered(true);
    setHoveredTodoId(id);
  }, [id, setHoveredTodoId]);

  const setItemHovered = useAtomCallback(
    useCallback(
      (get, set) => {
        if (get(hoveredTodoIdAtom) != id) {
          setHovered(true);
          set(hoveredTodoIdAtom, id);
        }
      },
      [id]
    )
  );

  const onMouseLeave = useCallback(() => {
    setHovered(false);
    setHoveredTodoId(undefined);
  }, [setHoveredTodoId]);

  const onEnterKeyPress = useAtomCallback(
    useCallback(
      (get, set, event: KeyboardEvent) => {
        event.preventDefault();
        if (inputRef.current == null) return;

        const cursorIndex = inputRef.current.selectionStart || 0;
        const name = inputRef.current.value;
        const activeTodoId = get(activeTodoIdAtom);
        if (cursorIndex === 0) {
          if (isDraft && !name) {
            // empty draft, remove draft todo focus
            set(todoDraftPositionAtom, null);
            setInputFocus(null);
          } else if (isDraft && name !== label) {
            createTodo({
              name: name,
              afterId: prevId || null,
              insertPos: BENEATH,
            });
            setInputFocus(null);
          } else if (!isDraft) {
            if (name) {
              set(activeTodoIdAtom, id);
              set(todoDraftPositionAtom, ABOVE);
              setInputFocus({ id: DRAFT });
            } else {
              updateTodo({
                deletedAt: new Date().toISOString(),
              });
              set(activeTodoIdAtom, null);
              set(todoDraftPositionAtom, null);
            }
          }
        } else if (cursorIndex === name.length) {
          if (!isDraft && name === label) {
            set(activeTodoIdAtom, id);
            set(todoDraftPositionAtom, BENEATH);
            setInputFocus({ id: DRAFT });
          } else if (!isDraft && name !== label) {
            updateTodo({
              name: name,
            });
            set(activeTodoIdAtom, id);
            set(todoDraftPositionAtom, BENEATH);
          } else if (isDraft && name) {
            createTodo({
              name: name,
              afterId: activeTodoId,
              insertPos: BENEATH,
            });
            setInputFocus({ id: DRAFT });
          }
        } else {
          const newTodoTitle = name.substring(cursorIndex).trim();
          const currentTodoTitle = name.substring(0, cursorIndex).trim();

          if (!isDraft && name) {
            splitTodo({ currentTodoTitle, newTodoTitle });
          } else if (isDraft && name) {
            // TODO: in case we want to support splitting draft todos in future
            // we need to create a a function that inserts two todos one after the other
            // both have new uuids, so we need to use the first new one as an after param for the second
            // for now we are just creating the todo but not splitting it
            createTodo({
              name: name,
              afterId: activeTodoId,
            });
          }
        }
      },
      [
        createTodo,
        id,
        isDraft,
        label,
        prevId,
        setInputFocus,
        splitTodo,
        updateTodo,
      ]
    )
  );
  const onUpKeyPress = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      if (prevId) {
        const currentPosition = inputRef.current?.selectionStart;
        const len = inputRef.current?.value.length || 0;
        // Keep current cursor position if it's not in the end
        // If input is empty or cursor is in the end - default to the end
        const position =
          currentPosition !== undefined && currentPosition !== len
            ? currentPosition
            : undefined;

        setInputFocus({
          id: prevId,
          position,
        });
      }
    },
    [prevId, setInputFocus]
  );

  const onDownKeyPress = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      if (nextId) {
        const currentPosition = inputRef.current?.selectionStart;
        const len = inputRef.current?.value.length || 0;
        // Keep current cursor position if it's not in the end
        // If input is empty or cursor is in the end - default to the end
        const position =
          currentPosition !== undefined && currentPosition !== len
            ? currentPosition
            : undefined;
        setInputFocus({ id: nextId, position });
      }
    },
    [nextId, setInputFocus]
  );

  // usingHotkeys as withEmojiPicker takes over the way we handle onKeyPress
  // and especially Enter with input,
  // so we need to stick to hotkeys
  useHotkey(
    'enter',
    { scope: 'all', enabledWithinInput: true, enabled: isFocused },
    onEnterKeyPress
  );

  useHotkey(
    'shift + enter',
    { scope: 'all', enabledWithinInput: true, enabled: isFocused },
    (event) => event.preventDefault()
  );

  useHotkey(
    'backspace, delete',
    {
      scope: 'all',
      enabledWithinInput: true,
      enabled: isFocused,
    },
    onDeleteKeyPress
  );

  useHotkey(
    'escape',
    {
      scope: 'all',
      enabledWithinInput: true,
      enabled: isFocused,
    },
    () => {
      inputRef.current?.blur();
      setInputFocus(null);
    }
  );

  useHotkey(
    'up',
    { scope: 'all', enabledWithinInput: true, enabled: isFocused },
    onUpKeyPress
  );

  useHotkey(
    'down',
    { scope: 'all', enabledWithinInput: true, enabled: isFocused },
    onDownKeyPress
  );

  const showTextArea = isDraft || isHovered || isFocused || Boolean(focusData);

  const sidebarWidth = useAtomValue(sidebarWidthAtom);

  const {
    width = 0,
    height,
    ref: textRef,
  } = useResizeDetector({
    handleWidth: showTextArea,
    handleHeight: showTextArea,
  });

  const checked = !!doneAt;

  return (
    <div
      ref={ref}
      key={id}
      onClickCapture={setItemHovered}
      onMouseEnter={onMouseEnter}
      onMouseMove={setItemHovered}
      onMouseLeave={onMouseLeave}
      className={classNames(
        className,
        colorMap.todoText,
        'group break relative flex h-full shrink gap-0.5 rounded-md transition-opacity',
        {
          'opacity-50': checked,
        }
      )}
      {...props}
    >
      <div className="flex h-6 w-6 shrink-0 items-center justify-center">
        <Checkbox
          ref={checkboxRef}
          size={14}
          className="shrink-0 opacity-50"
          variant="event"
          colorMap={colorMap}
          onChange={onCheckboxChange}
          value={checked}
        />
      </div>

      <div className="relative flex pt-0.5">
        {showTextArea && (
          <TextareaWithEmojiPicker
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={Boolean(focusData) && isDraft}
            spellCheck={false}
            style={{
              minWidth: sidebarWidth - 90 + 'px',
              width: Math.ceil(width) + 'px',
              height: height ? height + 'px' : undefined,
              wordWrap: 'break-word',
              overflowWrap: 'anywhere',
              whiteSpace: 'pre-wrap',
            }}
            value={value}
            ref={inputRef}
            onChange={(e) => setValue(e.target.value.replace(/\n/g, ''))}
            onEmojiInsert={(value) => setValue(value)}
            onBlur={onInputBlur}
            onFocus={onInputFocus}
            onClick={onInputClick}
            className={classNames(
              'absolute resize-none overflow-hidden', // No scrolling and resizing for textarea
              'text-s font-medium leading-5', // Text size
              'bg-transparent placeholder-opacity-40 focus:outline-none dark:placeholder-opacity-40 ',
              colorMap.selection,
              colorMap.todoButtonPlaceholderText
            )}
            {...PreventAutocompleteAttributes}
          />
        )}
        <div
          ref={textRef}
          style={{
            wordWrap: 'break-word',
            overflowWrap: 'anywhere',
            whiteSpace: 'pre-wrap',
          }}
          className={classNames(
            'break flex shrink text-left text-s font-medium leading-5',
            { ['pointer-events-none invisible']: showTextArea }
          )}
        >
          {/* It is important to keep space in the end  */}
          {value || ' '}
        </div>
      </div>
    </div>
  );
}

export const TodoItem = React.memo(React.forwardRef(TodoItemComponent));

interface EmptyTodoProps {
  colorFamily: Category['colorFamily'];
}

export function EmptyTodo({ colorFamily }: EmptyTodoProps) {
  const color = colorFamilyToColor(colorFamily);
  const colorMap = EVENT_COLOR_MAP[color];

  const showArchivedLists = useAtomValue(showArchivedListsAtom);

  if (!showArchivedLists) return null;
  return (
    <div className={classNames('ml-8 text-s', `${colorMap.todoButtonText}`)}>
      This list is empty
    </div>
  );
}
