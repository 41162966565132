import { isDraftEvent } from 'components/Grid/utils';
import { userEmailAtom } from 'hooks/auth/authAtoms';
import { updateEventMutation } from 'hooks/events/api/eventsApi';
import {
  confirmEventChanges,
  EventConfirmationCallback,
  eventConfirmationsAtom,
  EVENT_CONFIRMATION_KEY_WHITELIST,
} from 'hooks/useEventConfirmations';
import { saveServerEventsToCacheAtomCallback } from 'hooks/useFetchCurrentWeekFromCache';
import { modalAtom } from 'hooks/useModal';
import { timezoneAtom } from 'hooks/useTimeZone';
import { Getter, Setter } from 'jotai';
import { EventName } from 'types/analytics';
import { IGridEvent } from 'types/events';
import { ModalType } from 'types/modal';
import { trackEvent } from 'utils/analytics';
import { ObjectKeys } from 'utils/toolbox';
import { MEET_LINK_PLACEHOLDER_DRAFT } from 'utils/video';
import {
  gridEventsFamily,
  optimisticEventsFamily,
  optimisticMutationTimestamps,
  serverEventsAtomFamily,
} from '../eventAtoms';
import { getEventsDiff } from '../helpers/eventsDiffHelpers';
import {
  formatServerEvent,
  formatUpdateEventPayload,
} from '../helpers/eventsHelpers';
import {
  optimisticUpdateRecurringInstances,
  syncRecurrenceRules,
  updateAllInstances,
} from '../helpers/recurringEventsHelpers';
import { createEventAtomCallback } from './createEventAtomCallback';
import {
  applyInteractionOnlyChangesAtomCallback,
  updateGridEventAtomCallback,
} from './updateEventAtomCallback';

const permittedGuestAttributes: (keyof IGridEvent)[] = [
  'colorFamily',
  'rsvp',
  'doneAt',
  'doneBy',
];

export interface SaveGridEventProps extends Partial<IGridEvent> {
  id: string;
  forceSave?: boolean;
}

export async function saveGridEventAtomCallback(
  get: Getter,
  set: Setter,
  props: SaveGridEventProps
): Promise<void> {
  applyInteractionOnlyChangesAtomCallback(get, set, { id: props.id });
  set(optimisticEventsFamily(props.id), (prevValues) => ({
    ...prevValues,
    ...props,
  }));
  const timezone = get(timezoneAtom);
  const userEmail = get(userEmailAtom);
  const mutationTimestamp = Date.now();
  const serverEvent = get(serverEventsAtomFamily(props.id));
  const updatedEvent = syncRecurrenceRules({
    updatedEvent: get(gridEventsFamily(props.id)),
    serverEvent,
  });

  if (!updatedEvent || updatedEvent?.status === 'cancelled') {
    return;
  }
  if (isDraftEvent(updatedEvent)) {
    return createEventAtomCallback(get, set, props);
  }

  const isEventPopoverOpen = get(modalAtom) === ModalType.Event;
  if (isEventPopoverOpen && !props.forceSave) {
    return updateGridEventAtomCallback(get, set, props);
  }

  set(optimisticMutationTimestamps(props.id), mutationTimestamp);
  const whitelistedKeys = updatedEvent?.canEdit
    ? EVENT_CONFIRMATION_KEY_WHITELIST
    : EVENT_CONFIRMATION_KEY_WHITELIST.filter((x) =>
        permittedGuestAttributes.includes(x)
      );

  const diff = getEventsDiff({
    oldEvent: serverEvent || {},
    newEvent: updatedEvent,
    whitelistedKeys,
  });

  if (!serverEvent || !updatedEvent || ObjectKeys(diff).length === 0) {
    return;
  }

  const setEventConfirmations = (updateValue: EventConfirmationCallback) =>
    set(eventConfirmationsAtom, updateValue);

  const { notifyGuests, applyToAllEvents, cancelled, notifyMessage } =
    await confirmEventChanges({
      diff,
      event: serverEvent,
      type: 'update',
      userEmail,
      setEventConfirmations,
    });

  if (cancelled) {
    set(optimisticEventsFamily(props.id), null);
    set(optimisticMutationTimestamps(props.id), null);
    return;
  }

  updatedEvent.attendees = updatedEvent.attendees.map(
    ({ status, ...attendee }) => attendee
  );

  const didUpdateRecurrenceRules = !!diff.recurrenceRules;

  if (applyToAllEvents || didUpdateRecurrenceRules) {
    optimisticUpdateRecurringInstances(
      get,
      set,
      updatedEvent,
      ObjectKeys(diff)
    );
  }

  const didChangeCalendar = diff.calendarId;
  const normalizedPayload = formatUpdateEventPayload(updatedEvent);

  if (didChangeCalendar) {
    normalizedPayload.prevCalendarId = serverEvent.calendarId;
  }

  saveServerEventsToCacheAtomCallback(get);
  const updatedServerEvent = await updateEventMutation({
    ...normalizedPayload,
    notifyGuests,
    notifyMessage,
    recurringEventId: applyToAllEvents ? updatedEvent.recurringEventId : null,
  });

  if (didChangeCalendar && updatedServerEvent?.id) {
    props.id = updatedServerEvent.id;
  }

  if (diff.recurrenceRules) {
    trackEvent(EventName.SetEventRecurring);
  }

  // Only apply if this mutation is from the latest optimistic update.
  const mostRecentOptimisticTimestamp = get(
    optimisticMutationTimestamps(props.id)
  );
  const hasBeenDeleted =
    get(optimisticEventsFamily(props.id))?.status === 'cancelled';
  if (updatedServerEvent && !hasBeenDeleted && !applyToAllEvents) {
    set(
      serverEventsAtomFamily(props.id),
      formatServerEvent(
        updatedServerEvent,
        updatedServerEvent.calendarId,
        timezone
      )
    );
  }
  if (
    updatedServerEvent &&
    !hasBeenDeleted &&
    !applyToAllEvents &&
    (!mostRecentOptimisticTimestamp ||
      mutationTimestamp >= mostRecentOptimisticTimestamp)
  ) {
    set(optimisticEventsFamily(props.id), null);
    set(optimisticMutationTimestamps(props.id), null);
  } else if (
    (updatedServerEvent?.location ||
      updatedServerEvent?.videoConferences?.[0]?.link) &&
    normalizedPayload.location === MEET_LINK_PLACEHOLDER_DRAFT
  ) {
    // Let's update the location and videoConferences when we request a meet link
    if (applyToAllEvents) {
      updateAllInstances(get, set, {
        ...updatedEvent,
        location: updatedServerEvent.location,
        videoConferences: updatedServerEvent.videoConferences,
      });
    } else {
      set(optimisticEventsFamily(props.id), (prev) => ({
        ...prev,
        location: updatedServerEvent.location,
        videoConferences: updatedServerEvent.videoConferences,
      }));
    }
  }
  saveServerEventsToCacheAtomCallback(get);
}
