import { useUpdateModal } from 'hooks/useModal';
import React, { useCallback, useRef } from 'react';
import {
  HookProps,
  ListGroup,
  NavItemType,
  NavItemNestedActions,
  NavItemChronoActions,
} from '../types';
import { useProfileKey } from 'hooks/useProfileKey';
import useSetNavItems from './useSetNavItems';
import { options as reminderFrequencyOptions } from 'components/Profile/WidgetReminder';
import { useContactReminder } from 'hooks/useContactReminder';
import { useSetBirthdayWithReminderMutation } from 'graphql/mutations/UpdateContact.graphql';
import { ChronoGroupResult } from './NestedQuickMenuGroups/useChronoGroup';
import { filterBySearch } from './useListGroups';
import IconBell from 'components/Icons/IconBell';
import IconCake from 'components/Icons/IconCake';
import IconClock from 'components/Icons/IconClock';
import useProfile from 'hooks/useProfile';
import { useBirthdayUpdate } from 'hooks/useBirthday';
import { usePreferences } from 'hooks/usePreferences';
import { formatOrdinal } from 'utils/time';

export default function useProfileActionsGroup({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const { addNavItem } = useSetNavItems();
  const { closeModal } = useUpdateModal();

  const key = useProfileKey();
  const profile = useProfile(key);
  const { setReminder } = useContactReminder(profile?.contactId);
  const [, updateContact] = useSetBirthdayWithReminderMutation();
  const { setDate } = useBirthdayUpdate(key);

  const profileDisplayname =
    profile?.firstName && profile?.lastName
      ? `${profile?.firstName} ${profile?.lastName}`
      : profile?.email;

  const listGroupRef = useRef<ListGroup>({
    title: 'Profile',
    subtitle: profileDisplayname,
    items: [],
  });

  const ReminderIcon = useCallback(
    () => <IconBell className="h-5 w-5 p-0.5 text-gray-400" />,
    []
  );

  const RecurringIcon = useCallback(
    () => <IconClock className="h-5 w-5 p-0.5 text-gray-400" />,
    []
  );

  const BirthdayIcon = useCallback(
    () => <IconCake className="h-5 w-5 p-0.5 text-gray-400" />,
    []
  );

  const setReminderActionOverride = useCallback(() => {
    const navItem: NavItemNestedActions = {
      type: NavItemType.nestedActions,
      title: `Set reminder for ${profileDisplayname}`,
      inputPlaceholder: 'Choose the reminder frequency',
      hidden: true,
      actions: reminderFrequencyOptions.map((option) => ({
        id: `set-person-birthday-${option.label}`,
        title: option.label,
        keywords: [option.label],
        type: 'action',
        actionOverride: () => {
          closeModal();
          setReminder(option.frequency);
        },
        Icon: RecurringIcon,
      })),
    };

    addNavItem({
      type: navItem.type,
      fullObject: navItem,
    });
  }, [RecurringIcon, addNavItem, closeModal, profileDisplayname, setReminder]);

  const setBirthdayActionOverride = useCallback(() => {
    const navItem: NavItemChronoActions = {
      type: NavItemType.chronoActions,
      title: `Set birthday for ${profileDisplayname}`,
      inputPlaceholder: 'i.e. June 12th',
      hidden: true,
      suggestOptions: false,
      showEmptyMessage: false,
      calendarDatesOnly: true,
      getFormattedSlot: (
        result: ChronoGroupResult,
        _preferences: ReturnType<typeof usePreferences>
      ) => {
        return result.start.toFormat('LLLL ') + formatOrdinal(result.start.day);
      },
      callback: (selection: ChronoGroupResult) => {
        if (!profile?.contactId) return;

        setDate(selection.start.toJSDate());
        closeModal();

        updateContact(
          {
            contactId: profile?.contactId,
            birthday: selection.start.toFormat('yyyy-MM-dd'),
          },
          { requestPolicy: 'cache-and-network' }
        ).catch(console.error);
      },
    };

    addNavItem({
      type: navItem.type,
      fullObject: navItem,
    });
  }, [
    addNavItem,
    closeModal,
    profileDisplayname,
    profile?.contactId,
    setDate,
    updateContact,
  ]);

  if (!enabled) {
    listGroupRef.current.items.length = 0;
    return listGroupRef.current;
  }

  listGroupRef.current.items = [
    {
      id: 'set-person-reminder',
      title: `Set reminder for ${profileDisplayname}`,
      keywords: ['reminder', 'person'],
      type: 'action',
      actionOverride: setReminderActionOverride,
      Icon: ReminderIcon,
    },
  ];

  // Only let you set a birthday for those without one set already.
  if (!profile?.birthday) {
    listGroupRef.current.items.push({
      id: 'set-person-birthday',
      title: `Set birthday for ${profileDisplayname}`,
      keywords: ['birthday', 'person'],
      type: 'action',
      actionOverride: setBirthdayActionOverride,
      Icon: BirthdayIcon,
    });
  }

  listGroupRef.current.items = filterBySearch(
    listGroupRef.current.items,
    searchTerm
  );
  return listGroupRef.current;
}
