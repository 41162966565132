import React, { useState } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { Emoji, EmojiData } from 'emoji-mart';
import Tooltip from 'joy/Tooltip';

const EmojiPopover = dynamic(() => import('./EmojiPopover'));

interface Props {
  emoji: EmojiData | null | undefined;
  onEmojiChange: (emoji: EmojiData) => void;
  variant?: 'small' | 'medium';
  tabIndex?: number;
}

export default function EmojiPicker({
  emoji,
  onEmojiChange,
  variant = 'small',
  tabIndex,
}: Props) {
  const [displayEmoji, setDisplayEmoji] = useState(getRandomEmoji);

  return (
    <EmojiPopover
      onClick={onEmojiChange}
      onSelect={onEmojiChange}
      tabIndex={tabIndex}
    >
      <div
        className="group flex h-full w-full items-center justify-center"
        onMouseEnter={() => setDisplayEmoji(getRandomEmoji())}
      >
        {emoji ? (
          <Emoji
            emoji={emoji}
            set="apple"
            size={variant === 'small' ? 20 : 24}
          />
        ) : (
          <Tooltip content="Set emoji">
            <span
              className={classNames(
                'flex scale-100  leading-none grayscale transition-all duration-75 group-hover:scale-105 group-hover:grayscale-0',
                {
                  'text-[20px]': variant === 'small',
                  'text-[24px]': variant === 'medium',
                }
              )}
            >
              {displayEmoji}
            </span>
          </Tooltip>
        )}
      </div>
    </EmojiPopover>
  );
}

const nativeEmojis = [
  '🥲',
  '😍',
  '🤩',
  '🥵',
  '😎',
  '🤯',
  '🤖',
  '👻',
  '😥',
  '😏',
  '🤪',
  '😝',
  '🙃',
  '🤣',
  '🥳',
];

function getRandomEmoji(): string {
  return nativeEmojis[Math.floor(Math.random() * nativeEmojis.length)];
}
