import IconBell from 'components/Icons/IconBell';
import { FrequencyOption, useContactReminder } from 'hooks/useContactReminder';
import Button from 'joy/Button';
import Dropdown, { DropdownItem } from 'joy/Dropdown';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { TProfileMeetInfo } from 'types/profile';

export const options: {
  label: string;
  frequency: FrequencyOption | undefined;
}[] = [
  { label: 'None', frequency: undefined },
  { label: 'Weekly', frequency: FrequencyOption.WEEKLY },
  { label: 'Monthly', frequency: FrequencyOption.MONTHLY },
  { label: 'Quarterly', frequency: FrequencyOption.QUARTERLY },
  { label: 'Yearly', frequency: FrequencyOption.YEARLY },
];

interface Props extends NonNullable<TProfileMeetInfo> {
  contactId: string;
  firstName: string | null | undefined;
}

export default function WidgetReminder({
  firstName,
  contactId,
  lastSeen,
  lastSeenSource,
}: Props) {
  const { frequency, setReminder, ruleStr } = useContactReminder(contactId);

  const frequencyValue = useMemo(() => {
    if (frequency === FrequencyOption.CUSTOM) return ruleStr || '';
    if (frequency === undefined) return undefined;

    return options.find((option) => option.frequency === frequency)?.label;
  }, [frequency, ruleStr]);

  const items = useMemo((): DropdownItem[] => {
    const menuItems: DropdownItem[] = options.map((option) => ({
      type: 'option',
      value: option.label,
      selected: option.frequency === frequency,
      onSelect: () => setReminder(option.frequency),
    }));

    menuItems.unshift({ value: 'Remind me', type: 'title' });

    return menuItems;
  }, [setReminder, frequency]);

  const lastInTouch = lastSeen
    ? DateTime.fromISO(lastSeen).toFormat(`ccc',' d MMMM`)
    : undefined;

  const emptyDescription =
    firstName == null
      ? `You haven't met before. Maybe it's about time?`
      : `You haven't met with ${firstName} before. Maybe it's about time?`;

  return (
    <div className="flex h-full w-full flex-col rounded-3xl bg-gradient-to-t from-gray-50 to-white p-6 dark:bg-gradient-to-b dark:from-gray-600/10 dark:to-gray-800/40">
      <div className="flex flex-col">
        <small className="mb-0.5 text-sm font-black uppercase tracking-wider text-yellow-400 dark:text-yellow-200">
          {lastInTouch ? 'Last together' : 'Never met'}
        </small>

        <p className="text-xl font-black">{lastInTouch || emptyDescription}</p>

        {lastInTouch && (
          <p className="text-secondary text-sm font-semibold">
            via {lastSeenSource}
          </p>
        )}
      </div>

      <div className="mt-auto flex w-full">
        <Dropdown offset={8} className="w-full" fullWidth={true} items={items}>
          <Button className="flex w-full items-center justify-center rounded-full bg-gray-200 py-3 text-sm font-semibold dark:bg-gray-700 dark:hover:bg-gray-600">
            <div className="mr-1.5 flex origin-top animate-bell text-gray-400 dark:text-gray-500">
              <IconBell />
            </div>
            {frequencyValue ? `${frequencyValue} reminder` : 'Set a reminder'}
          </Button>
        </Dropdown>
      </div>
    </div>
  );
}
