import { Category } from '@graphql-types@';
import classNames from 'classnames';
import React, { HTMLProps } from 'react';
import { colorFamilyToColor } from './utils';
import { EVENT_COLOR_MAP } from 'utils/eventColors';

export const CategoryWrapper = React.memo(
  React.forwardRef<
    HTMLDivElement,
    HTMLProps<HTMLDivElement> & {
      colorFamily?: Category['colorFamily'];
    }
  >(({ className, colorFamily, ...props }, ref) => {
    const color = colorFamilyToColor(colorFamily);
    const colorMap = EVENT_COLOR_MAP[color];

    return (
      <div
        ref={ref}
        {...props}
        className={classNames(
          'relative flex flex-col',
          'w-full rounded-md py-0.5',
          colorMap.todoText,
          'bg-opacity-0 transition-colors duration-500 ease-in-out dark:bg-opacity-0',
          `bg-${color}-200 dark:bg-${color}-300`,
          className
        )}
      />
    );
  })
);

CategoryWrapper.displayName = 'CategoryWrapper';
