/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Getter, Setter } from 'jotai/core/typeUtils';
import { useAtomCallback } from 'jotai/utils';
import { EventName } from 'types/analytics';
import { trackEvent } from 'utils/analytics';
import {
  gridEventsFamily,
  interactionOnlyEventsFamily,
  optimisticEventsFamily,
  serverEventsAtomFamily,
} from './eventAtoms';
import { createEventAtomCallback } from './eventsAtomCallbacks/createEventAtomCallback';
import { deleteEventAtomCallback } from './eventsAtomCallbacks/deleteEventAtomCallback';
import {
  createDraftEventAtomCallback,
  deleteDraftEventAtomCallback,
} from './eventsAtomCallbacks/draftEventAtomCallback';
import { saveGridEventAtomCallback } from './eventsAtomCallbacks/saveEventAtomCallback';
import {
  applyInteractionOnlyChangesAtomCallback,
  updateGridEventAtomCallback,
  updateGridEventForInteractionOnlyAtomCallback,
} from './eventsAtomCallbacks/updateEventAtomCallback';
import { generateEventUUID } from './helpers/eventsHelpers';

export function useUpdateGridEvent() {
  return {
    /**
     * Update without saving to backend the grid event
     */
    updateGridEvent: useAtomCallback(updateGridEventAtomCallback),

    /**
     * Revert any update made to the grid event via `updateGridEvent` or `updateGridEventForInteractionOnly`
     */
    revertGridEvent: useAtomCallback(_revertGridEvent),

    /**
     * Delete the grid event. Will prompt a confirmation modal
     */
    deleteGridEvent: useAtomCallback(deleteEventAtomCallback),

    /**
     * Save the grid event to backend. Will prompt a confirmation modal
     */
    saveGridEvent: useAtomCallback(saveGridEventAtomCallback),

    /**
     * Duplicate the grid event and save it to the backend
     */
    duplicateGridEvent: useAtomCallback(_duplicateGridEvent),

    /**
     * Create a draft event
     */
    createDraftEvent: useAtomCallback(createDraftEventAtomCallback),

    /**
     * Mark all draft events as 'cancelled'
     */
    deleteDraftEvent: useAtomCallback(deleteDraftEventAtomCallback),

    /**
     * Update the grid event for interaction only.
     * => Use this method for updating the event time on real time interaction (dragging, resizing, etc.)
     **/
    updateGridEventForInteractionOnly: useAtomCallback(
      updateGridEventForInteractionOnlyAtomCallback
    ),

    /**
     * Commit the updates to the grid event using `updateGridEvent`
     * => Use this method with `updateGridEventForInteractionOnly`
     **/
    applyInteractionOnlyChanges: useAtomCallback(
      applyInteractionOnlyChangesAtomCallback
    ),
  };
}

function _revertGridEvent(get: Getter, set: Setter, props: { id: string }) {
  const serverEvent = get(serverEventsAtomFamily(props.id));
  if (serverEvent) {
    set(optimisticEventsFamily(props.id), null);
    set(interactionOnlyEventsFamily(props.id), null);
  }
}

/**
 * Duplicating an event is the same as creating an event
 * as long as you give the id, the corresponding event will
 * be retrieved to make the copy
 */
async function _duplicateGridEvent(
  get: Getter,
  set: Setter,
  eventIdToDuplicate: string
): Promise<string | null> {
  trackEvent(EventName.DuplicatedEvent);
  const displayedGridEvent = get(gridEventsFamily(eventIdToDuplicate));
  if (!displayedGridEvent) {
    return null;
  }
  const newEventId = generateEventUUID(displayedGridEvent.calendarId);
  await createEventAtomCallback(get, set, {
    ...displayedGridEvent,
    id: newEventId,
    doneAt: null,
  });
  return newEventId;
}
