import React from 'react';

export default function IconEnter(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="11"
      fill="none"
      viewBox="0 0 10 11"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 7.25h5a3 3 0 003-3V.75m-8 6.5l3-3m-3 3l3 3"
      ></path>
    </svg>
  );
}
