import { atom, Getter, Setter } from 'jotai';
import { atomFamily, useAtomCallback, useAtomValue } from 'jotai/utils';
import { RefObject, useCallback, useEffect } from 'react';

type SelectionData = { id: string; position?: number };

export const inputFocusAtom = atom<SelectionData | null>(null);

export const isFocusEmpty = atom<boolean>((get) =>
  Boolean(get(inputFocusAtom))
);

const inputFocusAtomFamily = atomFamily((id) =>
  atom((get) => {
    const data = get(inputFocusAtom);
    return data?.id == id ? data : null;
  })
);

export function useSetInputFocus(): (data: SelectionData | null) => void {
  return useAtomCallback(
    useCallback((_: Getter, set: Setter, data: SelectionData | null) => {
      set(inputFocusAtom, data);
    }, [])
  );
}

export function useFocusControl(
  id: string,
  ref: RefObject<HTMLTextAreaElement>
): SelectionData | null {
  const data = useAtomValue(inputFocusAtomFamily(id));
  const isEmpty = useAtomValue(isFocusEmpty);

  useEffect(() => {
    const current = ref.current;
    if (current != null) {
      const isFocused = document.activeElement === current;

      if (isFocused) {
        if (data == null) {
          if (isEmpty) current.blur();
        } else {
          if (data.position != null) {
            if (current.selectionStart !== data.position) {
              current.setSelectionRange(data.position, data.position);
            }
          }
        }
      } else {
        if (data != null) {
          current.focus();

          // Defaults to the end as it's easier to pass zero than get length
          const position =
            data.position != null ? data.position : current.value.length;
          current.setSelectionRange(position, position);
        }
      }
    }
  }, [data, isEmpty, ref]);

  return data;
}
