import classNames from 'classnames';
import IconEyeClosed from 'components/Icons/IconEyeClosed';
import IconEyeOpened from 'components/Icons/IconEyeOpened';
import IconCheckboxMark from 'components/Icons/IconCheckboxMark';
import { useUpdateTodo } from 'components/Todos/TodoItem';
import { showArchivedListsAtom } from 'components/Todos/todosAtoms';
import { TodosQuery, TodosDocument } from 'graphql/queries/todos.graphql';
import { useUpdateModal } from 'hooks/useModal';
import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import { useAtomValue } from 'jotai/utils';
import React, { useMemo } from 'react';
import { PreferenceName } from 'types/preference';
import { useQuery } from 'urql';
import { HookProps, ListItem, ListItemAction, NavItemType } from '../types';
import { useScheduleIntoCalendar } from './TodoActions/useScheduleIntoCalendar';
import { filterBySearch } from './useListGroups';
import { useLastNavItem } from './useSetNavItems';

export default function useTodoActions({
  enabled,
  eventIds,
  searchTerm,
}: HookProps): ListItem[] {
  const lastNavItem = useLastNavItem();
  const todoItemId =
    lastNavItem?.type === NavItemType.todos ? lastNavItem.todoId : undefined;

  const showArchivedLists = useAtomValue(showArchivedListsAtom);
  const updateTodo = useUpdateTodo(todoItemId);

  // Somehow, this server query here is causing the app to crash. If we use todosAtom or optimisticTodosAtom,
  // it will work IF the sidebar has been opened previously (so that we aren't making the server request.)
  const [gqlResponse] = useQuery({
    query: TodosDocument,
    variables: { hideArchived: !showArchivedLists },
  });

  const optUpdate: TodosQuery | undefined = gqlResponse.data;

  const todoItem = useMemo(() => {
    return optUpdate?.todos.find((x) => x.id === todoItemId);
  }, [optUpdate?.todos, todoItemId]);

  const { closeModal } = useUpdateModal();
  const { showDoneTodos } = usePreferences();
  const updatePreference = useUpdatePreference();

  const scheduleIntoCalendar = useScheduleIntoCalendar({ todoItem });

  const options: ListItemAction[] = [];

  if (todoItem) {
    options.push(scheduleIntoCalendar, {
      id: 'markAsDone',
      title: todoItem.doneAt ? 'Mark as undone' : 'Mark as done',
      keywords: ['create', 'todo'],
      type: 'action',
      actionOverride: () => {
        updateTodo({
          doneAt: todoItem.doneAt ? null : new Date().toISOString(),
        });
        closeModal();
      },
      Icon: () => (
        <div
          className={classNames(
            'relative mx-0.5 flex items-center justify-center rounded-md transition-colors',
            'shadow-checkbox-gray-400 dark:shadow-checkbox-gray-400',
            'focus-within:ring-2 focus-within:ring-opacity-50'
          )}
          style={{ height: 16, width: 16 }}
        >
          <IconCheckboxMark className="h-4 w-4 text-gray-400 dark:text-gray-400" />
        </div>
      ),
    });
  }

  options.push({
    id: 'toggleDoneTodosVisibility',
    title: showDoneTodos ? 'Hide done todos' : 'Show done todos',
    keywords: ['create', 'todo'],
    type: 'action',
    actionOverride: () => {
      updatePreference(PreferenceName.ShowDoneTodos, !showDoneTodos);
      closeModal();
    },
    Icon: () =>
      showDoneTodos ? (
        <IconEyeClosed className="h-5 w-5 p-0.5 text-gray-400" />
      ) : (
        <IconEyeOpened className="h-5 w-5 p-0.5 text-gray-400" />
      ),
  });

  if (!enabled || eventIds.length > 0) {
    return [];
  } else {
    return filterBySearch(options, searchTerm);
  }
}
