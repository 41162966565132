import { SettingsPageType } from 'components/Settings/types';
import { perfEnd } from 'contexts/performance';
import useHotkey from 'hooks/useHotkey';
import { useUpdateModal, useUpdateSettingPageModal } from 'hooks/useModal';
import { useAtomValue } from 'jotai/utils';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalType } from 'types/modal';
import useListGroups from './hooks/useListGroups';
import { quickMenuNavItemsAtom, useLastNavItem } from './hooks/useSetNavItems';
import useTrappedMode, { TrappedMode } from './hooks/useTrappedMode';
import QuickMenuInput from './QuickMenuInput';
import QuickMenuList from './QuickMenuList';
import { ListItem } from './types';

export default function QuickMenuContent(): JSX.Element {
  const router = useRouter();
  const { trappedMode } = useTrappedMode();
  const setSettingsPage = useUpdateSettingPageModal();
  const { closeModal, openModal } = useUpdateModal();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const navItems = useAtomValue(quickMenuNavItemsAtom);
  const lastNavItem = useLastNavItem();

  const { list, groups, focusId } = useListGroups({ searchTerm });
  const isEmpty = !searchTerm && !selectedItemId;
  const noResultsFound = list.length === 0 && !isEmpty;

  useEffect(() => {
    perfEnd('open-quick-menu');
  }, []);

  useEffect(() => {
    setSelectedItemId(focusId || null);
  }, [focusId]);

  useEffect(() => {
    setSearchTerm('');
  }, [lastNavItem]);

  const inputPlaceholderText = useMemo(() => {
    const lastNavItem =
      navItems?.length > 0 ? navItems[navItems.length - 1] : null;

    switch (trappedMode) {
      case TrappedMode.AddCalendar:
        return 'Add a calendar...';

      default:
        return lastNavItem?.inputPlaceholder || 'Search or jump to...';
    }
  }, [trappedMode, navItems]);

  const selectedIndex = selectedItemId
    ? list.findIndex((item) => item.id === selectedItemId)
    : -1;

  const navigateUp = useCallback(() => {
    const prevIndex = Math.max(selectedIndex - 1, 0);
    const prevItem = list[prevIndex];
    if (!prevItem) return;

    setSelectedItemId(prevItem.id);
  }, [list, selectedIndex]);

  const navigateDown = useCallback(() => {
    const nextIndex = Math.min(selectedIndex + 1, list.length - 1);
    const nextItem = list[nextIndex];
    if (!nextItem) return;

    setSelectedItemId(nextItem.id);
  }, [list, selectedIndex]);

  const onChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.currentTarget.value);
    },
    []
  );

  useEffect(() => {
    if (selectedIndex === -1) return;
    const selectedItem = list[selectedIndex];

    if (selectedItem == null) return;
  }, [list, selectedIndex]);

  const handleItemConfirm = useCallback(
    (item: ListItem) => {
      if (item.actionOverride !== undefined) {
        item.actionOverride();
        return;
      }

      switch (item.type) {
        case 'event':
          // TODO: Handle adding a breadcrumb for the event
          break;

        case 'contact':
          closeModal(ModalType.QuickMenu);
          router.push('/' + item.email, undefined, { shallow: true });
          break;
        case 'navigation':
          setSettingsPage(item.page ? item.page : SettingsPageType.Profile);
          openModal(ModalType.Settings); // closes the quick menu before opening
          break;
      }
    },
    [openModal, closeModal, setSettingsPage] // eslint-disable-line
  );

  useHotkey('up', { scope: 'quickmenu', enabledWithinInput: true }, (event) => {
    event.preventDefault();
    navigateUp();
  });

  useHotkey(
    'down',
    { scope: 'quickmenu', enabledWithinInput: true },
    (event) => {
      event.preventDefault();
      navigateDown();
    }
  );

  useHotkey(
    'enter',
    { scope: 'quickmenu', enabledWithinInput: true },
    (event) => {
      event.preventDefault();
      const selectedItem = list[selectedIndex];
      if (!selectedItem) return;
      handleItemConfirm(selectedItem);
    }
  );

  return (
    <div
      className="z-100 flex w-full flex-col rounded-xl bg-white py-0.5 shadow-quickmenu transition-colors dark:bg-gray-750"
      style={{ width: 550, height: 364 }}
    >
      <QuickMenuInput
        value={searchTerm}
        onChange={onChangeSearch}
        placeholder={inputPlaceholderText}
      />
      <QuickMenuList
        isEmpty={isEmpty}
        noResultsFound={noResultsFound}
        selectedItemId={selectedItemId}
        setSelectedItemId={setSelectedItemId}
        groups={groups}
        handleItemConfirm={handleItemConfirm}
      />
    </div>
  );
}
