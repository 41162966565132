import React from 'react';
import IconSearch from 'components/Icons/IconSearch';
import { motion } from 'framer-motion';

export default function QuickMenuEmptyState({
  content,
  icon,
}: {
  content: React.ReactElement | string;
  icon: React.ReactElement;
}): JSX.Element {
  const bounceTransition = {
    y: {
      duration: 0.7,
      repeat: Infinity,
      repeatType: 'reverse',
      ease: 'easeOut',
    },
  };

  return (
    <div className="flex flex-col flex-grow w-full space-y-2 items-center justify-center text-center">
      <motion.div animate={{ y: ['5%', '-5%'] }} transition={bounceTransition}>
        <div className="flex text-icons opacity-60 relative justify-center items-center">
          <IconSearch strokeWidth={0.5} className={'w-10 h-10'} />
          {icon}
        </div>
      </motion.div>
      <small className="text-gray-400 dark:text-gray-500 font-medium">
        {content}
      </small>
    </div>
  );
}
