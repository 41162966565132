import { TodosQuery } from 'graphql/queries/todos.graphql';
import { TooltipOwnProps } from 'joy/Tooltip';
import React from 'react';
import TContact from 'types/contact';
import { IGridEvent } from '../../../types/events';
import { NavItem, NavItemType } from '../types';

export function isNavItemEnabled(
  navItemType: NavItemType | NavItemType[],
  navItems: NavItem[]
): boolean {
  return navItems.some((navItem) =>
    Array.isArray(navItemType)
      ? navItemType.some((type) => navItem.type === type)
      : navItem.type === navItemType
  );
}

export function getEventsBreadcrumbTitle(selectedEvents: IGridEvent[]): string {
  return selectedEvents.length !== 1
    ? `${selectedEvents.length} events`
    : selectedEvents[0].title;
}

export function EventsBreadcrumbTooltipContent(
  selectedEvents: IGridEvent[]
): TooltipOwnProps['content'] {
  const eventsSubset = selectedEvents.slice(0, 3);
  const numberOfSelectedEvents = selectedEvents.length;

  return (
    <div className="text-xs">
      {eventsSubset.map((event) => (
        <div key={event.id}>{event.title}</div>
      ))}
      {numberOfSelectedEvents > 3 && (
        <div>+ {numberOfSelectedEvents - 3} more</div>
      )}
    </div>
  );
}

export function getTodosBreadcrumbTitle(
  todo: TodosQuery['todos'][0] | undefined
): string {
  return todo?.name || 'Todo item without a title';
}

export function TodosBreadcrumbTooltipContent(
  todo: TodosQuery['todos'][0] | undefined
): TooltipOwnProps['content'] {
  return (
    <div className="text-xs">
      <div>{todo?.name}</div>
    </div>
  );
}

export function getTodoCategoryBreadcrumbTitle(
  category: TodosQuery['categories'][0] | undefined
): string {
  return category?.name || 'Todo category without a title';
}

export function TodoCategoryBreadcrumbTooltipContent(
  category: TodosQuery['categories'][0] | undefined
): TooltipOwnProps['content'] {
  return (
    <div className="text-xs">
      <div>{category?.name}</div>
    </div>
  );
}

export function getContactTitle(contact: TContact): string {
  return contact.displayName || contact.emailAddress;
}

export function getContactDescription(contact: TContact): string {
  return (contact.displayName && contact.emailAddress?.split('@')[1]) || '';
}
