import { visibleEventsAtom } from 'hooks/events/eventAtoms';
import { calendarEndDateAtom, calendarStartDateAtom } from 'hooks/useCalendar';
import { eventsSelectionAtom } from 'hooks/useEventsSelection';
import { Getter, Setter } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { DateTime } from 'luxon';
import { IGridEvent } from 'types/events';
import {
  coordinatesToTargetDate,
  getCoordinates,
  getTargetDimensions,
} from 'utils/mouseEvents';
import {
  DEFAULT_SELECTION_FRAME_PROPS,
  gridSelectionFrameAtom,
} from '../GridSelectionFrame';

export function useHandleSelectionFrame() {
  const updateSelectionFrame = useAtomCallback(
    updateSelectionFrameAtomCallback
  );
  const clearSelectionFrame = useAtomCallback(clearSelectionFrameAtomCallback);
  return {
    updateSelectionFrame,
    clearSelectionFrame,
  };
}

function updateSelectionFrameAtomCallback(
  get: Getter,
  set: Setter,
  {
    x,
    y,
    mouseEvent,
  }: { x: number; y: number; mouseEvent: React.MouseEvent<HTMLDivElement> }
) {
  const visibleEvents = get(visibleEventsAtom);
  const startDay = get(calendarStartDateAtom);
  const endDay = get(calendarEndDateAtom);
  const gridDimensions = getTargetDimensions(mouseEvent);
  const mouseCoordinates = getCoordinates(mouseEvent);

  const minDate = coordinatesToTargetDate({
    coordinates: {
      x: Math.min(x, mouseCoordinates.x),
      y: Math.min(y, mouseCoordinates.y),
    },
    width: gridDimensions.width,
    height: gridDimensions.height,
    startDay,
    endDay,
    roundToNearestQuarterHour: false,
  });
  const maxDate = coordinatesToTargetDate({
    coordinates: {
      x: Math.max(x, mouseCoordinates.x),
      y: Math.max(y, mouseCoordinates.y),
    },
    width: gridDimensions.width,
    height: gridDimensions.height,
    startDay,
    endDay,
    roundToNearestQuarterHour: false,
  });

  if (!minDate || !maxDate) {
    return;
  }

  set(gridSelectionFrameAtom, {
    x1: x,
    y1: y,
    x2: mouseCoordinates.x,
    y2: mouseCoordinates.y,
  });

  const nextSelection = visibleEvents
    .filter((event) => belongsToSelection({ event, minDate, maxDate }))
    .map((event) => event.id);

  set(eventsSelectionAtom, (prevSelection) => {
    if (prevSelection.length === nextSelection.length) {
      return prevSelection;
    }
    return nextSelection;
  });
}

function clearSelectionFrameAtomCallback(_get: Getter, set: Setter) {
  set(gridSelectionFrameAtom, DEFAULT_SELECTION_FRAME_PROPS);
}

function belongsToSelection({
  event,
  minDate,
  maxDate,
}: {
  event: IGridEvent;
  minDate: DateTime;
  maxDate: DateTime;
}): boolean {
  const intersectDays =
    event.startAt.ordinal >= minDate.ordinal &&
    event.endAt.ordinal <= maxDate.ordinal;

  const eventStart = minuteOfDay(event.startAt);
  const eventEnd = minuteOfDay(event.endAt);
  const dayStart = minuteOfDay(minDate);
  const dayEnd = minuteOfDay(maxDate);

  const intersectMinutes =
    (dayStart >= eventStart && dayStart <= eventEnd) ||
    (dayEnd >= eventStart && dayEnd <= eventEnd) ||
    (eventStart >= dayStart && eventEnd <= dayEnd);

  return intersectDays && intersectMinutes;
}

function minuteOfDay(date: DateTime): number {
  return date.hour * 60 + date.minute;
}
