import React from 'react';
import { IconSVGProps } from 'types/icon';

const IconSearch: React.FC<IconSVGProps> = ({
  height = 16,
  width = 16,
  className,
  strokeWidth = 0,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.97168 11.4224C6.94287 11.4224 7.85693 11.1431 8.63135 10.6606L11.2466 13.2759C11.4561 13.4854 11.7417 13.5869 12.0337 13.5869C12.6494 13.5869 13.1128 13.1108 13.1128 12.4951C13.1128 12.2158 13.0176 11.9365 12.8018 11.7271L10.2119 9.13086C10.7451 8.32471 11.0498 7.36621 11.0498 6.33789C11.0498 3.54492 8.76465 1.25342 5.97168 1.25342C3.17871 1.25342 0.887207 3.53857 0.887207 6.33789C0.887207 9.13086 3.17871 11.4224 5.97168 11.4224ZM5.97168 9.87354C4.02295 9.87354 2.42969 8.28027 2.42969 6.33789C2.42969 4.39551 4.02295 2.80225 5.97168 2.80225C7.91406 2.80225 9.50732 4.39551 9.50732 6.33789C9.50732 8.28027 7.91406 9.87354 5.97168 9.87354Z"
        fill="currentColor"
        strokeWidth={strokeWidth}
        stroke={'white'}
      />
    </svg>
  );
};

export default IconSearch;
