import { useId } from '@react-aria/utils';
import { useFocusTrap } from 'hooks/useFocusTrap';
import Input from 'joy/Input';
import React, { useCallback, useRef } from 'react';
import useSetNavItems from './hooks/useSetNavItems';
import QuickMenuBreadcrumbs from './QuickMenuBreadcrumbs';

interface Props {
  value: string;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function QuickMenuInput({
  value,
  onChange,
  placeholder,
}: Props): JSX.Element {
  const inputName = useId();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { deleteLastItem } = useSetNavItems();
  useFocusTrap({ ref: inputRef, active: true });

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Backspace' && value.length === 0) {
        deleteLastItem();
      }
    },
    [deleteLastItem, value]
  );

  return (
    <div className="flex items-center border-b border-gray-100 px-5 dark:border-gray-700">
      <QuickMenuBreadcrumbs />
      <label htmlFor={inputName} className="flex h-16 w-full items-center">
        <Input
          name={inputName}
          ref={inputRef}
          type="text"
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className="w-full truncate bg-transparent py-5 text-base font-medium placeholder-gray-400 outline-none dark:placeholder-gray-500"
          placeholder={placeholder}
          spellCheck={false}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
        />
      </label>
    </div>
  );
}
