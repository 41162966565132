import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function Hidden({ children }: Props): JSX.Element {
  return (
    <div
      style={{
        position: 'absolute',
        border: 0,
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: '1px',
        width: '1px',
        overflow: 'hidden',
        padding: 0,
        whiteSpace: 'nowrap',
        wordWrap: 'normal',
      }}
    >
      {children}
    </div>
  );
}
