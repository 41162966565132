import React from 'react';
import { modalAtom } from 'hooks/useModal';
import { DateTime } from 'luxon';
import { useAtomCallback } from 'jotai/utils';
import { Getter, Setter } from 'jotai';
import { mouseEventToTargetDateAtomCallback } from '../helpers/gridHelpers';

export default function useDragToCreate() {
  const onDragToCreate = useAtomCallback(onPointerMoveAtomCallback);
  return {
    onDragToCreate,
  };
}

function onPointerMoveAtomCallback(
  get: Getter,
  set: Setter,
  {
    mouseEvent,
    originalClickedDate,
    onUpdate,
  }: {
    mouseEvent: React.MouseEvent<HTMLDivElement>;
    originalClickedDate: DateTime;
    onUpdate(props: {
      startAt: DateTime;
      endAt: DateTime;
      get: Getter;
      set: Setter;
    }): void;
  }
) {
  if (get(modalAtom)) return;
  const targetDate = mouseEventToTargetDateAtomCallback(get, set, {
    mouseEvent,
    roundToNearestQuarterHour: true,
  });

  if (
    !targetDate ||
    Math.abs(targetDate.diff(originalClickedDate).as('minutes')) < 15
  ) {
    return;
  }

  const startAt = DateTime.min(
    originalClickedDate.set({
      hour: targetDate.hour,
      minute: targetDate.minute,
    }),
    originalClickedDate
  );
  const endAt = DateTime.max(
    originalClickedDate.set({
      hour: targetDate.hour,
      minute: targetDate.minute,
    }),
    originalClickedDate
  );
  onUpdate({ get, set, startAt, endAt });
}
