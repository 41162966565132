export enum SettingsPageType {
  Profile = 'Profile',
  Connections = 'Connections',
  Appearance = 'Appearance',
  Meetings = 'Meetings',
  Accounts = 'Accounts',
  Calendar = 'Calendar',
  Notifications = 'Notifications',
  Shortcuts = 'Shortcuts',
  Download = 'Download',
  WhatsNew = 'WhatsNew',
  Feedback = 'Feedback',
}

type SettingsItemNotification = {
  callback?: () => void;
  hasNotification: boolean;
};
export interface SettingsPage {
  title: string;
  type: SettingsPageType;
  icon?: () => JSX.Element;
  notification?: SettingsItemNotification;
}

export interface SettingsPageGroup {
  title?: string;
  pages: SettingsPage[];
}

export interface SettingsPageGroupSeparator {
  type: 'separator';
}
