import classNames from 'classnames';
import { atom, Getter } from 'jotai';
import { atomFamily, useAtomValue } from 'jotai/utils';
import React from 'react';
import { DraggableType } from 'types/drag-and-drop';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { DragHandle } from './DragHandle';
import { DropIndicator } from './DropIndicator';
import { TodoItem, TodoItemProps } from './TodoItem';
import { isDraggingAtom, useTodosDraggable } from './todos-dnd';
import {
  dragEventAtom,
  dragOverCategoryAtom,
  dragOverTodoAtom,
  dragTodoAtom,
} from './todosAtoms';
import { DropDirection } from './types';
import { colorFamilyToColor, useIsDragging } from './utils';

export function SortableTodoItem(props: TodoItemProps) {
  const { setNodeRef: ref, setDragHandleRef } =
    useTodosDraggable<HTMLDivElement>(props.id, DraggableType.TODO);

  const isDragging = useAtomValue(isDraggingAtom);
  const isBeingDragged = useIsDragging(props.id);

  const color = colorFamilyToColor(props.colorFamily);
  const colorMap = EVENT_COLOR_MAP[color];

  const dropDirection = useTodoDropDirection(props.id);

  return (
    <div
      className={classNames(
        'group relative flex w-full max-w-full transition-opacity',
        {
          'opacity-50': isBeingDragged,
        }
      )}
      ref={ref}
    >
      <DragHandle
        ref={setDragHandleRef}
        className={classNames(
          colorMap.todoButtonText,
          colorMap.todoButton,
          'opacity-0 transition-opacity',
          { 'group-hover:opacity-100': !isDragging }
        )}
      />

      <TodoItem {...props} />
      <DropIndicator direction={dropDirection} colorFamily={color} />
    </div>
  );
}

const todoDropDirectionAtom = atom<DropDirection>((get) => {
  const dragEvent = get(dragEventAtom);
  if (dragEvent != null) return 'above';

  const dragTodo = get(dragTodoAtom);
  const dragOverTodo = get(dragOverTodoAtom);
  const dragOverCategory = get(dragOverCategoryAtom);

  if (!dragTodo || !dragOverTodo || !dragOverCategory) return 'none';
  if (dragTodo.categoryId !== dragOverCategory.id) return 'above';

  const currentIndex = dragOverCategory.todos.findIndex(
    (item) => item.id === dragTodo.id
  );
  const overIndex = dragOverCategory.todos.findIndex(
    (item) => item.id === dragOverTodo.id
  );

  return overIndex >= currentIndex ? 'below' : 'above';
});

const todoDropDirectionAtomFamily = atomFamily((id: string) =>
  atom((get: Getter) => {
    if (get(dragOverTodoAtom)?.id !== id) return 'none';

    return get(todoDropDirectionAtom);
  })
);

export const useTodoDropDirection = (id: string) =>
  useAtomValue(todoDropDirectionAtomFamily(id));
