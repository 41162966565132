import { useEffect } from 'react';
import usePrevious from './usePrevious';

type ProperRef =
  | React.MutableRefObject<HTMLElement | null>
  | React.MutableRefObject<HTMLElement | null>[];

const isRef = (ref: unknown): ref is ProperRef => {
  if (Array.isArray(ref) && ref.every(isRef)) return true;
  if (typeof ref !== 'object') return false;
  if (ref && 'current' in ref === false) return false;

  return true;
};

const useClickOutside = <T, R>(
  ref: R,
  handler: (event: MouseEvent) => void,

  /**
   * If this variable value changes, make sure the listener is re-loaded.
   */
  observableVariable?: T
) => {
  const prevObservable = usePrevious(observableVariable);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!isRef(ref)) return;

      const containsTarget = Array.isArray(ref)
        ? ref.some(
            (innerRef) =>
              innerRef.current &&
              event.target instanceof Element &&
              innerRef.current.contains(event.target)
          )
        : event.target instanceof Element &&
          ref?.current?.contains(event.target);

      if (containsTarget) {
        return;
      }

      handler(event);
    }

    if (
      prevObservable &&
      observableVariable &&
      prevObservable !== observableVariable
    ) {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [observableVariable, prevObservable]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useClickOutside;

export function UseClickOutside(props: {
  onClickOutside: () => void;
  containerRef: React.MutableRefObject<HTMLFormElement | HTMLDivElement | null>;
}) {
  useClickOutside(props.containerRef, props.onClickOutside);
  return null;
}
