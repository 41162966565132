import classNames from 'classnames';
import React from 'react';
import { capitalize } from 'lodash';

interface Props {
  title: string;
  description?: string | React.ReactNode;
  noPadding?: boolean;
  comingSoon?: boolean;
}

export default function PageControlHeader({
  title,
  description,
  noPadding,
  comingSoon,
}: Props): JSX.Element {
  return (
    <div
      className={classNames('flex flex-col', {
        'mb-5': !noPadding && description,
        'mb-3.5': !noPadding && !description,
      })}
    >
      <div className="flex items-center space-x-2">
        <p className="text-base font-semibold">{capitalize(title)}</p>
        {comingSoon && (
          <div className="flex text-secondary uppercase text-[10px] font-bold tracking-wider rounded-full px-2.5 py-1 bg-gray-200 dark:bg-gray-750">
            Coming soon
          </div>
        )}
      </div>
      {description && (
        <p className="text-sm text-secondary mt-1">{description}</p>
      )}
    </div>
  );
}
