import useHotkey from 'hooks/useHotkey';
import React, { useCallback, useEffect, useRef } from 'react';
import QuickMenuEmptyState from './QuickMenuEmptyState';
import QuickMenuItem from './QuickMenuItem';
import { ListGroup, ListItem } from './types';
import IconHappy from './icons/IconHappy';
import IconQuestionMark from 'components/Icons/IconQuestionMark';
import { useLastNavItem } from './hooks/useSetNavItems';

interface Props {
  isEmpty?: boolean;
  noResultsFound?: boolean;
  selectedItemId: string | null;
  groups: ListGroup[];
  setSelectedItemId: (value: string | null) => void;
  handleItemConfirm: (item: ListItem) => void;
}

export default function QuickMenuList({
  isEmpty,
  noResultsFound,
  selectedItemId,
  groups,
  setSelectedItemId,
  handleItemConfirm,
}: Props): JSX.Element {
  const navigatedByMouse = useRef(false);
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const selectedItemRef = useRef<HTMLDivElement | null>(null);
  const lastNavItem = useLastNavItem();

  const onItemMouseMove = useCallback(
    (item: ListItem) => () => {
      navigatedByMouse.current = true;
      setSelectedItemId(item.id);
    },
    [setSelectedItemId]
  );

  const onItemClick = useCallback(
    (item: ListItem) => () => handleItemConfirm(item),
    [handleItemConfirm]
  );

  useHotkey(
    'enter',
    {
      scope: 'quickmenu',
      enabled: selectedItemId != null,
      enabledWithinInput: false,
      enabledWithinContentEditable: false,
    },
    (event) => {
      event.preventDefault();

      for (let i = 0; i < groups.length; i++) {
        for (let j = 0; j < groups[i].items.length; j++) {
          if (groups[i].items[j].id === selectedItemId) {
            onItemClick(groups[i].items[j]);
            break;
          }
        }
      }
    },
    [groups]
  );

  useEffect(() => {
    if (!selectedItemId) return;
    if (navigatedByMouse.current) {
      navigatedByMouse.current = false;
      return;
    }

    selectedItemRef.current?.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
    });

    navigatedByMouse.current = false;
  }, [selectedItemId]);

  return (
    <div
      ref={scrollableRef}
      className="group flex flex-grow flex-col overflow-y-auto"
    >
      {(isEmpty || noResultsFound) &&
      lastNavItem?.showEmptyMessage !== false ? (
        <QuickMenuEmptyState
          content={
            noResultsFound ? (
              <>
                You&#39;re a trailblazer! This is a dead end. <br /> Try another
                way?
              </>
            ) : (
              <>
                Find anything in your calendar - <br /> events, todos, people,
                settings
              </>
            )
          }
          icon={
            noResultsFound ? (
              <IconQuestionMark
                className={'absolute left-px ml-1.5 -mt-1 h-5 w-5'}
              />
            ) : (
              <IconHappy className={'absolute left-px ml-2 h-5 w-5'} />
            )
          }
        />
      ) : (
        groups.map(({ title, subtitle, items }) => (
          <div key={title} className="mt-4 flex flex-col">
            <div className="mb-1 flex items-center space-x-1 px-5">
              <small className="text-[11px] font-black uppercase leading-snug tracking-wider text-gray-500 dark:text-gray-400">
                {title}
              </small>
              {subtitle && (
                <small className="text-[11px] font-black uppercase leading-snug tracking-wider text-gray-400 dark:text-gray-500">
                  {subtitle}
                </small>
              )}
            </div>

            {items.map((item) => (
              <QuickMenuItem
                key={item.id}
                ref={item.id === selectedItemId ? selectedItemRef : null}
                selected={item.id === selectedItemId}
                item={item}
                onMouseMove={onItemMouseMove(item)}
                onClick={onItemClick(item)}
              />
            ))}
          </div>
        ))
      )}
    </div>
  );
}
