import { SettingsPageType } from 'components/Settings/types';
import { usePreferences } from 'hooks/usePreferences';
import type { TooltipOwnProps } from 'joy/Tooltip';
import TContact from 'types/contact';
import { ChronoGroupResult } from './hooks/NestedQuickMenuGroups/useChronoGroup';

export type ListItemType =
  | 'contact'
  | 'event'
  | 'upcomingEvent'
  | 'navigation'
  | 'calendar'
  | 'eventsSelectionOption'
  | 'timezone'
  | 'action';

interface ListItemBase {
  id: string;
  title: string;
  time?: string;
  Icon: () => JSX.Element | null;
  type: ListItemType;
  actionNameOverride?: string;
  actionOverride?: () => void;
}

export interface ListItemEvent extends ListItemBase {
  type: 'event';
  description: string;
}

export interface ListItemUpcoming extends ListItemBase {
  type: 'upcomingEvent';
  description: string;
}

export interface ListItemEventsSelectionOption extends ListItemBase {
  type: 'eventsSelectionOption';
  keywords?: string[];
}

export interface ListItemContact extends ListItemBase {
  type: 'contact';
  description: string;
  email: string;
}

export interface ListItemCalendar extends ListItemBase {
  type: 'calendar';
  description: string;
  email: string;
}
export interface ListItemNavigation extends ListItemBase {
  type: 'navigation';
  page?: SettingsPageType;
}

export interface ListItemTimezone extends ListItemBase {
  type: 'timezone';
}

export interface ListItemAction extends ListItemBase {
  type: 'action';
  keywords: string[];
  onlyAppearsInSearch?: boolean;
}

export type ListItem =
  | ListItemEvent
  | ListItemUpcoming
  | ListItemContact
  | ListItemContact
  | ListItemCalendar
  | ListItemEventsSelectionOption
  | ListItemNavigation
  | ListItemTimezone
  | ListItemAction;

export interface HookProps {
  searchTerm?: string;
  enabled?: boolean;
  eventIds: string[];
}

export interface ListGroup {
  title: string;
  subtitle?: string | null;
  items: ListItem[];
}

export interface ListGroups {
  focusId?: string;
  groups: ListGroup[];
  list: ListItem[];
}

export enum NavItemType {
  events = 'events',
  todos = 'todos',
  todoCategory = 'todoCategory',
  calendars = 'calendars',
  timezones = 'timezones',
  nestedActions = 'nestedActions',
  chronoActions = 'chronoActions',
  textInput = 'textInput',
  contactSearch = 'contactSearch',
  locationPicker = 'locationPicker',
}

export interface NavItemBase {
  type: NavItemType;
  title: string;
  inputPlaceholder?: string;
  hidden?: boolean;
  remove?: () => void;
  tooltipContent?: TooltipOwnProps['content'];
  eventIds?: string[];

  // If this is false, then don't show messages regarding
  // no suggested options or no search results.
  showEmptyMessage?: boolean;
}

export interface NavItemEvents extends NavItemBase {
  type: NavItemType.events;
}

export interface NavItemTodos extends NavItemBase {
  type: NavItemType.todos;
  todoId?: string;
}

export interface NavItemTodoCategory extends NavItemBase {
  type: NavItemType.todoCategory;
  todoCategoryId?: string;
}

export interface NavItemTimezones extends NavItemBase {
  type: NavItemType.timezones;
}

export interface NavItemLocationPicker extends NavItemBase {
  type: NavItemType.locationPicker;
  onChooseLocation: (location: string | undefined) => void;
}

export interface NavItemNestedActions extends NavItemBase {
  type: NavItemType.nestedActions;
  actions: ListItem[];
}

export interface NavItemChronoActions extends NavItemBase {
  type: NavItemType.chronoActions;
  calendarDatesOnly?: boolean;
  suggestOptions?: boolean;
  eventDurationMinutes?: number;
  getFormattedSlot?: (
    result: ChronoGroupResult,
    preferences: ReturnType<typeof usePreferences>
  ) => string;
  getActionNameOverride?: (input?: string) => string;
  callback: (selection: ChronoGroupResult) => void;
}

export interface NavItemTextInput extends NavItemBase {
  type: NavItemType.textInput;
  getActionNameOverride?: (input: string) => string;
  callback: (text: string) => void;
}

export interface NavItemContactSearch extends NavItemBase {
  type: NavItemType.contactSearch;
  getActionNameOverride?: (contact: TContact) => string;
  callback: (contact: TContact) => void;
  onlySameDomain?: boolean;
}

export type NavItem =
  | NavItemEvents
  | NavItemTodos
  | NavItemTodoCategory
  | NavItemTimezones
  | NavItemNestedActions
  | NavItemChronoActions
  | NavItemContactSearch
  | NavItemLocationPicker
  | NavItemTextInput;

export type QuickMenuNavItems = NavItem[];
