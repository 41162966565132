/* eslint-disable react/display-name */

import { HookProps, ListGroup } from 'components/QuickMenu/types';
import { getSearchedEvents, isSearching } from 'components/QuickMenu/utils';
import { useVisibleEvents } from 'hooks/events/useGridEvents';
import React, { useRef } from 'react';
import { CalendarIcon } from '../icons/CalendarIcon';

export default function useEventSearch({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const events = useVisibleEvents();

  const listGroupRef = useRef<ListGroup>({
    title: 'Search results',
    items: [],
  });
  listGroupRef.current.items.length = 0;

  if (!enabled) return listGroupRef.current;
  if (!isSearching(searchTerm)) return listGroupRef.current;

  const searchedEvents = getSearchedEvents(events, searchTerm);
  searchedEvents.map((event) => {
    listGroupRef.current.items.push({
      id: event.id,
      title: event.title,
      description: event.isAllDay
        ? 'All day'
        : `${event.startAt.toFormat('HH:mm')} - ${event.endAt.toFormat(
            'HH:mm'
          )}`,
      type: 'event',
      Icon: () => <CalendarIcon calendarId={event.calendarId} />,
    });
  });

  return listGroupRef.current;
}
