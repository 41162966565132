import { useAtomValue } from 'jotai/utils';
import { MutableRefObject, useMemo, useRef } from 'react';
import { useThrottle } from 'react-use';
import {
  HookProps,
  ListGroup,
  ListGroups,
  ListItem,
  NavItemType,
} from '../types';
import { isSearching } from '../utils';
import { isNavItemEnabled } from './navItemsHelpers';
import { useCalendarsListGroup } from './useCalendarsListGroup';
import { useContactsListGroup } from './useContactsListGroup';
import useEventSearch from './useEventSearch';
import useEventsSelectionGroup from './useEventsSelectionGroup';
import { useFavoritesListGroup } from './useFavoritesListGroup';
import { quickMenuNavItemsAtom } from './useSetNavItems';
import useTodaysEvents from './useTodaysEvents';
import useTrappedMode, { TrappedMode } from './useTrappedMode';
import useUpcomingEvent from './useUpcomingEvent';
import { useTimezonesListGroup } from './useTimezonesListGroup';
import { useRecentTimezonesListGroup } from './useRecentTimezonesListGroup';
import useActions from './useActions';
import { useRouter } from 'next/router';
import { isProfilePage } from 'utils/router';
import useProfileActionsGroup from './useProfileActionsGroup';
import useNestedActionsGroup from './NestedQuickMenuGroups/useNestedActionsGroup';
import useChronoGroup from './NestedQuickMenuGroups/useChronoGroup';
import useTextInputGroup from './NestedQuickMenuGroups/useTextInputGroup';
import useAppearanceActions from './useAppearanceActions';
import useNavigationActions from './useNavigationActions';
import useLocationInputGroup from './useLocationInputGroup';
import useTodoActionsGroup from './useTodoActionsGroup';

export default function useListGroups({
  searchTerm,
}: Omit<HookProps, 'enabled' | 'eventIds'>): ListGroups {
  const { trappedMode } = useTrappedMode();
  const router = useRouter();
  const flatListRef = useRef<ListItem[]>([]);
  flatListRef.current.length = 0;

  const listGroupsRef = useRef<ListGroup[]>([]);
  listGroupsRef.current.length = 0;

  const navItems = useAtomValue(quickMenuNavItemsAtom);
  const isEventSelectionGroupEnabled = isNavItemEnabled(
    NavItemType.events,
    navItems
  );

  const isTimezoneGroupEnabled = isNavItemEnabled(
    NavItemType.timezones,
    navItems
  );

  const isNestedActionsEnabled = isNavItemEnabled(
    [
      NavItemType.textInput,
      NavItemType.chronoActions,
      NavItemType.nestedActions,
      NavItemType.contactSearch,
      NavItemType.locationPicker,
      NavItemType.todos,
      NavItemType.todoCategory,
    ],
    navItems
  );

  const isTodoActionsEnabled = isNavItemEnabled(
    [NavItemType.todos, NavItemType.todoCategory],
    navItems
  );

  const navEventIds: string[] = useMemo(
    () =>
      navItems.reduce<string[]>(
        (prev, cur) => (cur.eventIds ? prev.concat(cur.eventIds) : prev),
        []
      ),
    [navItems]
  );

  const throttledTerm = useThrottle(searchTerm, 25);

  const eventSelectionGroup = useEventsSelectionGroup({
    enabled: isEventSelectionGroupEnabled,
    eventIds: navEventIds,
    searchTerm: throttledTerm,
  });

  const upcomingEventGroup = useUpcomingEvent({
    enabled:
      !isSearching(throttledTerm) &&
      !isEventSelectionGroupEnabled &&
      !isTimezoneGroupEnabled &&
      !isNestedActionsEnabled &&
      trappedMode !== TrappedMode.AddCalendar,
    eventIds: navEventIds,
    searchTerm,
  });

  const todaysEventsGroup = useTodaysEvents({
    enabled:
      !isSearching(throttledTerm) &&
      !isEventSelectionGroupEnabled &&
      !isTimezoneGroupEnabled &&
      !isNestedActionsEnabled &&
      trappedMode !== TrappedMode.AddCalendar,
    eventIds: navEventIds,
    searchTerm,
  });

  const searchedEventsGroup = useEventSearch({
    enabled:
      isSearching(searchTerm) &&
      !isEventSelectionGroupEnabled &&
      !isTimezoneGroupEnabled &&
      !isNestedActionsEnabled &&
      trappedMode !== TrappedMode.AddCalendar,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const searchedContactsGroup = useContactsListGroup({
    enabled:
      isNavItemEnabled(NavItemType.contactSearch, navItems) ||
      (isSearching(throttledTerm) &&
        !isEventSelectionGroupEnabled &&
        !isTimezoneGroupEnabled &&
        !isNestedActionsEnabled &&
        trappedMode !== TrappedMode.AddCalendar),
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const searchedCalendarsGroup = useCalendarsListGroup({
    enabled:
      !isEventSelectionGroupEnabled &&
      !isNestedActionsEnabled &&
      !isTimezoneGroupEnabled &&
      trappedMode === TrappedMode.AddCalendar,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const searchedFavoriteCalendarsGroup = useFavoritesListGroup({
    enabled:
      !isEventSelectionGroupEnabled &&
      !isNestedActionsEnabled &&
      !isTimezoneGroupEnabled &&
      trappedMode === TrappedMode.AddCalendar,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const recentTimezonesGroup = useRecentTimezonesListGroup({
    enabled: !isNestedActionsEnabled && isTimezoneGroupEnabled,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const searchedTimezonesGroup = useTimezonesListGroup({
    enabled: !isNestedActionsEnabled && isTimezoneGroupEnabled,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const actionsGroup = useActions({
    enabled:
      !isEventSelectionGroupEnabled &&
      !isTimezoneGroupEnabled &&
      !isNestedActionsEnabled &&
      trappedMode !== TrappedMode.AddCalendar,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const appearanceActionsGroup = useAppearanceActions({
    enabled:
      !isEventSelectionGroupEnabled &&
      !isTimezoneGroupEnabled &&
      !isNestedActionsEnabled &&
      trappedMode !== TrappedMode.AddCalendar,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const navigationActionsGroup = useNavigationActions({
    enabled:
      !isEventSelectionGroupEnabled &&
      !isTimezoneGroupEnabled &&
      !isNestedActionsEnabled &&
      trappedMode !== TrappedMode.AddCalendar,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const profileActionsGroup = useProfileActionsGroup({
    enabled:
      !isEventSelectionGroupEnabled &&
      !isTimezoneGroupEnabled &&
      !isNestedActionsEnabled &&
      trappedMode !== TrappedMode.AddCalendar &&
      isProfilePage(router),
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const nestedActionsGroup = useNestedActionsGroup({
    enabled: isNestedActionsEnabled && trappedMode !== TrappedMode.AddCalendar,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const chronoResultsGroup = useChronoGroup({
    enabled: isNestedActionsEnabled && trappedMode !== TrappedMode.AddCalendar,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const textInputGroup = useTextInputGroup({
    enabled: isNestedActionsEnabled && trappedMode !== TrappedMode.AddCalendar,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const locationInputGroup = useLocationInputGroup({
    enabled: isNestedActionsEnabled && trappedMode !== TrappedMode.AddCalendar,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  const todoActionsGroup = useTodoActionsGroup({
    enabled:
      (isTodoActionsEnabled || !isNestedActionsEnabled) &&
      !isTimezoneGroupEnabled &&
      trappedMode !== TrappedMode.AddCalendar,
    searchTerm: throttledTerm,
    eventIds: navEventIds,
  });

  mergeGroups(todoActionsGroup, listGroupsRef);
  mergeGroups(locationInputGroup, listGroupsRef);
  mergeGroups(textInputGroup, listGroupsRef);
  mergeGroups(chronoResultsGroup, listGroupsRef);
  mergeGroups(nestedActionsGroup, listGroupsRef);
  mergeGroups(profileActionsGroup, listGroupsRef);
  mergeGroups(actionsGroup, listGroupsRef);
  mergeGroups(appearanceActionsGroup, listGroupsRef);
  mergeGroups(navigationActionsGroup, listGroupsRef);
  mergeGroups(eventSelectionGroup, listGroupsRef);
  mergeGroups(recentTimezonesGroup, listGroupsRef);
  mergeGroups(searchedTimezonesGroup, listGroupsRef);
  mergeGroups(upcomingEventGroup, listGroupsRef);
  mergeGroups(todaysEventsGroup, listGroupsRef);
  mergeGroups(searchedEventsGroup, listGroupsRef);
  mergeGroups(searchedContactsGroup, listGroupsRef);
  mergeGroups(searchedFavoriteCalendarsGroup, listGroupsRef);
  mergeGroups(searchedCalendarsGroup, listGroupsRef);

  setFlatList(listGroupsRef.current, flatListRef);

  return {
    focusId: flatListRef.current?.[0]?.id,
    groups: listGroupsRef.current,
    list: flatListRef.current,
  };
}

export const filterBySearch = (
  options: ListItem[],
  searchTerm: string | undefined
): ListItem[] => {
  if (!searchTerm) return options;

  const filteredOptions = [];

  for (let index = 0; index < options.length; index++) {
    const element = options[index];

    if (isSearching(searchTerm)) {
      const titleContainsText =
        element.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      const keywordContainsText =
        (element.type === 'action' ||
          element.type === 'eventsSelectionOption') &&
        element.keywords &&
        element.keywords.reduce((acc, curr) => {
          if (curr.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
            acc = true;
          }
          return acc;
        }, false);

      if (titleContainsText || keywordContainsText) {
        filteredOptions.push(element);
      }
    } else {
      filteredOptions.push(element);
    }
  }

  return filteredOptions;
};

function mergeGroups(
  groups: ListGroup | ListGroup[],
  listRef: MutableRefObject<ListGroup[]>
): void {
  if (Array.isArray(groups)) {
    listRef.current.push(...groups.filter(({ items }) => items.length > 0));
  } else if (groups.items.length > 0) {
    listRef.current.push(groups);
  }
}

function setFlatList(
  groups: ListGroup[],
  listRef: MutableRefObject<ListItem[]>
): void {
  groups.forEach((group) => listRef.current.push(...group.items));
}
