import { DateTime } from 'luxon';
import React from 'react';
import { MINUTES_IN_A_DAY } from './time';

export interface MouseCoordinates {
  x: number;
  y: number;
}

export interface TargetDimensions {
  width: number;
  height: number;
}

export function getCoordinates(
  mouseEvent: React.MouseEvent<HTMLDivElement>
): MouseCoordinates {
  return {
    x: mouseEvent.nativeEvent.offsetX,
    y: mouseEvent.nativeEvent.offsetY,
  };
}

export function coordinatesToTargetDate(props: {
  coordinates: MouseCoordinates;
  width: number;
  height: number;
  startDay: DateTime;
  endDay: DateTime;
  roundToNearestQuarterHour: boolean;
}): DateTime | null {
  const { coordinates, width, height, startDay, endDay } = props;

  if (
    coordinates.x < 0 ||
    coordinates.y < 0 ||
    coordinates.x >= width ||
    coordinates.y >= height
  ) {
    return null;
  }

  const x = coordinates.x / width;
  // 5px to offset the mouse click
  const y = (coordinates.y - 4) / height;
  const numberOfDaysOnGrid = Math.floor(endDay.diff(startDay).as('days') + 1);
  const days = Math.floor(numberOfDaysOnGrid * x);
  const minutes = Math.round(y * MINUTES_IN_A_DAY);

  return startDay
    .set({
      hour: 0,
      minute: 0,
      millisecond: 0,
    })
    .plus({
      days,
      minutes: props.roundToNearestQuarterHour
        ? roundToNearestQuarterHour(minutes)
        : minutes,
    });
}

export function getTargetDimensions(
  mouseEvent: React.MouseEvent<HTMLDivElement>
): TargetDimensions {
  return {
    width: (mouseEvent.nativeEvent.target as HTMLDivElement)?.clientWidth || 0,
    height:
      (mouseEvent.nativeEvent.target as HTMLDivElement)?.clientHeight || 0,
  };
}

export function getAbsoluteDistance(
  coordinatesA?: MouseCoordinates | null,
  coordinatesB?: MouseCoordinates | null
): number {
  if (!coordinatesA || !coordinatesB) return 0;
  return Math.sqrt(
    Math.pow(coordinatesA.x - coordinatesB.x, 2) +
      Math.pow(coordinatesA.y - coordinatesB.y, 2)
  );
}
export function cmdKeyIsDown(
  mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>
): boolean {
  return mouseEvent.nativeEvent.ctrlKey || mouseEvent.nativeEvent.metaKey;
}

export function roundToNearestQuarterHour(minutes: number): number {
  return Math.round(minutes / 15) * 15;
}

export function roundDateTimeToNearestQuarterHour(
  dateTime: DateTime
): DateTime {
  return dateTime.set({
    minute: roundToNearestQuarterHour(dateTime.minute),
  });
}
