import {
  NewEventRsvpEnum,
  NewEventAttendeeResponseStatusEnum,
} from '@graphql-types@';
import IconUser from 'components/Icons/IconUser';
import {
  ListItem,
  NavItemContactSearch,
  NavItemType,
} from 'components/QuickMenu/types';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useUpdateModal } from 'hooks/useModal';
import { uniqBy } from 'lodash';
import React from 'react';
import { IGridEvent } from 'types/events';
import useSetNavItems from '../useSetNavItems';

export const useInviteGuestAction = (fullEvents: IGridEvent[]): ListItem => {
  const { saveGridEvent } = useUpdateGridEvent();
  const { closeModal } = useUpdateModal();
  const { addNavItem } = useSetNavItems();

  const inviteGuestAction = () => {
    const navItem: NavItemContactSearch = {
      type: NavItemType.contactSearch,
      title: `Add a guest`,
      inputPlaceholder: 'Add a guest',
      hidden: true,
      getActionNameOverride: () => 'Add',
      callback: (contact) => {
        saveGridEvent({
          id: fullEvents[0].id,
          attendees: uniqBy(
            [
              ...fullEvents[0].attendees,
              {
                id: contact.id,
                email: contact.emailAddress,
                RSVP: NewEventRsvpEnum.Unknown,
                responseStatus: NewEventAttendeeResponseStatusEnum.NeedsAction,
                organizer: false,
                displayName: contact.displayName,
              },
            ],
            'email'
          ),
        });

        closeModal();
      },
    };

    addNavItem({
      type: navItem.type,
      fullObject: navItem,
    });
  };

  return {
    id: 'inviteGuest',
    title: `Add a guest`,
    type: 'eventsSelectionOption',
    keywords: ['add', 'invite', 'guest'],
    Icon: () => <IconUser className="h-5 w-5 p-0.5 text-gray-400" />,
    actionNameOverride: 'Add',
    actionOverride: inviteGuestAction,
  };
};
