import classNames from 'classnames';
import IconCheckboxMark from 'components/Icons/IconCheckboxMark';
import { ListItem } from 'components/QuickMenu/types';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useUpdateModal } from 'hooks/useModal';
import { some } from 'lodash';
import React, { useCallback } from 'react';
import { IGridEvent } from 'types/events';

export const useDoneEventActions = (fullEvents: IGridEvent[]): ListItem[] => {
  const { saveGridEvent } = useUpdateGridEvent();
  const { closeModal } = useUpdateModal();
  const listItems = [];

  // show `Mark as done` if at least one of the selected events is not done
  const selectionHasUndoneEvents = some(fullEvents, (event) => !event.doneAt);

  // show `Mark as undone` if at least one of the selected events is done
  const selectionHasDoneEvents = some(fullEvents, (event) => event.doneAt);

  const toggleEventDone = useCallback(
    (markDone: boolean) => {
      fullEvents.forEach((event) => {
        if (
          (markDone ? event.doneAt : !event.doneAt) ||
          !event.belongsToUserCalendar
        )
          return;

        saveGridEvent({
          id: event.id,
          doneAt: markDone ? new Date().toISOString() : undefined,
        });
      });

      closeModal();
    },
    [closeModal, fullEvents, saveGridEvent]
  );

  const markDone: ListItem = {
    id: 'markAsDone',
    title: `Mark as done`,
    type: 'eventsSelectionOption',
    Icon: () => (
      <div
        className={classNames(
          'relative mx-0.5 flex items-center justify-center rounded-md transition-colors',
          'shadow-checkbox-gray-400 dark:shadow-checkbox-gray-400',
          'focus-within:ring-2 focus-within:ring-opacity-50'
        )}
        style={{ height: 16, width: 16 }}
      >
        <IconCheckboxMark className="h-4 w-4 text-gray-400 dark:text-gray-400" />
      </div>
    ),
    actionNameOverride: 'Confirm',
    actionOverride: () => toggleEventDone(true),
  };

  const markUndone: ListItem = {
    id: 'markAsUndone',
    title: `Mark as undone`,
    type: 'eventsSelectionOption',
    Icon: () => (
      <div
        className={classNames(
          'relative mx-0.5 flex items-center justify-center rounded-md transition-colors',
          'shadow-checkbox-gray-400 dark:shadow-checkbox-gray-400',
          'focus-within:ring-2 focus-within:ring-opacity-50'
        )}
        style={{ height: 16, width: 16 }}
      />
    ),
    actionNameOverride: 'Confirm',
    actionOverride: () => toggleEventDone(false),
  };

  if (selectionHasUndoneEvents) {
    listItems.push(markDone);
  }

  if (selectionHasDoneEvents) {
    listItems.push(markUndone);
  }

  return listItems;
};
