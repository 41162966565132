import { atom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import React from 'react';

export interface GridSelectionFrameProps {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

export default function GridSelectionFrame() {
  const { x1, x2, y1, y2 } = useAtomValue(gridSelectionFrameAtom);
  const top = Math.min(y1, y2);
  const left = Math.min(x1, x2);

  const width = Math.abs(x1 - x2);
  const height = Math.abs(y1 - y2);

  if (width === 0 || height === 0) {
    return null;
  }

  return (
    <div
      className="pointer-events-none absolute z-10 rounded-md border border-blue-600/20 bg-blue-400/20"
      style={{
        top,
        left,
        width,
        height,
      }}
    />
  );
}

export const DEFAULT_SELECTION_FRAME_PROPS: GridSelectionFrameProps = {
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0,
};

export const gridSelectionFrameAtom = atom(DEFAULT_SELECTION_FRAME_PROPS);
