import { MutableRefObject, useCallback, useEffect } from 'react';
import useHotkey from './useHotkey';

interface Props {
  ref: MutableRefObject<HTMLElement | null>;
  active: boolean;
}

let shouldReleaseFocusTrap = false;

export function releaseFocusTrap() {
  shouldReleaseFocusTrap = true;
}

export function useFocusTrap({ ref, active }: Props): void {
  const trapElementFocus = useCallback(() => {
    const element = ref.current;
    if (!element) return;

    return setTimeout(() => {
      if (element.contains(document.activeElement) || shouldReleaseFocusTrap)
        return;

      element.focus();
    }, 50);
  }, [ref]);

  useEffect(() => {
    if (!active) return;

    const element = ref.current;
    if (!element) return;

    const trapTimeout = trapElementFocus();

    window.addEventListener('focus', trapElementFocus);
    element.addEventListener('blur', trapElementFocus);

    return () => {
      window.removeEventListener('focus', trapElementFocus);
      element.removeEventListener('blur', trapElementFocus);
      shouldReleaseFocusTrap = false;

      if (trapTimeout) {
        window.clearTimeout(trapTimeout);
      }
    };
  }, [active, ref, trapElementFocus]);

  useHotkey('tab', 'all', trapElementFocus);
}
