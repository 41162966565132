import React from 'react';
import { IconSVGProps } from 'types/icon';

const IconSushiRoll: React.FC<IconSVGProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-sushiroll"
    >
      <path
        d="M6.77539 10.0937C6.04297 10.0937 5.47461 10.6621 5.47461 11.4004C5.47461 12.1152 6.05469 12.7012 6.77539 12.7012C7.47852 12.7012 8.07617 12.1152 8.07617 11.4004C8.07617 10.6914 7.47852 10.0937 6.77539 10.0937ZM6.77539 5.70508C6.04297 5.70508 5.47461 6.27344 5.47461 7.00586C5.47461 7.7207 6.05469 8.30078 6.77539 8.30078C7.47852 8.30078 8.07617 7.7207 8.07617 7.00586C8.07617 6.29687 7.47852 5.70508 6.77539 5.70508ZM6.77539 1.30469C6.04297 1.30469 5.47461 1.87305 5.47461 2.60547C5.47461 3.32617 6.05469 3.91211 6.77539 3.91211C7.47852 3.91211 8.07617 3.32617 8.07617 2.60547C8.07617 1.90234 7.47852 1.30469 6.77539 1.30469Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconSushiRoll;
