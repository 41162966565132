import React from 'react';
import { EmojiData } from 'utils/emojis';
import cn from 'classnames';

type Props = {
  emojiOptions: EmojiData[];
  index: number;
  handleClick: (emojiSymbol: string) => void;
};

export default function EmojiPicker({
  emojiOptions,
  index,
  handleClick,
}: Props): React.ReactElement {
  return (
    <div className="min-w-max absolute z-100 p-2 bg-popover shadow-lg rounded-lg">
      {emojiOptions.length ? (
        emojiOptions.map((emoji, i) => (
          <button
            onMouseDown={(event) => {
              event.preventDefault(); // don't blur the current text field

              handleClick(emoji.symbol);
            }}
            className={cn(
              'flex items-center transition-colors duration-100 w-full p-1 rounded-md',
              {
                'bg-gray-100 dark:bg-gray-600': i === index,
              },
              'hover:bg-gray-50 dark:hover:bg-gray-500'
            )}
            key={emoji.shortcut}
          >
            <div className="mr-3 text-[14px]">{emoji.symbol}</div>
            <div className="text-xs text-gray-700 dark:text-gray-300">
              {emoji.shortcut}
            </div>
          </button>
        ))
      ) : (
        <div className="text-xs text-secondary">No results</div>
      )}
    </div>
  );
}
