import IconCalendar from 'components/Icons/IconCalendar';
import { useCalendarColor } from 'components/Panels/useAvailableCalendars';
import React from 'react';

export const CalendarIcon = ({ calendarId }: { calendarId: string }) => {
  const color = useCalendarColor(calendarId);
  return (
    <IconCalendar
      className={`h-5 w-5 p-0.5 text-${color}-400 dark:text-${color}-400 text-opacity-75`}
    />
  );
};
