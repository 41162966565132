import classNames from 'classnames';
import IconPlus from 'components/Icons/IconPlus';
import IconStar from 'components/Icons/IconStar';
import IconTrash from 'components/Icons/IconTrash';
import { BaseEmoji } from 'emoji-mart';
import { AnimatePresence } from 'framer-motion';
import {
  TimezonePreference,
  timezonePreferencesAtom,
  useRemoveTimezone,
} from 'hooks/useTimeZone';
import { atom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import Button from 'joy/Button';
import ContextMenu, {
  ContextMenuType,
  useUpdateContextMenu,
} from 'joy/ContextMenu';
import { DropdownItemOption } from 'joy/Dropdown';
import Tooltip from 'joy/Tooltip';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLayer } from 'react-laag';
import { getTimezoneHoursDiff } from 'utils/time';
import GridTimezoneChangeDialog from './GridTimezoneChangeDialog';

export const isTimezoneModalOpenAtom = atom(false);

interface Props {
  timezonePreference: TimezonePreference | null;
  isRemoveable: boolean;
}

export default function GridTimezoneChanger({
  timezonePreference,
  isRemoveable,
}: Props): JSX.Element {
  const updateTimezonePreferences = useUpdateAtom(timezonePreferencesAtom);
  const removeTimezone = useRemoveTimezone();
  const setIsTimezoneModalOpen = useUpdateAtom(isTimezoneModalOpenAtom);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTextTooLong, setIsTextTooLong] = useState(false);

  useLayoutEffect(() => {
    if (
      timezonePreference != null &&
      labelRef.current != null &&
      labelRef.current.getBoundingClientRect().width >= 46
    ) {
      setIsTextTooLong(true);
    }
  }, [timezonePreference]);

  useEffect(() => {
    setIsTimezoneModalOpen(isDialogOpen);
  }, [isDialogOpen, setIsTimezoneModalOpen]);

  const timezoneHoursDiff = timezonePreference?.timezone
    ? getTimezoneHoursDiff(undefined, timezonePreference.timezone)
    : 'N/A';

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const labelRef = useRef<HTMLSpanElement | null>(null);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen: isDialogOpen,
    onOutsideClick: () => setIsDialogOpen(false),
    onParentClose: () => setIsDialogOpen(false),
    placement: 'bottom-start',
    triggerOffset: 6,
  });

  let label = '+';

  if (timezonePreference) {
    label = timezonePreference?.nickname || '';
    const emoji = (timezonePreference.emoji as BaseEmoji | null)?.native;

    if (!emoji && !timezonePreference.nickname) {
      label =
        timezonePreference.city ||
        timezonePreference.timezone.split('/').pop() ||
        '';

      const maxNumChars = 7;

      if (label.length > maxNumChars) {
        label = label.slice(0, maxNumChars).trim();
      }
    }
  }

  const menuItems: DropdownItemOption[] = useMemo(() => {
    if (!timezonePreference) return [];
    const items: DropdownItemOption[] = [];

    if (isRemoveable) {
      items.push({
        type: 'option',
        value: 'Remove timezone',
        variant: 'red',
        onSelect: () => {
          removeTimezone(timezonePreference.id);
        },
        icon: <IconTrash className="h-5 w-5" />,
      });
    }

    if (timezonePreference.priority !== 'primary') {
      items.unshift({
        type: 'option',
        value: 'Make primary',
        onSelect: () => {
          updateTimezonePreferences({
            ...timezonePreference,
            priority: 'primary',
          });
        },
        icon: <IconStar className="h-5 w-5" />,
      });
    }

    return items;
  }, [
    isRemoveable,
    removeTimezone,
    timezonePreference,
    updateTimezonePreferences,
  ]);

  const { openContextMenu, closeContextMenu } = useUpdateContextMenu();

  const onContextMenuClose = useCallback(
    () => closeContextMenu(ContextMenuType.TimezoneTitle),
    [closeContextMenu]
  );

  const onContextMenuOpen = useCallback(
    () => openContextMenu(ContextMenuType.TimezoneTitle),
    [openContextMenu]
  );

  return (
    <>
      <ContextMenu
        className="flex"
        items={menuItems}
        placement="right-start"
        onClose={onContextMenuClose}
        {...triggerProps}
      >
        <Tooltip
          content={
            timezonePreference ? (
              <div className="flex text-xs font-medium leading-none">
                <span>
                  Edit {timezonePreference.city || timezonePreference.timezone}
                  &nbsp;
                </span>
                {timezoneHoursDiff !== 0 && (
                  <span className="text-gray-400">
                    ({timezoneHoursDiff > 0 ? '+' : ''}
                    {`${timezoneHoursDiff}h`})
                  </span>
                )}
              </div>
            ) : (
              'Add timezone'
            )
          }
        >
          <Button
            ref={buttonRef}
            className={classNames(
              'group flex items-center overflow-hidden rounded-md px-1 py-0.5 hover:bg-gray-150 dark:hover:bg-gray-750',
              {
                'w-5': timezonePreference == null,
                'w-[3.4em]': timezonePreference != null,
              }
            )}
            style={{
              height: 'initial',
              lineHeight: 'initial',
            }}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              buttonRef.current?.blur();
              setIsDialogOpen(!isDialogOpen);
            }}
            onContextMenu={onContextMenuOpen}
          >
            <small
              className={classNames(
                'mx-auto whitespace-nowrap text-center text-xs font-medium text-gray-400 group-hover:text-gray-600 dark:text-gray-500 dark:group-hover:text-gray-200',
                {
                  'fade-bg-right-timezone': isTextTooLong,
                }
              )}
              ref={labelRef}
            >
              {timezonePreference?.emoji && (
                <span className="mr-1">
                  {(timezonePreference.emoji as BaseEmoji).native}
                </span>
              )}
              <span>
                {timezonePreference ? label : <IconPlus className="h-2 w-2" />}
              </span>
            </small>
          </Button>
        </Tooltip>
      </ContextMenu>

      {renderLayer(
        <AnimatePresence>
          {isDialogOpen && (
            <GridTimezoneChangeDialog
              {...layerProps}
              timezonePreference={timezonePreference}
              isRemoveable={isRemoveable}
              onClose={() => setIsDialogOpen(false)}
            />
          )}
        </AnimatePresence>
      )}
    </>
  );
}
