import { NewEventRsvpEnum } from '@graphql-types@';
import classNames from 'classnames';
import React from 'react';
import { IGridEvent } from 'types/events';

interface Props {
  className?: string;
  status: IGridEvent['rsvp'];
}

export default function EventRsvpStatus({ className, status }: Props) {
  return (
    <span
      className={classNames(
        'w-2 h-2 rounded-full mr-1',
        className,
        getRSVPIconClasses(status)
      )}
    />
  );
}

function getRSVPIconClasses(rsvp: NewEventRsvpEnum) {
  switch (rsvp) {
    case NewEventRsvpEnum.Yes:
      return 'bg-green-500';
    case NewEventRsvpEnum.No:
      return 'bg-rose-500';
    case NewEventRsvpEnum.Unknown:
      return 'bg-gray-500 dark:bg-gray-300';
    case NewEventRsvpEnum.NotInvited:
      return 'bg-gray-500';
  }
}
