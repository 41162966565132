import {
  NewEventAttendeeResponseStatusEnum,
  NewEventRsvpEnum,
} from '@graphql-types@';
import { userAtom } from 'hooks/user/userAtoms';
import { Getter } from 'jotai';
import { Attendee } from 'types/events';

export function createOrganizerAttendeeAtomCallback(
  get: Getter
): Attendee | null {
  const user = get(userAtom);
  if (!user) return null;
  return {
    __typename: 'NewEventAttendee',
    avatar: user.photo,
    displayName: user.displayName,
    RSVP: NewEventRsvpEnum.Yes,
    id: user.email,
    email: user.email || '',
    organizer: true,
    responseStatus: NewEventAttendeeResponseStatusEnum.Accepted,
  };
}
