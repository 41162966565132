import React from 'react';
import { Getter, Setter } from 'jotai';
import { DateTime } from 'luxon';
import {
  coordinatesToTargetDate,
  getCoordinates,
  getTargetDimensions,
  MouseCoordinates,
} from 'utils/mouseEvents';
import { calendarEndDateAtom, calendarStartDateAtom } from 'hooks/useCalendar';
import { useAtomCallback } from 'jotai/utils';
import { eventsSelectionAtom } from 'hooks/useEventsSelection';
import { modalAtom } from 'hooks/useModal';
import { ModalType } from 'types/modal';
import { isDraftEvent } from 'components/Grid/utils';
import { gridEventsFamily } from 'hooks/events/eventAtoms';

export interface GridCoordinates extends MouseCoordinates {
  date: DateTime | null;
}

export function useMouseEventToGridCoordinates() {
  return useAtomCallback(mouseEventToGridCoordinatesAtomCallback);
}

export function mouseEventToGridCoordinatesAtomCallback(
  get: Getter,
  _set: Setter,
  {
    mouseEvent,
    roundToNearestQuarterHour,
  }: {
    mouseEvent: React.MouseEvent<HTMLDivElement>;
    roundToNearestQuarterHour: boolean;
  }
): GridCoordinates {
  return {
    ...getCoordinates(mouseEvent),
    date: mouseEventToTargetDateAtomCallback(get, _set, {
      mouseEvent,
      roundToNearestQuarterHour,
    }),
  };
}

export function mouseEventToTargetDateAtomCallback(
  get: Getter,
  _set: Setter,
  {
    mouseEvent,
    roundToNearestQuarterHour,
  }: {
    mouseEvent: React.MouseEvent<HTMLDivElement>;
    roundToNearestQuarterHour: boolean;
  }
): DateTime | null {
  const startDay = get(calendarStartDateAtom);
  const endDay = get(calendarEndDateAtom);
  const gridDimensions = getTargetDimensions(mouseEvent);
  const mouseCoordinates = getCoordinates(mouseEvent);

  return coordinatesToTargetDate({
    coordinates: mouseCoordinates,
    width: gridDimensions.width,
    height: gridDimensions.height,
    startDay,
    endDay,
    roundToNearestQuarterHour,
  });
}

export function isCreatingDraft(get: Getter): boolean {
  const selectedEventId = get(eventsSelectionAtom)[0];
  return (
    get(modalAtom) === ModalType.Event &&
    isDraftEvent(get(gridEventsFamily(selectedEventId)))
  );
}
