import IconToggle from 'components/Icons/IconToggle';
import IconArchive from 'components/Icons/IconArchive';
import { useUpdateCategory } from 'components/Todos/CategoryItem';
import { showArchivedListsAtom } from 'components/Todos/todosAtoms';
import { TodosQuery, TodosDocument } from 'graphql/queries/todos.graphql';
import { useUpdateModal } from 'hooks/useModal';
import { useAtomValue } from 'jotai/utils';
import React, { useMemo } from 'react';
import { useQuery } from 'urql';
import { HookProps, ListItem, ListItemAction, NavItemType } from '../types';
import { useChangeListColor } from './TodoActions/useChangeListColor';
import { useCreateNewList } from './TodoActions/useCreateNewList';
import { filterBySearch } from './useListGroups';
import { useLastNavItem } from './useSetNavItems';

export default function useTodoCategoryActions({
  enabled,
  eventIds,
  searchTerm,
}: HookProps): ListItem[] {
  const lastNavItem = useLastNavItem();
  const todoCategoryId =
    lastNavItem?.type === NavItemType.todoCategory
      ? lastNavItem.todoCategoryId
      : undefined;

  const showArchivedLists = useAtomValue(showArchivedListsAtom);
  const setCategoryValue = useUpdateCategory(todoCategoryId);

  // Somehow, this server query here is causing the app to crash. If we use todosAtom or optimisticTodosAtom,
  // it will work IF the sidebar has been opened previously (so that we aren't making the server request.)
  const [gqlResponse] = useQuery({
    query: TodosDocument,
    variables: { hideArchived: !showArchivedLists },
  });

  const optUpdate: TodosQuery | undefined = gqlResponse.data;

  const todoCategory = useMemo(
    () => optUpdate?.categories.find((x) => x.id === todoCategoryId),
    [optUpdate?.categories, todoCategoryId]
  );

  const { closeModal } = useUpdateModal();

  const changeListColor = useChangeListColor({ todoCategory });
  const createNewList = useCreateNewList({ todoCategory });

  const options: ListItemAction[] = [];

  if (todoCategoryId) {
    options.push(
      {
        id: 'toggleTodoCategory',
        title: 'Toggle list',
        keywords: ['toggle', 'list'],
        type: 'action',
        actionOverride: () => {
          if (todoCategory)
            setCategoryValue({ expanded: !todoCategory.expanded });

          closeModal();
        },
        Icon: () => (
          <IconToggle className="h-5 w-5 text-gray-400 dark:text-gray-400" />
        ),
      },
      changeListColor,
      {
        id: 'archiveList',
        title: `${showArchivedLists ? `Unarchive` : `Archive`} list`,
        keywords: ['archive', 'list'],
        type: 'action',
        actionOverride: () => {
          if (showArchivedLists) {
            setCategoryValue({ archivedAt: null });
          } else {
            setCategoryValue({ archivedAt: new Date().toISOString() });
          }

          closeModal();
        },
        Icon: () => <IconArchive className="h-5 w-5 p-0.5 text-gray-400" />,
      }
    );
  }

  options.push(
    createNewList
    // TODO: https://linear.app/amie/issue/AMIE-2075/web-quick-menu-add-todo
    // {
    //   id: 'createNewTodo',
    //   title: 'Create new todo (not implemented - UX required)',
    //   keywords: ['create', 'todo'],
    //   type: 'action',
    //   actionOverride: () => {
    //     console.warn('Not yet implemented - UX descisions required.');
    //     closeModal();
    //   },
    //   Icon,
    // },
  );

  if (!enabled || eventIds.length > 0) {
    return [];
  } else {
    return filterBySearch(options, searchTerm);
  }
}
