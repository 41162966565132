import React from 'react';
import { IconSVGProps } from 'types/icon';

const IconCall: React.FC<IconSVGProps> = ({
  height = 12,
  width = 12,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 12"
    >
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.625"
        d="M2.111 1A1.111 1.111 0 001 2.111v.556A8.334 8.334 0 009.333 11h.556A1.111 1.111 0 0011 9.889V8.067a.556.556 0 00-.38-.527l-2.496-.832a.556.556 0 00-.672.279L6.824 8.24a6.135 6.135 0 01-3.065-3.065l1.254-.627a.556.556 0 00.28-.672L4.46 1.379A.556.556 0 003.933 1H2.11z"
      ></path>
    </svg>
  );
};

export default IconCall;
