import { atom, useAtom } from 'jotai';
import {
  atomFamily,
  useAtomCallback,
  useAtomValue,
  useUpdateAtom,
} from 'jotai/utils';
import { useCallback } from 'react';
import { IGridEvent } from 'types/events';
import { gridEventsFamily } from './events/eventAtoms';
import { isGridEvent } from './events/helpers/eventsHelpers';

const EMPTY_STATE: string[] = [];
export const eventsSelectionAtom = atom<string[]>(EMPTY_STATE);

export function useEventsSelection() {
  return useAtomValue(eventsSelectionAtom);
}

export function useSetEventsSelection() {
  const setEventsSelection = useUpdateAtom(eventsSelectionAtom);

  const clearEventsSelection = useCallback(() => {
    setEventsSelection(() => EMPTY_STATE);
  }, [setEventsSelection]);

  const selectEvent = useCallback(
    (eventId: string, clearSelectedEvents = false) => {
      setEventsSelection((prevSelection) => {
        if (clearSelectedEvents) {
          return [eventId];
        }
        return [...prevSelection, eventId];
      });
    },
    [setEventsSelection]
  );

  const deselectEvent = useCallback(
    (eventId: string) => {
      setEventsSelection((prevSelection) =>
        prevSelection.filter((id) => id !== eventId)
      );
    },
    [setEventsSelection]
  );

  return {
    setEventsSelection,
    deselectEvent,
    selectEvent,
    clearEventsSelection,
  };
}

const isSelectedAtomFamily = atomFamily((eventId: string) =>
  atom((get) => !!get(eventsSelectionAtom).find((id) => id === eventId))
);

export function useIsEventSelected(eventId: string): boolean {
  const [isSelectEvent] = useAtom(isSelectedAtomFamily(eventId));
  return isSelectEvent;
}

export const fullSelectionEventsAtom = atom((get) => {
  return get(eventsSelectionAtom)
    .map((id) => get(gridEventsFamily(id)))
    .filter(isGridEvent);
});

export const fullEventsAtomFamily = atomFamily((ids: string[]) => {
  return atom((get) => {
    return ids.map((id) => get(gridEventsFamily(id))).filter(isGridEvent);
  });
});

export function useFullEventsAtom(ids: string[]): IGridEvent[] {
  const [fullEvents] = useAtom(fullEventsAtomFamily(ids));
  return fullEvents;
}

export function useFullEventsSelection(): IGridEvent[] {
  return useAtomValue(fullSelectionEventsAtom);
}

export function useGetFullEventsSelection(): () => Promise<IGridEvent[]> {
  return useAtomCallback((get) => get(fullSelectionEventsAtom));
}

export function useFullEvent(eventId: string): IGridEvent | null {
  return useAtomValue(gridEventsFamily(eventId));
}
