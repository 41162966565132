import { ColorFamilyEnum_Enum } from '@graphql-types@';
import classNames from 'classnames';
import { sidebarWidthAtom } from 'components/Panels/SidePanel';
import { showDoneTodosAtom } from 'hooks/preferences/preferencesAtoms';
import { useAccentColor } from 'hooks/usePreferences';
import { useAtomValue } from 'jotai/utils';
import React from 'react';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { CategoryItem } from './CategoryItem';
import { DragHandle } from './DragHandle';
import { TodoItem } from './TodoItem';
import {
  dragCategoryAtom,
  normalizedTodosAtom,
  showArchivedListsAtom,
} from './todosAtoms';
import { colorFamilyToColor } from './utils';

export function DragCategoryGhost() {
  const dragCategory = useAtomValue(dragCategoryAtom);
  const accentColor = useAccentColor();
  const colorFamily = dragCategory?.colorFamily
    ? colorFamilyToColor(dragCategory.colorFamily)
    : accentColor;

  const colorEnum = colorFamily.toUpperCase() as ColorFamilyEnum_Enum;

  const colorMap = EVENT_COLOR_MAP[colorFamily];
  const sidebarWidth = useAtomValue(sidebarWidthAtom);
  const todos = useAtomValue(normalizedTodosAtom);
  const showDoneTodos = useAtomValue(showDoneTodosAtom);
  const showArchive = useAtomValue(showArchivedListsAtom);

  const widthStyle = {
    top: '-14px',
    width: sidebarWidth * 0.95 + 'px',
    marginLeft: sidebarWidth * 0.8 + 12 + 'px',
  };

  if (!dragCategory) return null;

  return (
    <div
      style={widthStyle}
      className={classNames(
        'pointer-events-none relative flex rounded-md',
        colorMap.todoDragBackground,
        colorMap.todoText
      )}
    >
      {/* Better to just draw it again instead of messing up with category header logic */}
      <DragHandle
        className={classNames(
          'absolute left-0 top-0.5',
          colorMap.todoButton,
          colorMap.todoButtonText
        )}
      />
      <CategoryItem
        isExpanded={dragCategory.expanded}
        id={dragCategory.id}
        colorFamily={colorEnum}
        name={dragCategory.name}
      >
        {dragCategory.expanded &&
          dragCategory.todos
            .filter(
              (todo) => showArchive || showDoneTodos || !todos[todo.id].doneAt
            )
            .map((todo) => {
              const item = todos[todo.id];

              return (
                <TodoItem
                  className="ml-6"
                  key={item.id}
                  id={item.id}
                  colorFamily={colorEnum}
                  categoryId={item.categoryId}
                  label={item.name}
                  doneAt={item.doneAt}
                />
              );
            })}
      </CategoryItem>
    </div>
  );
}
