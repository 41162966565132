import { Getter, Setter } from 'jotai';
import { IGridEvent } from 'types/events';
import {
  interactionOnlyEventsFamily,
  optimisticEventsFamily,
  optimisticMutationTimestamps,
} from '../eventAtoms';

interface UpdateGridEventProps extends Partial<IGridEvent> {
  id: string;
}

/**
 * Small optimisation on top of updateGridEventAtomCallback to prevent unnecessary re-renders
 */
const FIVE_MINUTES_MS = 300_000;
export function updateGridEventForInteractionOnlyAtomCallback(
  get: Getter,
  set: Setter,
  props: Pick<UpdateGridEventProps, 'id' | 'startAt' | 'endAt'>
): void {
  const event = get(interactionOnlyEventsFamily(props.id));
  if (
    !props.startAt ||
    !props.endAt ||
    (Math.abs(
      (event?.startAt.toMillis() || 0) - (props.startAt?.toMillis() || 0)
    ) < FIVE_MINUTES_MS &&
      Math.abs(
        (event?.endAt.toMillis() || 0) - (props.endAt?.toMillis() || 0)
      ) < FIVE_MINUTES_MS)
  ) {
    return;
  }
  set(interactionOnlyEventsFamily(props.id), {
    startAt: props.startAt,
    endAt: props.endAt,
  });
}
/**
 * Small optimisation on top of updateGridEventAtomCallback to prevent unnecessary re-renders
 */
export function applyInteractionOnlyChangesAtomCallback(
  get: Getter,
  set: Setter,
  props: { id: string }
): void {
  const interactionOnlyUpdates = get(interactionOnlyEventsFamily(props.id));
  set(interactionOnlyEventsFamily(props.id), null);
  updateGridEventAtomCallback(get, set, {
    ...props,
    ...interactionOnlyUpdates,
  });
}

export function updateGridEventAtomCallback(
  get: Getter,
  set: Setter,
  props: UpdateGridEventProps
): void {
  const interactionOnlyUpdates = get(interactionOnlyEventsFamily(props.id));
  if (interactionOnlyUpdates) {
    set(interactionOnlyEventsFamily(props.id), null);
  }
  const mutationTimestamp = Date.now();
  set(optimisticMutationTimestamps(props.id), mutationTimestamp);
  set(optimisticEventsFamily(props.id), (prevValues) => ({
    ...prevValues,
    ...interactionOnlyUpdates,
    ...props,
  }));
}
