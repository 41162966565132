import { getFirstName } from 'utils/share';
import { CalendarSlotDate } from 'hooks/calendarLink/booking/atoms';
import { preferencesAtom } from 'hooks/preferences/preferencesAtoms';
import { userAtom } from 'hooks/user/userAtoms';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { getDefaultVideoLocation, NormalizedPreferences } from 'utils/format';
import { DateTime } from 'luxon';
import { uuid } from 'utils/toolbox';
import { selectedCalendarLinkIdAtom } from './useSelectedCalendarLink';
import { startOfWeek } from 'utils/time';
import {
  CalendarLinkType,
  calendarLinksFamily,
  calendarLinkIdsAtom,
  CalendarLink,
} from '../calendarLinkAtoms';
import { MEET_LINK_PLACEHOLDER_DRAFT } from 'utils/video';

export function useCreateDraftCalendarLink() {
  return useAtomCallback(
    useCallback((get, set, calendarLinkType: CalendarLinkType) => {
      const user = get(userAtom);
      const preferences = get(preferencesAtom);
      if (!user || !preferences) {
        return;
      }

      const location = getDefaultVideoLocation(preferences) || '';

      const draft = getDefaultCalendarLinkDraft({
        displayName: user.displayName,
        calendarId: user.email,
        preferences,
        calendarLinkType,
        location,
      });

      set(calendarLinksFamily(draft.id), draft);
      set(selectedCalendarLinkIdAtom, draft.id);
      set(calendarLinkIdsAtom, (ids) => [...ids, draft.id]);
    }, [])
  );
}

export function getDefaultCalendarLinkDraft({
  calendarId,
  displayName,
  preferences,
  calendarLinkType,
  location,
}: {
  calendarId: string;
  displayName: string;
  preferences: NormalizedPreferences;
  calendarLinkType: CalendarLinkType;
  location: string;
}): CalendarLink {
  return {
    id: uuid(),
    calendarId,
    type: calendarLinkType,
    bookedCount: 0,
    title: `Meet with ${getFirstName(displayName)}`,
    alias: '',
    description: '',
    location,
    createdAt: new Date(),
    updatedAt: new Date(),
    bufferDurationMinutes: 0,
    eventMinDurationMinutes: 30,
    eventMaxDurationMinutes: 30,
    status: 'draft',
    slots:
      calendarLinkType === 'recurring'
        ? generateWorkingHoursSlots(preferences, 'draft')
        : [],
  };
}

export function isCalendarLinkDraft(calendarLink: CalendarLink): boolean {
  return calendarLink.status === 'draft';
}

function generateWorkingHoursSlots(
  preferences: NormalizedPreferences,
  calendarLinkId: string
): CalendarSlotDate[] {
  const { workHoursEnabled, workHoursEndTime, workHoursStartTime } =
    preferences;

  const fromDate = startOfWeek(
    DateTime.now().set({
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    preferences?.calendarStartsOn
  );

  let startAt = fromDate;
  let endAt = fromDate;

  if (workHoursEnabled && workHoursEndTime && workHoursStartTime) {
    startAt = startAt.set({
      hour: workHoursStartTime.hour,
      minute: workHoursStartTime.minute,
      second: 0,
      millisecond: 0,
    });
    endAt = endAt.set({
      hour: workHoursEndTime.hour,
      minute: workHoursEndTime.minute,
      second: 0,
      millisecond: 0,
    });
  } else {
    startAt = startAt.set({
      hour: 7,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    endAt = endAt.set({
      hour: 19,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }
  return [
    {
      id: uuid(),
      calendarLinkId,
      recurrenceRules: ['RRULE:FREQ=WEEKLY;BYDAY=MO'],
      startAt,
      endAt,
    },
    {
      id: uuid(),
      calendarLinkId,
      recurrenceRules: ['RRULE:FREQ=WEEKLY;BYDAY=TU'],
      startAt: startAt.plus({ days: 1 }),
      endAt: endAt.plus({ days: 1 }),
    },
    {
      id: uuid(),
      calendarLinkId,
      recurrenceRules: ['RRULE:FREQ=WEEKLY;BYDAY=WE'],
      startAt: startAt.plus({ days: 2 }),
      endAt: endAt.plus({ days: 2 }),
    },
    {
      id: uuid(),
      calendarLinkId,
      recurrenceRules: ['RRULE:FREQ=WEEKLY;BYDAY=TH'],
      startAt: startAt.plus({ days: 3 }),
      endAt: endAt.plus({ days: 3 }),
    },
    {
      id: uuid(),
      calendarLinkId,
      recurrenceRules: ['RRULE:FREQ=WEEKLY;BYDAY=FR'],
      startAt: startAt.plus({ days: 4 }),
      endAt: endAt.plus({ days: 4 }),
    },
  ];
}
