import Fuse from 'fuse.js';
import { useMountEffect } from 'joy/utils';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';
import TContact from 'types/contact';
import { useCalendars } from './useCalendars';
import { useContactsValue, useSyncGoogleContacts } from './useContacts';

interface UseContactSearchParams {
  term: string;
  limit: number;
  exclude?: string[];
  include?: string[];
}

interface UseContactSearchReturn {
  results: TContact[];
}

const OPTS = {
  keys: ['emailAddress', 'labels', 'displayName'],
  shouldSort: true,
  includeScore: true,
};
const MAX_FUZZY_SEARCH_RESULT = 100;

// use 2 buckets, top results, and the rest

/* the fuzzy search will return a matching score between 0.0 and 1.0
  with 0.0 being a perfect match and 1.0 being a poor match, this will
  return the bucket index based on the score, this assumes that
  we want to split the result between 2 buckets:
  0.00 -> 0.09999 = 0
  0.10 -> 1 = 1
*/
const bucketIndexFromScore = (score: number): number => {
  if (score > 1 || score < 0) {
    throw new Error('score must be below 1 and equal to or above 0');
  }

  if (score < 0.1) {
    return 0;
  }

  return 1;
};

const useContactSearch = ({
  term,
  limit,
  exclude,
  include,
}: UseContactSearchParams): UseContactSearchReturn => {
  const userCalendar = useCalendars();
  const contacts = useContactsValue();
  const [results, setResults] = useState<TContact[]>([]);
  const syncGoogleContacts = useSyncGoogleContacts();
  useMountEffect(() => void syncGoogleContacts({ isFullSync: true }));

  useEffect(() => {
    if (term.length === 0) {
      return;
    }

    // Manually merge calendar email addresses from org, and contacts.
    const merged = uniqBy(
      [
        ...contacts,
        ...userCalendar
          .map((cal) => ({
            // Adding displayName even though calendars don't have it, to make type match with contacts
            displayName: '',
            emailAddress: cal.id,
            id: cal.id,
            labels: [],
            lastInteractedAt: '',
          }))
          .filter((cal) => !cal.emailAddress.includes('group.calendar')),
      ],
      'emailAddress'
    );

    // Remove emails that are passed in the exclude list.

    let list = merged;
    if (exclude) {
      list = merged.filter((contact) => {
        return !exclude.some((email) => contact.emailAddress === email);
      });
    } else if (include) {
      list = merged.filter((contact) => {
        return include.some((email) => contact.emailAddress === email);
      });
    }

    const fuse = new Fuse(list, OPTS);
    let searchResults = fuse
      .search(term)
      .slice(0, MAX_FUZZY_SEARCH_RESULT)
      .reduce<TContact[][]>((buckets, fuseResult) => {
        // this will group the fuse search results by buckets depending on their score
        const bucketIndex = bucketIndexFromScore(fuseResult.score || 1);
        buckets[bucketIndex] = [...buckets[bucketIndex], fuseResult.item];
        return buckets;
      }, new Array(2).fill([]))
      .reduce<TContact[]>((contacts, bucket) => [...contacts, ...bucket], []); // and the merge all the buckets together
    const exactStartMatch = searchResults.find((result) =>
      result.emailAddress.startsWith(term)
    );
    /**
     * Exact start match should come absolutely first. Fuse does not
     * guarantee this with their scoring, but it is an optimization
     * that we would like.
     */
    if (exactStartMatch) {
      searchResults = [
        exactStartMatch,
        ...searchResults.filter((r) => r.id !== exactStartMatch.id),
      ];
    }

    return setResults(searchResults.slice(0, limit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  return { results };
};

export default useContactSearch;
