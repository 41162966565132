import { atom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { getCursorImage } from 'utils/cursors';

export const cursorAtom = atom<string | undefined>(undefined);
export const cursorStyleAtom = atom<string | undefined>((get) => {
  const value = get(cursorAtom);
  return value ? getCursorImage(value) : undefined;
});

export function useCursorStyle(): string | undefined {
  return useAtomValue(cursorStyleAtom);
}
