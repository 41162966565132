import { getRecurrenceRuleChoices } from 'components/EventPopover/EventRecurrence';
import IconRecurrence from 'components/Icons/IconRecurrence';
import {
  ListItem,
  NavItemNestedActions,
  NavItemType,
} from 'components/QuickMenu/types';
import { useFetchVisibleWeekEvents } from 'hooks/events/useGridEvents';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useUpdateModal } from 'hooks/useModal';
import { flatMap } from 'lodash';
import React from 'react';
import { IGridEvent } from 'types/events';
import useSetNavItems from '../useSetNavItems';

export const useChangeRecurrenceAction = (
  fullEvents: IGridEvent[]
): ListItem => {
  const { updateGridEvent } = useUpdateGridEvent();
  const { closeModal } = useUpdateModal();
  const { addNavItem } = useSetNavItems();
  const refreshVisibleCalendarEvents = useFetchVisibleWeekEvents();

  const changeEventRecurrenceAction = () => {
    const navItem: NavItemNestedActions = {
      type: NavItemType.nestedActions,
      title: `Recurrence`,
      inputPlaceholder: 'Change recurrence',
      hidden: true,
      // Use lodash's flatMap instead of .filter.map chain in order to make TypeScript happy.
      actions: flatMap(getRecurrenceRuleChoices(fullEvents[0]), (choice) =>
        choice.type === 'title'
          ? []
          : {
              id: `set-recurrence-${choice.value}`,
              title: choice.value,
              keywords: choice.value.split(' '),
              type: 'action',
              actionOverride: () => {
                updateGridEvent({
                  id: fullEvents[0].id,
                  recurrenceRules: [choice.rule.toString()],
                }).then(() =>
                  refreshVisibleCalendarEvents(fullEvents[0].calendarId || '')
                );
                closeModal();
              },
              Icon: () => (
                <IconRecurrence className="h-5 w-5 p-0.5 text-gray-400" />
              ),
            }
      ),
    };

    addNavItem({
      type: navItem.type,
      fullObject: navItem,
    });
  };

  return {
    id: 'makeRecurring',
    title: `Change recurrence`,
    type: 'eventsSelectionOption',
    Icon: () => <IconRecurrence className="h-5 w-5 p-0.5 text-gray-400" />,
    actionNameOverride: 'Change',
    actionOverride: changeEventRecurrenceAction,
  };
};
