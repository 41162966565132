import { atom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import React, { useCallback } from 'react';
import Dropdown, { DropdownProps } from './Dropdown';

const ContextMenuComponent = (
  { items, onClose, children, ...props }: DropdownProps,
  ref: React.Ref<HTMLDivElement>
): JSX.Element => (
  <Dropdown
    items={items}
    onClose={onClose}
    enableRightClick={true}
    ref={ref}
    {...props}
  >
    {children}
  </Dropdown>
);

const ContextMenu = React.memo(React.forwardRef(ContextMenuComponent));
export default ContextMenu;

// context menu hooks
export enum ContextMenuType {
  Contact,
  TimezoneTitle,
  Event,
  Location,
}
export const contextMenuAtom = atom<ContextMenuType | undefined>(undefined);

export function useUpdateContextMenu() {
  const setContextMenu = useUpdateAtom(contextMenuAtom);

  const openContextMenu = useCallback(
    (contextMenuType: ContextMenuType) => setContextMenu(contextMenuType),
    [setContextMenu]
  );

  const closeContextMenu = useCallback(
    (contextMenuType: ContextMenuType) => {
      setContextMenu((currentContextMenuType) => {
        if (
          contextMenuType !== undefined &&
          contextMenuType !== currentContextMenuType
        ) {
          return currentContextMenuType;
        }

        return undefined;
      });
    },
    [setContextMenu]
  );
  return {
    openContextMenu,
    closeContextMenu,
  };
}

export function useContextMenu() {
  return useAtomValue(contextMenuAtom);
}
