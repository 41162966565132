import React, { useMemo, useRef } from 'react';
import { HookProps, ListGroup, NavItemType } from '../../types';
import * as chrono from 'chrono-node';
import { DateTime } from 'luxon';
import { useLastNavItem } from '../useSetNavItems';
import { isSameDay, isToday, roundToNext15Minutes } from 'utils/time';
import useTimezone from 'hooks/useTimeZone';
import {
  useNextWeekEvents,
  useVisibleEvents,
} from 'hooks/events/useGridEvents';
import { usePreferences } from 'hooks/usePreferences';
import { IGridEvent } from 'types/events';
import { orderBy } from 'lodash';
import IconCalendar from 'components/Icons/IconCalendar';

export interface ChronoGroupResult {
  start: DateTime;
  end?: DateTime | undefined;
  title?: string;
  text?: string;
  calendarDatesOnly?: boolean;
  timeRangeOnly?: boolean;
}

const defaultListGroupTitle = 'Set a date';
const defaultWorkHoursStart = 9;

export default function useChronoGroup({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const timezone = useTimezone();
  const preferences = usePreferences();
  const lastNavItem = useLastNavItem();
  const openSlotSuggestions = useOpenSlotSuggestions({
    eventDurationMinutes:
      lastNavItem?.type === NavItemType.chronoActions
        ? lastNavItem.eventDurationMinutes
        : 30,
  });
  const suggestedTimes = useMemo(
    () => getCalendarDateSuggestions(timezone),
    [timezone]
  );
  const shouldRender =
    enabled && lastNavItem?.type === NavItemType.chronoActions;

  const listGroupRef = useRef<ListGroup>({
    title: lastNavItem?.title || defaultListGroupTitle,
    items: [],
  });

  listGroupRef.current.title = lastNavItem?.title || defaultListGroupTitle;

  const results: ChronoGroupResult[] = useMemo(() => {
    if (!shouldRender) return [];

    return [
      ...parseSearchResultAsChronoResult(
        searchTerm,
        timezone,
        preferences,
        lastNavItem?.calendarDatesOnly
      ),
    ];
  }, [shouldRender, searchTerm, timezone, preferences, lastNavItem]);

  listGroupRef.current.items.length = 0;

  const resultOptions = useMemo(() => {
    const mergedResults = [...results];

    if (
      lastNavItem?.type === NavItemType.chronoActions &&
      lastNavItem?.suggestOptions !== false
    ) {
      if (lastNavItem?.calendarDatesOnly) {
        mergedResults.push(...suggestedTimes);
      } else {
        mergedResults.push(...openSlotSuggestions);
      }
    }

    return mergedResults;
  }, [lastNavItem, openSlotSuggestions, results, suggestedTimes]);

  listGroupRef.current.items = useMemo(() => {
    if (!enabled || lastNavItem?.type !== NavItemType.chronoActions) return [];

    return resultOptions.map((result, i) => {
      const formattedSlot = lastNavItem.getFormattedSlot
        ? lastNavItem.getFormattedSlot(result, preferences)
        : getFormattedSlot(result, preferences);

      return {
        id: `chronoResult${i}`,
        title: result.title || formattedSlot,

        // Only show the slot as the subtitle if it's not already the title.
        time: result.title ? formattedSlot : undefined,

        type: 'eventsSelectionOption',
        Icon: () => <IconCalendar className="h-5 w-5 p-0.5 text-gray-400" />,
        actionNameOverride: lastNavItem.getActionNameOverride
          ? lastNavItem.getActionNameOverride(searchTerm)
          : 'Set',
        actionOverride: () => {
          lastNavItem.callback(result);
        },
      };
    });
  }, [enabled, lastNavItem, preferences, resultOptions, searchTerm]);

  return listGroupRef.current;
}

const parseSearchResultAsChronoResult = (
  searchTerm: string | undefined,
  timezone: string,
  preferences: ReturnType<typeof usePreferences>,
  calendarDatesOnly = false
): ChronoGroupResult[] => {
  if (!searchTerm) {
    return [];
  }

  // First attempt to parse it according to your date preferences.
  const dateParseResult = DateTime.fromFormat(
    searchTerm,
    preferences.dateFormat
  );

  const chronoResult = dateParseResult.isValid
    ? [
        {
          start: dateParseResult,
          end: null,
          text: searchTerm,
        },
      ]
    : chrono.parse(searchTerm);

  if (chronoResult == null) {
    return [];
  }

  return chronoResult.map((x) => {
    const start = DateTime.isDateTime(x.start)
      ? x.start
      : DateTime.fromJSDate(x.start.date(), {
          zone: timezone,
        }).set({
          second: 0,
          millisecond: 0,
        });

    const end =
      x.end == null
        ? undefined
        : DateTime.fromJSDate(x.end.date(), {
            zone: timezone,
          }).set({
            second: 0,
            millisecond: 0,
          });

    return {
      start,
      end,
      text: x.text,
      calendarDatesOnly,
    };
  });
};

export function getFirstAvailableSlot(
  preferences: ReturnType<typeof usePreferences>,
  events: IGridEvent[],
  zone: string,
  eventDurationMinutes = 30
): DateTime | null {
  let sortedEvents = orderBy(events, 'startAt', 'asc');

  let workHoursStart =
    preferences.workHoursStartTime &&
    DateTime.isDateTime(preferences.workHoursStartTime)
      ? preferences.workHoursStartTime.setZone(zone, {
          keepLocalTime: true,
        })
      : null;

  let workHoursEnd =
    preferences.workHoursEndTime &&
    DateTime.isDateTime(preferences.workHoursEndTime)
      ? preferences.workHoursEndTime.setZone(zone, {
          keepLocalTime: true,
        })
      : null;

  let slotStart =
    preferences.workHoursEnabled && workHoursStart
      ? workHoursStart
      : DateTime.fromObject({ hour: defaultWorkHoursStart, minute: 0 }).setZone(
          zone,
          {
            keepLocalTime: true,
          }
        );

  if (events.length > 0) {
    const targetDayUnits = {
      day: sortedEvents[0].startAt.day,
      month: sortedEvents[0].startAt.month,
      year: sortedEvents[0].startAt.year,
    };

    if (workHoursStart) workHoursStart = workHoursStart.set(targetDayUnits);
    if (workHoursEnd) workHoursEnd = workHoursEnd.set(targetDayUnits);
    slotStart = slotStart.set(targetDayUnits);
  }

  if (isToday(slotStart) && slotStart < DateTime.now()) {
    slotStart = roundToNext15Minutes(DateTime.now().setZone(zone));
  }

  let slotEnd = slotStart.plus({ minutes: eventDurationMinutes });

  sortedEvents = sortedEvents.filter((event) => event.endAt >= slotStart);

  for (let i = 0; i < sortedEvents.length; i++) {
    if (sortedEvents[i].startAt >= slotEnd) {
      // If this event starts after the slot, then we can use that slot.
      break;
    } else {
      // Otherwise, the next possible slot is when this event ends.
      slotStart = sortedEvents[i].endAt;
      slotEnd = slotStart.plus({ minutes: eventDurationMinutes });

      // If work hours are enabled and the next possible slot is after work hours end,
      // then there is no available slot today.
      if (
        preferences.workHoursEnabled &&
        workHoursEnd &&
        workHoursEnd < slotStart
      ) {
        return null;
      }
    }
  }

  return slotStart;
}

const getCalendarDateSuggestions = (timezone?: string) => {
  return [
    {
      title: 'Today',
      calendarDatesOnly: true,
      start: DateTime.now().setZone(timezone),
    },
    {
      title: 'Next Monday',
      calendarDatesOnly: true,
      start: DateTime.now()
        .setZone(timezone)
        .plus({ weeks: 1 })
        .set({ weekday: 1 }),
    },
    {
      title: 'Next Month',
      calendarDatesOnly: true,
      start: DateTime.now()
        .setZone(timezone)
        .plus({ months: 1 })
        .startOf('month'),
    },
    {
      title: 'Next Quarter',
      calendarDatesOnly: true,
      start: DateTime.now()
        .setZone(timezone)
        .plus({ quarters: 1 })
        .startOf('quarter'),
    },
  ];
};

const useOpenSlotSuggestions = ({
  eventDurationMinutes = 30,
}: {
  eventDurationMinutes?: number;
}) => {
  const timezone = useTimezone();
  const preferences = usePreferences();

  let eventsThisWeek = useVisibleEvents();
  eventsThisWeek = useMemo(
    () => eventsThisWeek.filter((event) => !event.isAllDay),
    [eventsThisWeek]
  );

  let eventsNextWeek = useNextWeekEvents().filter((event) => !event.isAllDay);
  eventsNextWeek = useMemo(
    () => eventsNextWeek.filter((event) => !event.isAllDay),
    [eventsNextWeek]
  );

  const results: ChronoGroupResult[] = [];

  // Add any hardcoded options you want here
  // (for example if tonight should be 11:11 instead of the first available slow)
  // or New Year's Eve, or Valentine's Day or something.
  // results.push({ DateTime.parse('Feb. 14th'), end, 'Valetine\'s Day' });
  const firstSlotToday = useMemo(
    () =>
      getFirstAvailableSlot(
        preferences,
        eventsThisWeek.filter((x) => isToday(x.startAt)),
        timezone,
        eventDurationMinutes
      ),
    [preferences, eventsThisWeek, timezone, eventDurationMinutes]
  );

  const firstSlotTomorrow = useMemo(
    () =>
      getFirstAvailableSlot(
        preferences,
        eventsThisWeek.filter((x) =>
          isSameDay(
            x.startAt,
            DateTime.now().setZone(timezone).plus({ days: 1 })
          )
        ),
        timezone,
        eventDurationMinutes
      ),
    [preferences, eventsThisWeek, timezone, eventDurationMinutes]
  );

  const firstSlotFriday = useMemo(
    () =>
      getFirstAvailableSlot(
        preferences,
        eventsThisWeek.filter((x) =>
          isSameDay(
            x.startAt,
            DateTime.now().setZone(timezone).set({ weekday: 5 })
          )
        ),
        timezone
      ),
    [preferences, eventsThisWeek, timezone]
  );

  const firstSlotNextMonday = useMemo(
    () =>
      getFirstAvailableSlot(
        preferences,
        eventsNextWeek.filter((x) =>
          isSameDay(
            x.startAt,
            DateTime.now()
              .setZone(timezone)
              .plus({ week: 1 })
              .set({ weekday: 1 })
          )
        ),
        timezone
      ),
    [preferences, eventsNextWeek, timezone]
  );

  if (firstSlotToday) {
    results.push({
      title: 'Today',
      start: firstSlotToday,
      end: firstSlotToday.plus({ minutes: eventDurationMinutes }),
      timeRangeOnly: true,
    });
  }

  if (firstSlotTomorrow) {
    results.push({
      title: 'Tomorrow',
      start: firstSlotTomorrow,
      end: firstSlotTomorrow.plus({ minutes: eventDurationMinutes }),
      timeRangeOnly: true,
    });
  }

  // If today is before Thursday, then show first slot on Friday.
  if (DateTime.now().weekday < 4 && firstSlotFriday) {
    results.push({
      title: 'Friday',
      start: firstSlotFriday,
      end: firstSlotFriday.plus({ minutes: eventDurationMinutes }),
      timeRangeOnly: true,
    });
  }

  // If tomorrow isn't Monday, then show first slot next Monday.
  if (DateTime.now().weekday !== 7 && firstSlotNextMonday) {
    results.push({
      title: 'Next Monday',
      start: firstSlotNextMonday,
      end: firstSlotNextMonday.plus({ minutes: eventDurationMinutes }),
      timeRangeOnly: true,
    });
  }

  return results;
};

const getFormattedSlot = (
  result: ChronoGroupResult,
  preferences: ReturnType<typeof usePreferences>
) => {
  let formattedSlot;

  if (result.calendarDatesOnly) {
    formattedSlot = result.start.toFormat('dd MMM yyyy');
  } else if (result.timeRangeOnly) {
    const timeFormat = preferences.ui24HourClock ? 'HH:mm' : 'hh:mm a';

    formattedSlot = result.end
      ? `${result.start.toFormat(timeFormat)} - ${result.end.toFormat(
          timeFormat
        )}`
      : `${result.start.toFormat(timeFormat)}`;
  } else {
    let dateFormat;
    if (!isToday(result.start) || (result.end && !isToday(result.end))) {
      dateFormat = preferences.ui24HourClock ? 'DD T' : 'ff';
    } else {
      dateFormat = preferences.ui24HourClock ? 'T' : 't';
    }

    formattedSlot = result.end
      ? `From ${result.start.toFormat(dateFormat)} to ${result.end.toFormat(
          dateFormat
        )}`
      : `${result.start.toFormat(dateFormat)}`;
  }

  return formattedSlot;
};
