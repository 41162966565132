import React from 'react';
import { IconSVGProps } from 'types/icon';

const IconToggle: React.FC<IconSVGProps> = ({
  height = 16,
  width = 16,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10.958 13.053c-.463.596-1.453.596-1.916 0L5.21 8.13c-.536-.688.017-1.629.958-1.629h7.664c.94 0 1.494.94.958 1.63l-3.832 4.923z" />
    </svg>
  );
};

export default IconToggle;
