import { CalendarSlotDate } from 'hooks/calendarLink/booking/atoms';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { selectedCalendarLinkAtom } from './useSelectedCalendarLink';
import { v4 as uuid } from 'uuid';
import { DateTime } from 'luxon';
import RRule from 'rrule';
import { dateToRRuleWeekday } from 'utils/share';
import { Getter, Setter } from 'jotai';
import { calendarLinksFamily } from '../calendarLinkAtoms';

export function useUpdateCalendarSlot() {
  const deleteCalendarSlot = useAtomCallback(
    useCallback((get, set, slotToDelete: CalendarSlotDate) => {
      const selectedCalendarLink = get(
        calendarLinksFamily(slotToDelete.calendarLinkId)
      );
      if (!selectedCalendarLink) {
        return;
      }
      set(calendarLinksFamily(selectedCalendarLink.id), {
        ...selectedCalendarLink,
        slots: selectedCalendarLink.slots.filter(
          (slot) => slot.id !== slotToDelete.id
        ),
      });
    }, [])
  );
  const updateCalendarSlot = useAtomCallback(
    useCallback((get, set, updatedSlot: CalendarSlotDate) => {
      const selectedCalendarLink = get(
        calendarLinksFamily(updatedSlot.calendarLinkId)
      );
      if (!selectedCalendarLink) {
        return;
      }
      set(calendarLinksFamily(selectedCalendarLink.id), {
        ...selectedCalendarLink,
        slots: selectedCalendarLink.slots.map((slot) =>
          slot.id === updatedSlot.id ? updatedSlot : slot
        ),
      });
    }, [])
  );
  const addCalendarSlot = useAtomCallback(addCalendarSlotAtomCallback);

  return { updateCalendarSlot, deleteCalendarSlot, addCalendarSlot };
}

export function addCalendarSlotAtomCallback(
  get: Getter,
  set: Setter,
  {
    startAt,
    id,
  }: {
    startAt: DateTime;
    id?: string;
  }
) {
  const selectedCalendarLink = get(selectedCalendarLinkAtom);
  if (!selectedCalendarLink) {
    return null;
  }

  // TODO: make sure the slot is spaced properly with other slots
  const newSlot: CalendarSlotDate = {
    id: id || uuid(),
    calendarLinkId: selectedCalendarLink.id,
    recurrenceRules:
      selectedCalendarLink.type === 'recurring'
        ? [
            new RRule({
              freq: RRule.WEEKLY,
              byweekday: [dateToRRuleWeekday(startAt)],
            }).toString(),
          ]
        : [],
    startAt,
    endAt: startAt.plus({
      minutes: selectedCalendarLink.eventMinDurationMinutes,
    }),
  };
  set(calendarLinksFamily(selectedCalendarLink.id), {
    ...selectedCalendarLink,
    slots: [...selectedCalendarLink.slots, newSlot],
  });
  return newSlot;
}

export function updateGridSlotAtomCallback(
  get: Getter,
  set: Setter,
  props: { id: string; startAt: DateTime; endAt: DateTime }
) {
  const selectedCalendarLink = get(selectedCalendarLinkAtom);
  if (!selectedCalendarLink) {
    return null;
  }
  set(calendarLinksFamily(selectedCalendarLink.id), {
    ...selectedCalendarLink,
    slots: selectedCalendarLink.slots.map((slot) =>
      slot.id === props.id
        ? { ...slot, startAt: props.startAt, endAt: props.endAt }
        : slot
    ),
  });
}
