import IconClose from 'components/Icons/IconClose';
import { useAtomValue } from 'jotai/utils';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import React, { useCallback } from 'react';
import { ModalType } from 'types/modal';
import useSetNavItems, { quickMenuNavItemsAtom } from './hooks/useSetNavItems';
import { NavItem } from './types';

export default function QuickMenuBreadcrumbs(): JSX.Element | null {
  const navItems = useAtomValue(quickMenuNavItemsAtom);
  return (
    <>
      {navItems.map((navItem, index) => {
        if (navItem.hidden) return;

        return <Breadcrumb key={index} index={index} navItem={navItem} />;
      })}
    </>
  );
}

type QuickMenuEventsBreadcrumbProps = {
  index: number;
  navItem: NavItem;
};

function Breadcrumb({ navItem }: QuickMenuEventsBreadcrumbProps): JSX.Element {
  const { resetNavItems } = useSetNavItems();

  const handleRemoveBreadcrumb = useCallback(() => {
    // TODO: for deeper navigation resetNavItems might be smarter in some cases
    // and use the breadcrumb's index to only remove it and those coming after it
    resetNavItems();

    if (navItem.remove) {
      navItem?.remove();
    }
  }, [resetNavItems, navItem]);

  return (
    <Tooltip
      disabled={!navItem.tooltipContent}
      customContainerId={`modal-${ModalType.QuickMenu}`}
      placement="top-center"
      content={navItem.tooltipContent ? navItem.tooltipContent : ''}
    >
      <div className="mr-3 flex shrink-0 cursor-default items-center justify-between rounded-md bg-gray-100 py-1 pl-2 pr-1.5 text-xs font-semibold dark:bg-gray-600 dark:text-gray-200">
        {navItem.title}
        <Button
          className="ml-1 opacity-60 transition-opacity hover:opacity-80"
          onClick={handleRemoveBreadcrumb}
        >
          <IconClose className="h-3 w-3" />
        </Button>
      </div>
    </Tooltip>
  );
}
