import { userEmailAtom } from 'hooks/auth/authAtoms';
import { useSetBirthdayWithReminderMutation } from 'graphql/mutations/UpdateContact.graphql';
import { useUpdateProfileByPkMutation } from 'graphql/mutations/UpdateProfileByPk.graphql';
import {
  ProfileBirthdayDataQueryVariables,
  useProfileBirthdayDataQuery,
} from 'graphql/queries/profile.graphql';
import { atom } from 'jotai';
import {
  atomFamily,
  useAtomCallback,
  useAtomValue,
  useUpdateAtom,
} from 'jotai/utils';
import { DateTime } from 'luxon';
import React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import type { UseQueryArgs } from 'urql';
import useProfile, { profileFamily } from './useProfile';

type QueryType = Omit<UseQueryArgs<ProfileBirthdayDataQueryVariables>, 'query'>;

interface Props {
  email: string;
}

const profileBirthdayFamily = atomFamily(() => atom<string | null>(null));
const contactBirthdayFamily = atomFamily(() => atom<string | null>(null));

export default function useBirthday(email: string) {
  const profile = useProfile(email);
  const profileId = profile?.id || '';
  const contactId = profile?.contactId || '';

  const profileBirthday = useAtomValue(profileBirthdayFamily(profileId));
  const contactBirthday = useAtomValue(contactBirthdayFamily(contactId));

  return {
    date: profileBirthday || contactBirthday,
    isSuggested: profileBirthday == null && contactBirthday != null,
  };
}

export function useBirthdayUpdate(email: string | null) {
  const [, updateProfileByPk] = useUpdateProfileByPkMutation();
  const [, updateContact] = useSetBirthdayWithReminderMutation();

  const updateBirthdayProfile = useAtomCallback(
    useCallback(
      async (get, set, birthday: string | null) => {
        const profile = get(profileFamily(email));
        const profileId = profile?.id;
        const contactId = profile?.contactId;

        if (!profileId || !contactId) return;

        set(profileBirthdayFamily(profileId), birthday);

        await updateProfileByPk({
          id: profileId,
          _set: { birthday },
        });
      },
      [email, updateProfileByPk]
    )
  );

  const updateBirthdayContact = useAtomCallback(
    useCallback(
      async (get, set, birthday: string | null) => {
        const profile = get(profileFamily(email));
        const profileId = profile?.id;
        const contactId = profile?.contactId;

        if (!profileId || !contactId) return;

        set(contactBirthdayFamily(contactId), birthday);

        await updateContact({
          contactId,
          birthday: birthday || undefined,
        });
      },
      [email, updateContact]
    )
  );

  const setDate = useAtomCallback(
    useCallback(
      (get, _, date: Date) => {
        // Check if the logged in user initiated the update
        // then update the profile date
        const userEmail = get(userEmailAtom);

        const birthday = date
          ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
          : null;

        if (userEmail === email) {
          updateBirthdayProfile(birthday);
        } else {
          // Otherwise update only the contact date
          // which results in a "suggested" birthday instead
          updateBirthdayContact(birthday);
        }
      },
      [email, updateBirthdayContact, updateBirthdayProfile]
    )
  );

  const removeBirthday = useAtomCallback(
    useCallback(
      (get) => {
        // Check if the logged in user initiated the deletion
        // then delete the profile date
        const userEmail = get(userEmailAtom);

        if (userEmail === email) {
          updateBirthdayProfile(null);
        } else {
          // Otherwise delete only the contact date
          updateBirthdayContact(null);
        }
      },
      [email, updateBirthdayContact, updateBirthdayProfile]
    )
  );

  return { setDate, removeBirthday };
}

export const SyncBirthday = React.memo(function SyncBirthdayComponent({
  email,
}: Props): null {
  const profile = useProfile(email);
  const profileId = profile?.id;
  const contactId = profile?.contactId;
  const updateProfileBirthday = useUpdateAtom(profileBirthdayFamily(profileId));
  const updateContactBirthday = useUpdateAtom(contactBirthdayFamily(contactId));

  const query = useMemo<QueryType>(
    () =>
      profileId && contactId
        ? {
            variables: { contactId, profileId },
            requestPolicy: 'cache-and-network',
          }
        : { pause: true },
    [profileId, contactId]
  );

  const [queryResults] = useProfileBirthdayDataQuery(query);

  useEffect(() => {
    if (!queryResults?.data?.contact || !queryResults.data.profile) return;

    updateProfileBirthday(queryResults.data.profile.birthday);
    updateContactBirthday(queryResults.data.contact.birthday);
  }, [
    queryResults.data?.contact,
    queryResults.data?.profile,
    updateContactBirthday,
    updateProfileBirthday,
  ]);

  return null;
});
