import {
  EventConfirmationCallback,
  eventConfirmationsAtom,
  confirmEventChanges,
} from 'hooks/useEventConfirmations';
import { saveServerEventsToCacheAtomCallback } from 'hooks/useFetchCurrentWeekFromCache';
import { Getter, Setter } from 'jotai';
import { deleteEventMutation } from '../api/eventsApi';
import {
  serverEventsAtomFamily,
  gridEventsFamily,
  optimisticEventsFamily,
} from '../eventAtoms';
import { optimisticDeleteRecurringInstances } from '../helpers/recurringEventsHelpers';
import { deleteDraftEventAtomCallback } from './draftEventAtomCallback';

interface DeleteGridEventProps {
  eventId: string;
  calendarId?: string;
}

export async function deleteEventAtomCallback(
  get: Getter,
  set: Setter,
  props: DeleteGridEventProps
): Promise<void> {
  const serverEvent = get(serverEventsAtomFamily(props.eventId));
  const eventToDelete = get(gridEventsFamily(props.eventId));
  if (eventToDelete?.isDraft) {
    return deleteDraftEventAtomCallback(get, set);
  }
  if (!serverEvent || !eventToDelete || !eventToDelete.belongsToUserCalendar) {
    return;
  }

  // only make optimistic changes when we know the event will be updated
  set(optimisticEventsFamily(props.eventId), (prevValues) => ({
    ...prevValues,
    status: 'cancelled',
  }));

  const setEventConfirmations = (updateValue: EventConfirmationCallback) =>
    set(eventConfirmationsAtom, updateValue);
  const { notifyGuests, applyToAllEvents, cancelled, notifyMessage } =
    await confirmEventChanges({
      event: eventToDelete,
      type: 'delete',
      setEventConfirmations,
    });
  if (cancelled) {
    set(optimisticEventsFamily(props.eventId), null);
    return;
  }
  set(serverEventsAtomFamily(props.eventId), (prevValues) =>
    prevValues
      ? {
          ...prevValues,
          status: 'cancelled',
        }
      : null
  );
  // Optimistically hide related event ids
  if (eventToDelete.recurringEventId && applyToAllEvents) {
    optimisticDeleteRecurringInstances(get, set, eventToDelete);
  }
  saveServerEventsToCacheAtomCallback(get);
  await deleteEventMutation({
    ...props,
    notifyGuests,
    notifyMessage,
    applyToAllEvents,
  });
}
