import { Getter, Setter } from 'jotai';
import { DateTime } from 'luxon';
import RRule, { Options } from 'rrule';
import { IGridEvent } from 'types/events';
import { dateToRRuleWeekday } from 'utils/share';
import { isSameDay } from 'utils/time';
import {
  eventIdsPoolAtom,
  gridEventsFamily,
  optimisticEventsFamily,
  serverEventsAtomFamily,
} from '../eventAtoms';
import { addBatchEvents, markEventAsCancelled } from './eventAtomsHelpers';
import {
  getEventDurationMinutes,
  isGridEvent,
  sortEventsASC,
} from './eventsHelpers';

export function optimisticDeleteRecurringInstances(
  get: Getter,
  set: Setter,
  eventToDelete: IGridEvent
): void {
  const eventIds = [...get(eventIdsPoolAtom)];
  eventIds.forEach((eventId) => {
    const event = get(gridEventsFamily(eventId));
    if (event?.recurringEventId === eventToDelete.recurringEventId) {
      markEventAsCancelled(set, eventId);
    }
  });
}

export function optimisticUpdateRecurringInstances(
  get: Getter,
  set: Setter,
  eventToUpdate: IGridEvent,
  updatedKeys: Array<keyof IGridEvent>
): void {
  // We're changing from non recurring event to recurring event.

  const shouldUpdateAllInstances =
    !updatedKeys.includes('isAllDay') &&
    !updatedKeys.includes('startAt') &&
    !updatedKeys.includes('recurrenceRules');
  const shouldRecreateInstances = !shouldUpdateAllInstances;

  if (shouldUpdateAllInstances) {
    updateAllInstances(get, set, eventToUpdate);
  } else if (shouldRecreateInstances) {
    recreateRecurringInstances(get, set, eventToUpdate, updatedKeys);
  }
}

export function generateEventInstances(
  recurringEvent: IGridEvent,
  numberOfDays = 15
): IGridEvent[] {
  const recurrenceRule = recurringEvent.recurrenceRules?.[0];
  if (!recurrenceRule) {
    return [{ ...recurringEvent, recurringEventId: null, recurrenceRules: [] }];
  }
  const rruleOptions = RRule.parseString(recurrenceRule);
  const eventDurationMinutes = getEventDurationMinutes(recurringEvent);
  rruleOptions.dtstart = recurringEvent.startAt.toJSDate();
  rruleOptions.until ??= recurringEvent.startAt
    .plus({ days: numberOfDays })
    .toJSDate();
  const newRRule = new RRule(rruleOptions);
  const generatedSlots = newRRule.all().map((date, index) => ({
    ...recurringEvent,
    id: generateInstanceId({
      recurringEventId: recurringEvent.id,
      startAt: DateTime.fromJSDate(date),
      isAllDay: recurringEvent.isAllDay,
    }),
    recurringEventId: recurringEvent.id,
    startAt: DateTime.fromJSDate(date),
    endAt: DateTime.fromJSDate(date).plus({ minutes: eventDurationMinutes }),
    doneAt: index === 0 ? recurringEvent.doneAt : undefined,
    status: 'confirmed',
  }));

  return generatedSlots;
}

function recreateRecurringInstances(
  get: Getter,
  set: Setter,
  eventToUpdate: IGridEvent,
  updatedKeys: Array<keyof IGridEvent>
): void {
  // We're changing from non recurring event to recurring event.
  const earliestInstance = getEarliestEventInstance(
    get,
    eventToUpdate.recurringEventId
  );

  removeEventInstancesFromPool(get, set, eventToUpdate.recurringEventId);

  if (
    updatedKeys.includes('recurrenceRules') &&
    eventToUpdate.recurrenceRules?.length
  ) {
    markEventAsCancelled(set, eventToUpdate.id);
  }
  const numberOfDaysToGenerate = Math.max(
    15,
    Math.round(
      eventToUpdate.startAt
        .diff(earliestInstance?.startAt || DateTime.now())
        .as('days')
    ) + 7
  );
  const eventInstances = generateEventInstances(
    {
      ...eventToUpdate,
      startAt:
        earliestInstance?.startAt.set({
          hour: eventToUpdate.startAt.hour,
          minute: eventToUpdate.startAt.minute,
        }) || eventToUpdate.startAt,
      endAt:
        earliestInstance?.endAt.set({
          hour: eventToUpdate.endAt.hour,
          minute: eventToUpdate.endAt.minute,
        }) || eventToUpdate.endAt,
      id: eventToUpdate.recurringEventId || eventToUpdate.id,
      status: 'confirmed',
    },
    numberOfDaysToGenerate
  );
  addBatchEvents(set, eventInstances, 'server');
}

function generateInstanceId({
  recurringEventId,
  startAt,
  isAllDay,
}: {
  recurringEventId: string;
  startAt: DateTime;
  isAllDay: boolean;
}): string {
  const [eventId, calendarId] = recurringEventId.split(':');
  return `${eventId}_${startAt
    .toUTC()
    .toFormat(isAllDay ? 'yyyyMMdd' : "yyyyMMdd'T'HHmmss'Z'")}:${calendarId}`;
}

export function removeEventInstancesFromPool(
  get: Getter,
  set: Setter,
  recurringEventCompoundId?: string | null
): void {
  if (!recurringEventCompoundId) {
    return;
  }

  const recurringEventId = recurringEventCompoundId.split(':')[0];
  const eventIds = [...get(eventIdsPoolAtom)];
  eventIds.forEach((eventId) => {
    if (eventId.includes(recurringEventId)) {
      // Cancel rather than remove to make those events are hidden even if a backend request sends them in the meantime
      markEventAsCancelled(set, eventId);
    }
  });
}

export function updateAllInstances(
  get: Getter,
  set: Setter,
  eventToUpdate: IGridEvent
) {
  const recurringEventId = eventToUpdate.recurringEventId?.split(':')[0];
  if (!recurringEventId) {
    return;
  }
  const eventIds = [...get(eventIdsPoolAtom)];
  eventIds.forEach((eventId) => {
    if (eventId.includes(recurringEventId)) {
      const prevEvent = get(gridEventsFamily(eventId));
      if (!prevEvent) {
        return;
      }
      const updatedEvent: IGridEvent = {
        ...prevEvent,
        title: eventToUpdate.title,
        description: eventToUpdate.description,
        location: eventToUpdate.location,
        videoConferences: eventToUpdate.videoConferences,
        isAllDay: eventToUpdate.isAllDay,
        attendees: eventToUpdate.attendees,
        colorFamily: eventToUpdate.colorFamily,
        startAt: prevEvent.startAt?.set({
          hour: eventToUpdate.startAt.hour,
          minute: eventToUpdate.startAt.minute,
        }),
        endAt: prevEvent.endAt?.set({
          hour: eventToUpdate.endAt.hour,
          minute: eventToUpdate.endAt.minute,
        }),
      };

      set(serverEventsAtomFamily(eventId), updatedEvent);
      set(optimisticEventsFamily(eventId), updatedEvent);
    }
  });
}

function getEarliestEventInstance(
  get: Getter,
  compoundRecurringEventId?: string | null
) {
  if (!compoundRecurringEventId) {
    return null;
  }
  const recurringEventId = compoundRecurringEventId?.split(':')[0];
  const eventIds = [...get(eventIdsPoolAtom)];
  const eventInstance = eventIds
    .filter((eventId) => eventId.includes(recurringEventId))
    .map((eventId) => get(gridEventsFamily(eventId)))
    .filter(isGridEvent);

  return sortEventsASC(eventInstance)[0] || null;
}

interface GetNewRecurrenceRulesProps {
  serverEvent: IGridEvent | null;
  updatedEvent: IGridEvent | null;
}

/**
 * For recurring events, we need to update the recurrence rules of the event
 * when we change the day.
 *
 * If we're not dealing with a recurring event or the day doesn't change,
 * we just return the original recurrence rules.
 */
export function syncRecurrenceRules({
  serverEvent,
  updatedEvent,
}: GetNewRecurrenceRulesProps): IGridEvent | null {
  if (
    !serverEvent ||
    !updatedEvent ||
    !updatedEvent.recurringEventId ||
    isSameDay(serverEvent.startAt, updatedEvent.startAt)
  ) {
    return updatedEvent;
  }
  const recurrenceRule = updatedEvent.recurrenceRules?.[0];
  const recurrenceOptions = recurrenceRule
    ? RRule.parseString(recurrenceRule)
    : null;

  if (recurrenceOptions && !repeatsMultipleTimesAWeek(recurrenceOptions)) {
    const updatedRecurrenceRule = changeRecurrenceRuleDay(
      recurrenceOptions,
      updatedEvent.startAt
    );
    return {
      ...updatedEvent,
      recurrenceRules: [new RRule(updatedRecurrenceRule).toString()],
    };
  }
  return updatedEvent;
}

export function repeatsMultipleTimesAWeek(
  recurrenceOptions: Partial<Options>
): boolean {
  return (
    Array.isArray(recurrenceOptions.byweekday) &&
    recurrenceOptions.byweekday?.length > 1
  );
}

function changeRecurrenceRuleDay(
  recurrenceOptions: Partial<Options>,
  dateTime: DateTime
): Partial<Options> {
  if (!recurrenceOptions.byweekday) {
    return recurrenceOptions;
  }
  recurrenceOptions.byweekday = [dateToRRuleWeekday(dateTime)];
  return recurrenceOptions;
}
