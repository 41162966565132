import classNames from 'classnames';
import { GridTodoGhost } from 'components/Grid/GridTodoGhost';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { TodoCategories } from './Categories';
import { DragCategoryGhost } from './DragCategoryGhost';
import { DragFromScheduleGhost } from './DragFromScheduleGhost';
import { DragTodoGhost } from './DragTodoGhost';
import TodoSectionHeader from './SectionHeader';
import { TodosDragOverlay } from './TodosDragOverlay';
import { UseTodosDnd } from './UseTodosDnd';

interface TodosProps {
  className?: string;
}

function TodosComponent(props: TodosProps) {
  return (
    <>
      <TodoSectionHeader />
      <UseTodosDnd />
      <TodosDragOverlay>
        <DragFromScheduleGhost />
        <DragCategoryGhost />
        <DragTodoGhost />
        <GridTodoGhost />
      </TodosDragOverlay>

      <div
        className={classNames(
          'mb-2 flex min-h-screen flex-col gap-1.5 pt-20',
          props.className
        )}
      >
        <AnimatePresence initial={false}>
          <TodoCategories />
        </AnimatePresence>
      </div>
    </>
  );
}

export const Todos = React.memo(TodosComponent);
