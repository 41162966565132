import React from 'react';
import { IconSVGProps } from 'types/icon';

const IconDrag: React.FC<IconSVGProps> = ({ className, ...rest }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="16"
      fill="none"
      viewBox="0 0 8 16"
      {...rest}
    >
      <circle
        cx="6.5"
        cy="3"
        r="1.4"
        fill="currentColor"
        transform="rotate(90 6.5 3)"
      />
      <circle
        cx="1.5"
        cy="3"
        r="1.4"
        fill="currentColor"
        transform="rotate(90 1.5 3)"
      />
      <circle
        cx="6.5"
        cy="8"
        r="1.4"
        fill="currentColor"
        transform="rotate(90 6.5 8)"
      />
      <circle
        cx="1.5"
        cy="8"
        r="1.4"
        fill="currentColor"
        transform="rotate(90 1.5 8)"
      />
      <circle
        cx="6.5"
        cy="13"
        r="1.4"
        fill="currentColor"
        transform="rotate(90 6.5 13)"
      />
      <circle
        cx="1.5"
        cy="13"
        r="1.4"
        fill="currentColor"
        transform="rotate(90 1.5 13)"
      />
    </svg>
  );
};

export default IconDrag;
