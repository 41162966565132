import { cursorAtom } from 'hooks/useCursor';
import { atom } from 'jotai';
import { useUpdateAtom, useAtomValue } from 'jotai/utils';
import { CalendarLink, calendarLinksFamily } from '../calendarLinkAtoms';

export const baseCalendarLinkIdAtom = atom<null | string>(null);
export const selectedCalendarLinkIdAtom = atom<null | string, null | string>(
  (get) => {
    return get(baseCalendarLinkIdAtom);
  },
  (_, set, value) => {
    set(cursorAtom, value ? 'cursor-slot-add' : undefined);
    set(baseCalendarLinkIdAtom, value);
  }
);

export function useSelectedCalendarLinkId() {
  return useAtomValue(selectedCalendarLinkIdAtom);
}

export function useSetSelectedCalendarLinkId() {
  return useUpdateAtom(selectedCalendarLinkIdAtom);
}

export const selectedCalendarLinkAtom = atom<null | CalendarLink>((get) => {
  const id = get(selectedCalendarLinkIdAtom);
  return get(calendarLinksFamily(id));
});

export function useSelectedCalendarLink() {
  return useAtomValue(selectedCalendarLinkAtom);
}
