import { ColorFamilyEnum_Enum } from '@graphql-types@';
import { useAtomValue } from 'jotai/utils';
import React from 'react';
import { ABOVE, BENEATH, BOTTOM, DRAFT, TOP } from './constants';
import { TodoItem } from './TodoItem';
import { SortableTodoItem } from './SortableTodoItem';
import { showArchivedListsAtom, todoDraftPositionAtom } from './todosAtoms';
import { useIsActiveCategoryId, useIsActiveTodoId, useTodo } from './utils';

export const Todo = ({
  index,
  prevId,
  nextId,
  isLast,
  id,
  colorFamily,
}: {
  index: number;
  id: string;
  prevId: string | null;
  nextId: string | null;
  isLast: boolean;
  colorFamily: ColorFamilyEnum_Enum | undefined;
}) => {
  const todoDraftPosition = useAtomValue(todoDraftPositionAtom);
  const showArchivedLists = useAtomValue(showArchivedListsAtom);

  // When we move item up we need to use the id of the item above the one we are over for after id
  const todo = useTodo(id);

  const isActiveTodoId = useIsActiveTodoId(id);
  const isActiveCategory = useIsActiveCategoryId(todo?.categoryId);

  // for inserting todo above an item
  const activeDraftTodoAbove =
    !showArchivedLists &&
    isActiveCategory &&
    ((isActiveTodoId && todoDraftPosition === ABOVE) ||
      (todoDraftPosition === TOP && index === 0));

  // for inserting todo beneath an item
  const activeDraftTodoBeneath =
    !showArchivedLists &&
    isActiveCategory &&
    ((isLast && todoDraftPosition === BOTTOM) ||
      (isActiveTodoId && todoDraftPosition === BENEATH));

  if (todo == null) return null;

  return (
    <React.Fragment key={id}>
      <>
        {activeDraftTodoAbove && (
          <TodoItem
            id={DRAFT}
            className="pl-6"
            colorFamily={colorFamily}
            categoryId={todo.categoryId}
            isLast={false}
            prevId={prevId}
            nextId={id}
          />
        )}
        <SortableTodoItem
          id={id}
          categoryId={todo.categoryId}
          colorFamily={colorFamily}
          index={index}
          doneAt={todo.doneAt}
          label={todo.name}
          isLast={isLast}
          prevId={prevId}
          nextId={nextId}
        />
      </>
      {/* show the draft placeholder even if the current todo is filtered with the hide done todos preference */}
      {activeDraftTodoBeneath && (
        <TodoItem
          index={index}
          id={DRAFT}
          className="pl-6"
          colorFamily={colorFamily}
          categoryId={todo?.categoryId}
          isLast={true}
          prevId={id}
          nextId={nextId}
        />
      )}
    </React.Fragment>
  );
};
