import { orderBy } from 'lodash';
import { NewEventRsvpEnum } from '@graphql-types@';
import { DateTime } from 'luxon';
import { IGridEvent } from 'types/events';
import { isToday } from 'utils/time';
import { ListItem, ListItemNavigation } from './types';

export function isSearching(searchTerm?: string): searchTerm is string {
  return searchTerm ? searchTerm.length > 0 : false;
}

export function getTodaysUnfinishedEvents(
  events: IGridEvent[],
  showDoneEvent = false
): IGridEvent[] {
  return events
    .sort((a, b) => (a.startAt < b.startAt ? -1 : 1))
    .filter(
      (event) =>
        isToday(event.startAt) &&
        event.endAt.plus({ minutes: showDoneEvent ? 5 : 0 }) >
          DateTime.now().setZone(event.endAt.zone) &&
        !event.isAllDay &&
        event.rsvp !== NewEventRsvpEnum.No
    );
}

export function getNextVisibleEvent(
  events: IGridEvent[],
  showDoneEvent = false
): IGridEvent | undefined {
  const todaysUnfinishedEvents = getTodaysUnfinishedEvents(
    events,
    showDoneEvent
  );
  if (todaysUnfinishedEvents.length === 0) return undefined;

  const thresholdToSwitchEvents = 5;

  // If there are multiple upcoming events, prioritize ones with more attendees.
  const upcomingEvents = orderBy(
    todaysUnfinishedEvents.filter(
      (event) =>
        event.startAt > DateTime.now().setZone(event.startAt.zone) &&
        event.startAt <
          DateTime.now()
            .setZone(event.startAt.zone)
            .plus({ minutes: thresholdToSwitchEvents })
    ),
    (event) => event.attendees.length,
    'desc'
  );

  const currentEvents = orderBy(
    todaysUnfinishedEvents.filter(
      (event) => event.startAt < DateTime.now().setZone(event.startAt.zone)
    ),
    (event) => event.attendees.length,
    'desc'
  );

  const upcomingEventsWithVideo = upcomingEvents.filter(
    (event) => event.videoConferences.length > 0
  );

  return (
    upcomingEventsWithVideo[0] ||
    upcomingEvents[0] ||
    currentEvents[currentEvents.length - 1] ||
    todaysUnfinishedEvents[0]
  );
}

export function getNavigationResults({
  searchTerm,
  options,
}: {
  searchTerm: string;
  options: ListItem[];
}): ListItem[] {
  const searchTermInput = searchTerm || '';
  // Detect partial matches, e.g. both what's new and what new.
  const regexPartialMatch = new RegExp(
    searchTermInput
      .replace(/(\S+)/g, (s) => {
        return '\\b' + s + '.*';
      })
      .replace(/\s+/g, ''),
    'gi'
  );
  const navigationResults = options.filter(
    (option: ListItem | ListItemNavigation): boolean =>
      option.type !== 'navigation' || regexPartialMatch.test(option.id)
  );

  return navigationResults;
}

export function getActionName(item: ListItem): string | undefined {
  if (item.actionNameOverride) return item.actionNameOverride;

  switch (item.type) {
    case 'event':
      return 'Options';
    case 'upcomingEvent':
      return item.actionOverride ? 'Join' : 'Options';
    case 'contact':
      return 'Open profile';
    case 'calendar':
      return 'Add calendar';
    case 'navigation':
      return 'Open';
    case 'timezone':
      return 'Set timezone';
    case 'action':
      return 'Select';
    default:
      return undefined;
  }
}

export function getSearchedEvents(
  events: IGridEvent[],
  searchTerm: string
): IGridEvent[] {
  const searchTerms = searchTerm
    .trim()
    .split(/\s+/)
    .map((n) => n.toLowerCase());

  const results: IGridEvent[] = [];

  events.forEach((event) => {
    const hasResult = searchTerms.every((query) =>
      event.title.toLowerCase().includes(query)
    );
    if (hasResult) results.push(event);
  });

  return results;
}
