import { NewEventVisibilityEnum } from '@graphql-types@';
import IconLockedClosed from 'components/Icons/IconLockClosed';
import IconLockedOpen from 'components/Icons/IconLockOpen';
import { ListItem } from 'components/QuickMenu/types';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useUpdateModal } from 'hooks/useModal';
import React, { useCallback, useMemo } from 'react';
import { IGridEvent } from 'types/events';

export const useChangePrivacyAction = (fullEvents: IGridEvent[]): ListItem => {
  const { saveGridEvent } = useUpdateGridEvent();
  const { closeModal } = useUpdateModal();
  const isEveryEventPrivate = useMemo(
    () =>
      !fullEvents.some((x) => x.visibility !== NewEventVisibilityEnum.Private),
    [fullEvents]
  );

  const togglePrivate = useCallback((): void => {
    fullEvents.forEach((event) => {
      saveGridEvent({
        id: event.id,
        visibility: isEveryEventPrivate
          ? NewEventVisibilityEnum.Default
          : NewEventVisibilityEnum.Private,
      });
    });

    closeModal();
  }, [closeModal, fullEvents, isEveryEventPrivate, saveGridEvent]);

  return {
    id: 'makePrivate',
    title: isEveryEventPrivate ? `Make public` : `Make private`,
    type: 'eventsSelectionOption',
    Icon: () =>
      isEveryEventPrivate ? (
        <IconLockedOpen className="h-5 w-5 p-0.5 text-gray-400" />
      ) : (
        <IconLockedClosed className="h-5 w-5 p-0.5 text-gray-400" />
      ),
    actionNameOverride: 'Toggle',
    actionOverride: togglePrivate,
  };
};
