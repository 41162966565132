import IconAppearance from './icons/IconAppearance';
import IconDownload from './icons/IconDownload';
import IconCalendar from './icons/IconCalendar';
import IconConnections from './icons/IconConnections';
import IconFeedback from './icons/IconFeedback';
import IconNotifications from './icons/IconNotifications';
import IconProfile from './icons/IconProfile';
import IconShortcuts from './icons/IconShortcuts';
import IconWhatsNew from './icons/IconWhatsNew';
import { SettingsPage, SettingsPageType } from './types';
import IconVideo from './icons/IconVideo';
import IconAccount from './icons/IconAccount';

export const settingsPages: Record<SettingsPageType, SettingsPage> = {
  [SettingsPageType.Profile]: {
    title: 'Profile',
    type: SettingsPageType.Profile,
    icon: IconProfile,
  },
  [SettingsPageType.Accounts]: {
    title: 'Accounts',
    type: SettingsPageType.Accounts,
    icon: IconAccount,
  },
  [SettingsPageType.Connections]: {
    title: 'Connections',
    type: SettingsPageType.Connections,
    icon: IconConnections,
  },
  [SettingsPageType.Calendar]: {
    title: 'Calendar',
    type: SettingsPageType.Calendar,
    icon: IconCalendar,
  },
  [SettingsPageType.Appearance]: {
    title: 'Appearance',
    type: SettingsPageType.Appearance,
    icon: IconAppearance,
  },
  [SettingsPageType.Meetings]: {
    title: 'Meetings',
    type: SettingsPageType.Meetings,
    icon: IconVideo,
  },
  [SettingsPageType.Notifications]: {
    title: 'Notifications',
    type: SettingsPageType.Notifications,
    icon: IconNotifications,
  },
  [SettingsPageType.Shortcuts]: {
    title: 'Shortcuts',
    type: SettingsPageType.Shortcuts,
    icon: IconShortcuts,
  },
  [SettingsPageType.Download]: {
    title: 'Download apps',
    type: SettingsPageType.Download,
    icon: IconDownload,
  },
  [SettingsPageType.WhatsNew]: {
    title: `What's new`,
    type: SettingsPageType.WhatsNew,
    icon: IconWhatsNew,
  },
  [SettingsPageType.Feedback]: {
    title: 'Send feedback',
    type: SettingsPageType.Feedback,
    icon: IconFeedback,
  },
};

export function getPreviousPage(
  pageType: SettingsPageType
): SettingsPageType | undefined {
  const keys = Object.keys(settingsPages) as SettingsPageType[];
  const currentIndex = keys.findIndex((type) => type === pageType);

  const previousIndex = currentIndex - 1;
  const previousPageType = keys[previousIndex];

  if (!previousPageType) return undefined;

  return previousPageType;
}

export function getNextPage(
  pageType: SettingsPageType
): SettingsPageType | undefined {
  const keys = Object.keys(settingsPages) as SettingsPageType[];
  const currentIndex = keys.findIndex((type) => type === pageType);

  const nextIndex = currentIndex + 1;
  const nextPageType = keys[nextIndex];

  if (!nextPageType) return undefined;

  return nextPageType;
}

export function capitalize(title: string): string {
  return title.charAt(0).toUpperCase() + title.slice(1);
}
