import { ColorFamilyEnum_Enum } from '@graphql-types@';
import { useAccentColor, usePreferences } from 'hooks/usePreferences';
import { useAtomValue } from 'jotai/utils';
import React from 'react';
import { CategoryItem, SortableCategoryItem } from './CategoryItem';
import { Todo } from './CategoryTodos';
import { BOTTOM, DRAFT, TOP } from './constants';
import { TodoAddButton } from './TodoAddButton';
import { TodoItem } from './TodoItem';
import {
  draftCategoryIdAtom,
  optimisticTodosAtom,
  showArchivedListsAtom,
  todoDraftPositionAtom,
} from './todosAtoms';
import { TodoCategoryT } from './types';
import {
  colorFamilyToColor,
  useCategoryTodosForDisplay,
  useIsActiveCategoryId,
} from './utils';

export const TodoCategories = (): JSX.Element => {
  const { categories } = useAtomValue(optimisticTodosAtom);
  const draftCategoryId = useAtomValue(draftCategoryIdAtom);
  const defaultEventColor = useAccentColor();

  return (
    <>
      {draftCategoryId && (
        <CategoryItem
          colorFamily={defaultEventColor.toLowerCase() as ColorFamilyEnum_Enum}
          id={DRAFT}
        />
      )}
      {categories.map((category) => (
        <CategoryItemWrapper
          key={`category-${category.id}`}
          category={category}
        />
      ))}
    </>
  );
};

const CategoryItemWrapper = ({ category }: { category: TodoCategoryT }) => {
  const preferences = usePreferences();

  const defaultColorFamily = preferences.defaultEventColor.toUpperCase() as
    | ColorFamilyEnum_Enum
    | undefined;

  const colorFamily = category.colorFamily || defaultColorFamily;

  const items = useCategoryTodosForDisplay(category.id);

  const isActiveCategory = useIsActiveCategoryId(category.id);
  const showArchivedLists = useAtomValue(showArchivedListsAtom);
  const todoDraftPosition = useAtomValue(todoDraftPositionAtom);
  // display + Todo button where a category doesn't have active items
  const showAddTodoButton =
    !showArchivedLists && (todoDraftPosition !== BOTTOM || !isActiveCategory);

  const activeDraftTodoTop =
    !showArchivedLists &&
    isActiveCategory &&
    items.length === 0 &&
    (todoDraftPosition === TOP || todoDraftPosition === BOTTOM);

  return (
    <SortableCategoryItem
      id={category.id}
      isExpanded={category.expanded}
      colorFamily={colorFamily}
      name={category.name}
    >
      {activeDraftTodoTop && (
        <TodoItem
          id={DRAFT}
          className="pl-6"
          colorFamily={colorFamily}
          categoryId={category.id}
          isLast={false}
          prevId={null}
          nextId={null}
        />
      )}

      {items.length > 0
        ? items.map((id, index) => {
            /* prev and next are used so that we don't need to
             * fetch all categories again and filter though for this one item
             */
            const prevId = index > 0 ? items[index - 1] : null;
            const nextId = index < items.length - 1 ? items[index + 1] : null;
            const isLastTodoInCategory = index === items.length - 1;

            return (
              <Todo
                id={id}
                key={`todo-${id}`}
                index={index}
                prevId={prevId}
                nextId={nextId}
                isLast={isLastTodoInCategory}
                colorFamily={colorFamily}
              />
            );
          })
        : null}

      {showAddTodoButton && (
        <TodoAddButton
          colorFamily={colorFamilyToColor(colorFamily)}
          categoryId={category.id}
        />
      )}
    </SortableCategoryItem>
  );
};
