/* 23e84bec946f1dd9268a41e027b78de4e3525828
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteColorNicknameMutationVariables = Types.Exact<{
  color: Types.Scalars['String'];
}>;


export type DeleteColorNicknameMutation = { __typename?: 'mutation_root', delete_colorNickname?: { __typename?: 'colorNickname_mutation_response', returning: Array<{ __typename?: 'colorNickname', color: string }> } | null };

export type UpsertColorNicknameMutationVariables = Types.Exact<{
  color: Types.Scalars['String'];
  nickname: Types.Scalars['String'];
}>;


export type UpsertColorNicknameMutation = { __typename?: 'mutation_root', insert_colorNickname?: { __typename?: 'colorNickname_mutation_response', returning: Array<{ __typename?: 'colorNickname', color: string, nickname: string }> } | null };


export const DeleteColorNicknameDocument = gql`
    mutation DeleteColorNickname($color: String!) {
  delete_colorNickname(where: {color: {_eq: $color}}) {
    returning {
      color
    }
  }
}
    `;

export function useDeleteColorNicknameMutation() {
  return Urql.useMutation<DeleteColorNicknameMutation, DeleteColorNicknameMutationVariables>(DeleteColorNicknameDocument);
};
export const UpsertColorNicknameDocument = gql`
    mutation UpsertColorNickname($color: String!, $nickname: String!) {
  insert_colorNickname(
    objects: {color: $color, nickname: $nickname}
    on_conflict: {constraint: colorNickname_color_nickname_userId_key}
  ) {
    returning {
      color
      nickname
    }
  }
}
    `;

export function useUpsertColorNicknameMutation() {
  return Urql.useMutation<UpsertColorNicknameMutation, UpsertColorNicknameMutationVariables>(UpsertColorNicknameDocument);
};