import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import { useColorHotkey } from 'hooks/useHotkey';
import Tooltip from 'joy/Tooltip';
import React from 'react';
import { useLayer } from 'react-laag';
import type { PlacementType } from 'react-laag/dist/PlacementType';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { useColorNicknameMap } from './Settings/rows/EventColorRename';

interface Props {
  value: ColorFamily;
  onChange: (value: ColorFamily) => void;
  clickedOutside: () => void;
  expanded: boolean;
  isHotkeyEnabled?: boolean;
  placement: PlacementType;
}

export default React.memo(function NewColorPicker({
  value,
  onChange,
  clickedOutside,
  expanded,
  isHotkeyEnabled,
  placement,
}: Props) {
  useColorHotkey((color) => onChange(color), {
    scope: 'all',
    enabled: isHotkeyEnabled || expanded,
  });
  const colorNicknameMap = useColorNicknameMap();
  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen: expanded,
    overflowContainer: true,
    auto: true,
    placement,
    triggerOffset: 12,
    onOutsideClick: clickedOutside,
  });

  if (!expanded) return null;

  return (
    <div className={'mt-2 flex items-center'} {...triggerProps}>
      {renderLayer(
        <div
          {...layerProps}
          className="bg-dropdown relative z-100 mt-2 ml-0.5 flex h-10 w-max items-center overflow-hidden rounded-lg px-3 py-2 shadow-md"
        >
          <div className="flex w-full items-center justify-between gap-2">
            {Object.entries(EVENT_COLOR_MAP).map(
              ([colorName, colorProp], idx) => (
                <Tooltip
                  key={colorName}
                  shortcut={`${++idx}`}
                  content={
                    <span className="text-xs font-medium capitalize leading-none">
                      {colorNicknameMap[colorName]}
                    </span>
                  }
                  targetOffset={12}
                >
                  <button
                    className="relative flex items-center justify-center"
                    name={`color-${colorName}`}
                    onClick={() => {
                      onChange(colorName as ColorFamily);
                    }}
                  >
                    <span
                      className={classNames(
                        'h-5 w-5 scale-100 transform rounded-full border-2 transition-transform hover:scale-110 active:scale-100',
                        colorProp.colorSwatchBg,
                        colorProp.border,
                        colorProp.ring,
                        {
                          'ring-2 ring-opacity-40 ring-offset-1 ring-offset-white dark:ring-offset-gray-600':
                            colorName === value,
                        }
                      )}
                    />
                  </button>
                </Tooltip>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
});
