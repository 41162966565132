export enum ModalType {
  None,
  Event,
  EventConfirmation,
  Settings,
  SetStatus,
  EmojiPicker,
  QuickMenu,
  SharePopover,
  Profile,
  SendFeedback,
}
