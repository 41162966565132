/* eslint-disable react/display-name */
import classNames from 'classnames';
import IconSushiRoll from 'components/Icons/IconSushiRoll';
import IconClear from 'components/Icons/IconClear';
import EmojiPicker from 'components/UserPopover/EmojiPicker';
import { EmojiData } from 'emoji-mart';
import { motion } from 'framer-motion';
import {
  timezonePreferencesAtom,
  TimezonePreference,
  useRemoveTimezone,
} from 'hooks/useTimeZone';
import { filterTimezones } from 'hooks/useTimezoneSearch';
import hotkeys from 'hotkeys-js';
import Button from 'joy/Button';
import ComboBox, { ComboBoxItem } from 'joy/ComboBox';
import Dropdown, { DropdownItem } from 'joy/Dropdown';
import Input from 'joy/Input';
import Tooltip from 'joy/Tooltip';
import { useUnmountEffect } from 'joy/utils';
import { type } from 'os';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useUpdateAtom } from 'jotai/utils';
import useHotkey from 'hooks/useHotkey';
import IconStar from 'components/Icons/IconStar';
import IconTrash from 'components/Icons/IconTrash';
import { getShortTimeZone } from 'utils/time';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';

interface Props {
  timezonePreference: TimezonePreference | null;
  isRemoveable: boolean;
  onClose: () => void;
  className?: string;
}

const getFormattedTimezone = (
  city: string | undefined,
  timezoneCode: string
) => {
  if (!timezoneCode) return city;
  return `${city} (${getShortTimeZone(DateTime.now().setZone(timezoneCode))})`;
};

export default React.forwardRef(
  (
    { timezonePreference, isRemoveable, onClose, ...props }: Props,
    ref: React.Ref<HTMLDivElement>
  ): JSX.Element => {
    const timezoneInputRef = useRef<HTMLInputElement | null>(null);
    const nicknameInputRef = useRef<HTMLInputElement | null>(null);

    const setTimezonePreferences = useUpdateAtom(timezonePreferencesAtom);
    const removeTimezone = useRemoveTimezone();

    const [city, setCity] = useState(timezonePreference?.city);
    const [timezone, setTimezone] = useState<string>(
      timezonePreference?.timezone || ''
    );
    const [timezoneInput, setTimezoneInput] = useState(
      getFormattedTimezone(city, timezone) || ''
    );

    const timezoneRef = useRef(timezoneInput);

    const [nicknameInput, setNicknameInput] = useState(
      timezonePreference?.nickname
    );
    const [priority, setPriority] = useState(
      timezonePreference?.priority || 'secondary'
    );
    const [emojiData, setEmojiData] = useState<EmojiData | undefined | null>(
      timezonePreference?.emoji
    );

    const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);

    useEffect(() => {
      if (!timezone) return;
      setTimezoneInput(
        `${city} (${getShortTimeZone(DateTime.now().setZone(timezone))})`
      );
    }, [city, timezone]);

    const draftTimezonePreferenceRef = useRef<TimezonePreference | null>(
      timezonePreference
    );

    useEffect(() => {
      draftTimezonePreferenceRef.current = {
        ...timezonePreference,
        nickname: nicknameInput,
        timezone: timezone,
        emoji: emojiData || null,
        city,
        priority,
      };
    }, [
      timezonePreference,
      nicknameInput,
      timezone,
      emojiData,
      city,
      priority,
    ]);

    useUnmountEffect(() => {
      const editedTimezonePreference = draftTimezonePreferenceRef.current;

      if (
        !editedTimezonePreference?.timezone ||
        editedTimezonePreference.timezone.trim().length === 0
      )
        return;

      if (timezonePreference) {
        if (editedTimezonePreference.timezone?.trim().length === 0) {
          editedTimezonePreference.timezone = timezonePreference.timezone;
        }
      }

      setTimezonePreferences(editedTimezonePreference);
    });

    useHotkey(
      'escape',
      {
        scope: 'all',
      },
      (e) => {
        e.preventDefault();
        onClose();
      }
    );

    useHotkey(
      'enter',
      {
        scope: 'all',
        enabledWithinInput: false,
      },
      (e) => {
        e.preventDefault();
        onClose();
      }
    );

    const onFocus = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) =>
        event.currentTarget.select(),
      []
    );

    const onBlur = useCallback(() => {
      const chosenTimezone = filterTimezones(timezoneRef.current, 1);

      // If the input was invalid (no timezone match) then revert to the saved timezonePreference.
      if (!chosenTimezone?.length || timezoneRef.current.trim().length === 0) {
        if (timezonePreference) setTimezone(timezonePreference?.timezone);
        getFormattedTimezone(city, timezone);
      } else {
        // The timezone is valid - so save the tzCode.
        setTimezone(chosenTimezone[0].tzCode);

        // If our city doesn't match the input, then revert to the first location result.
        if (
          !chosenTimezone[0].locations.some(
            (location: string) => location === city
          )
        ) {
          setCity(chosenTimezone[0].locations[0]);
        }
      }
    }, [city, timezone, timezonePreference]);

    useEffect(() => {
      timezoneRef.current = timezoneInput;
    }, [timezoneInput]);

    const blurInput = useCallback(
      () => timezoneInputRef?.current && timezoneInputRef.current.focus(),
      [timezoneInputRef]
    );

    const timezoneInputOptions: ComboBoxItem[] = useMemo(() => {
      const timezoneOptions = filterTimezones(
        timezoneInput ? timezoneInput.split('(')[0].trim() : '',
        8,
        true
      );

      return timezoneOptions.map((x) => ({
        type: 'option',
        value: x.locations[0],
        text: x.locations[0],
        subtext: `(${getShortTimeZone(DateTime.now().setZone(x.tzCode))})`,
      }));
    }, [timezoneInput]);

    const onSelect = useCallback((value: string) => {
      const chosenTimezones = filterTimezones(value, 1, true);
      if (!chosenTimezones.length) return;

      const chosenCity = chosenTimezones.find((x) =>
        x.locations.some((y) => y.toLowerCase() === value.toLowerCase())
      )?.locations[0];

      if (!chosenCity) return;

      timezoneRef.current = chosenTimezones[0].tzCode;
      setTimezone(timezoneRef.current);
      setCity(chosenCity);
      setTimezoneInput(
        getFormattedTimezone(chosenCity, timezoneRef.current) || ''
      );

      nicknameInputRef.current?.focus();
    }, []);

    const sushiOptions: DropdownItem[] = useMemo(() => {
      const options: DropdownItem[] = [];

      if (isRemoveable) {
        options.push({
          type: 'option',
          value: timezonePreference ? 'Remove timezone' : 'Discard timezone',
          variant: timezonePreference ? 'red' : undefined,
          onSelect: () => {
            if (timezonePreference) {
              removeTimezone(timezonePreference.id);
              draftTimezonePreferenceRef.current = null;
              onClose();
            } else {
              // Just close without saving if this was
              // a new timezone preference (pressed the + button).
              onClose();
            }
          },
          icon: <IconTrash className="h-5 w-5" />,
        });
      }

      if (draftTimezonePreferenceRef.current?.priority !== 'primary') {
        options.unshift({
          type: 'option',
          value: 'Set as primary',
          onSelect: () => {
            setPriority('primary');
            onClose();
          },
          icon: <IconStar className="h-5 w-5" />,
        });
      }

      return options;
    }, [onClose, isRemoveable, removeTimezone, timezonePreference]);

    return (
      <motion.div {...props} ref={ref}>
        <div className="bg-popover pointer-events-auto z-100 flex w-full space-x-8 rounded-xl p-3 shadow-feintXl transition-colors">
          <div className="flex flex-1 flex-col">
            <div className="flex w-full flex-row justify-between">
              <p className="mb-3.5 self-start text-sm font-semibold">
                {timezonePreference ? 'Edit' : 'Add'} timezone
              </p>

              {sushiOptions.length > 0 && (
                <Dropdown
                  className="h-6 w-6"
                  items={sushiOptions}
                  onClose={() => setIsMoreOptionsOpen(false)}
                >
                  <Tooltip content={'More options'}>
                    <Button
                      className={classNames(
                        'rotate rotate-90 rounded-md bg-gray-200 bg-opacity-0 p-1 text-gray-800/50 opacity-100 hover:bg-opacity-40 dark:bg-gray-300 dark:bg-opacity-0 dark:text-gray-200/50 dark:opacity-100 dark:hover:bg-opacity-10',
                        {
                          'bg-opacity-40 dark:bg-opacity-10': isMoreOptionsOpen,
                          'bg-opacity-0 dark:bg-opacity-0': !isMoreOptionsOpen,
                        }
                      )}
                      tabIndex={-1}
                      onClick={() => setIsMoreOptionsOpen(!isMoreOptionsOpen)}
                    >
                      <IconSushiRoll className="h-4 w-4" />
                    </Button>
                  </Tooltip>
                </Dropdown>
              )}
            </div>

            <div className="flex flex-row gap-2">
              <div className="flex flex-col gap-1.5">
                <small className="text-secondary text-[11px] font-black uppercase leading-snug tracking-wider transition-colors">
                  TIMEZONE
                </small>
                <div className="relative flex w-full">
                  <div
                    onKeyDown={() => hotkeys.isPressed('esc') && blurInput()}
                    onClick={() => timezoneInputRef?.current?.focus()}
                    role="presentation"
                    className="flex w-full"
                  >
                    <ComboBox
                      aria-label="Date"
                      onSubmit={onSelect}
                      onInputChange={setTimezoneInput}
                      items={
                        timezoneInput?.length > 0 ? timezoneInputOptions : []
                      }
                      inputRef={timezoneInputRef}
                      className={'w-full items-center'}
                    >
                      <Input
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={timezonePreference == null}
                        ref={timezoneInputRef}
                        placeholder="City or timezone"
                        className="w-[150px] rounded-lg bg-gray-100 p-2 text-sm font-medium outline-none transition-colors hover:border-gray-200 focus:border-gray-200 focus:bg-gray-150 dark:bg-gray-600"
                        value={timezoneInput}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            onSelect(timezoneInput);
                            return;
                          }
                        }}
                        onChange={(e) => {
                          setTimezoneInput(e.target.value);
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        name={`timezone-${type}`}
                      />
                    </ComboBox>
                  </div>
                </div>
              </div>

              <div className="flex w-[135px] flex-col gap-1.5">
                <small className="text-secondary text-[11px] font-black uppercase leading-snug tracking-wider transition-colors">
                  NICKNAME
                </small>
                <div className="relative flex w-full">
                  <div className="absolute top-0 left-0 flex h-full w-10 items-center justify-center">
                    <EmojiPicker
                      emoji={emojiData}
                      onEmojiChange={setEmojiData}
                      tabIndex={-1}
                    />
                  </div>

                  {(!isEmpty(nicknameInput) || emojiData != null) && (
                    <div className="absolute top-0 right-0 flex h-full w-11 items-center justify-center">
                      <Tooltip content="Clear nickname">
                        <Button
                          onClick={() => {
                            setNicknameInput('');
                            setEmojiData(null);
                          }}
                          tabIndex={-1}
                          className="scale-100 text-gray-400 transition hover:scale-105 hover:text-gray-500 active:scale-100 dark:text-gray-400 dark:hover:text-gray-300 "
                        >
                          <IconClear />
                        </Button>
                      </Tooltip>
                    </div>
                  )}

                  <Input
                    spellCheck={false}
                    placeholder="Home"
                    value={nicknameInput}
                    ref={nicknameInputRef}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && !event.metaKey) {
                        event.stopPropagation();
                        onClose();
                      }
                    }}
                    onChange={(event) =>
                      setNicknameInput(event.currentTarget.value.slice(0, 5))
                    }
                    className="w-full rounded-lg bg-gray-100 p-2 pl-9 text-sm font-medium outline-none transition-colors hover:border-gray-200 focus:border-gray-200 focus:bg-gray-150 dark:bg-gray-600"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
);
