import { useFakeEvents } from 'components/Grid/useFakeEvents';
import IconCalendar from 'components/Icons/IconCalendar';
import IconClock from 'components/Icons/IconClock';
import IconLink from 'components/Icons/IconLink';
import useAvailableCalendars from 'components/Panels/useAvailableCalendars';
import { useFakeTodos } from 'components/Todos/useFakeTodos';
import { useIsWorkEmail, useUserEmail } from 'hooks/auth/authAtoms';
import { useCreateDraftCalendarLink } from 'hooks/calendarLink/creation/useCreateDraftCalendarLink';
import { useUpdateModal } from 'hooks/useModal';
import { useSetVisibleCalendars } from 'hooks/useVisibleCalendars';
import React, { useCallback, useRef } from 'react';
import {
  HookProps,
  ListGroup,
  ListItemAction,
  NavItemContactSearch,
  NavItemTimezones,
  NavItemType,
} from '../types';
import { filterBySearch } from './useListGroups';
import useSetNavItems from './useSetNavItems';

export default function useActions({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const createDraft = useCreateDraftCalendarLink();
  const { closeModal } = useUpdateModal();

  const { addNavItem } = useSetNavItems();

  const { addCalendar, removeCalendar } = useSetVisibleCalendars();
  const availableCalendars = useAvailableCalendars();

  // These are used for debug stuff.
  const { toggleFakeEvents } = useFakeEvents();
  const { toggleFakeTodos } = useFakeTodos();
  const userEmail = useUserEmail() || '';
  const isWorkEmail = useIsWorkEmail();

  const isAvatarActive = useCallback(
    (email: string) => availableCalendars.some((cal) => cal.name === email),
    [availableCalendars]
  );

  const listGroupRef = useRef<ListGroup>({
    title: 'Actions',
    items: [],
  });
  listGroupRef.current.items.length = 0;

  const showColleaguesCalendar = useCallback(() => {
    const navItem: NavItemContactSearch = {
      type: NavItemType.contactSearch,
      title: `Toggle colleague's calendar`,
      inputPlaceholder: 'Type to search...',
      hidden: true,
      onlySameDomain: true,
      getActionNameOverride: (contact) =>
        isAvatarActive(contact.emailAddress) ? 'Hide' : 'Show',
      callback: (contact) => {
        if (isAvatarActive(contact.emailAddress)) {
          removeCalendar(contact.emailAddress);
        } else {
          addCalendar(contact.emailAddress);
        }

        closeModal();
      },
    };

    addNavItem({
      type: navItem.type,
      fullObject: navItem,
    });
  }, [addCalendar, addNavItem, closeModal, isAvatarActive, removeCalendar]);

  const chooseTimezone = useCallback(() => {
    const navItem: NavItemTimezones = {
      type: NavItemType.timezones,
      title: `Change timezone`,
      hidden: true,
    };

    addNavItem({
      type: navItem.type,
      fullObject: navItem,
    });
  }, [addNavItem]);

  const options: ListItemAction[] = [];
  options.push({
    id: 'slots',
    title: 'Create share link',
    keywords: ['slot', 'share'],
    type: 'action',
    actionOverride: () => {
      closeModal();
      createDraft('one-off');
    },
    Icon: () => <IconLink className="h-5 w-5 p-0.5 text-gray-400" />,
    onlyAppearsInSearch: true,
    actionNameOverride: 'Create',
  });

  if (isWorkEmail) {
    options.push({
      id: 'showColleaguesCalendar',
      title: `Show colleague's calendar`,
      type: 'action',
      keywords: ['colleague', 'visibility'],
      Icon: () => <IconCalendar className="h-5 w-5 p-0.5 text-gray-400" />,
      actionNameOverride: 'Find',
      actionOverride: showColleaguesCalendar,
    });
  }

  options.push({
    id: 'changeTimezone',
    title: `Change timezone`,
    type: 'action',
    keywords: ['timezone', 'choose', 'change'],
    Icon: () => <IconClock className="h-5 w-5 p-0.5 text-gray-400" />,
    actionNameOverride: 'Select',
    actionOverride: chooseTimezone,
  });

  // Only employees of Amie and people at pubGENIUS named Kevin can access these.
  if (
    userEmail.includes('@amie.so') ||
    (userEmail.includes('kevin') && userEmail.includes('@pubgenius.io'))
  ) {
    options.push(
      {
        id: 'debug-events',
        title: 'Toggle debug events',
        keywords: ['events', 'fake', 'fake events', 'debug'],
        type: 'action',
        actionOverride: () => {
          closeModal();
          toggleFakeEvents();
        },
        Icon: () => <span className="text-md h-5  w-5 text-center"> 🚧 </span>,
      },
      {
        id: 'debug-todos',
        title: 'Toggle debug todos',
        keywords: ['todos', 'fake', 'fake todos', 'debug'],
        type: 'action',
        actionOverride: () => {
          closeModal();
          toggleFakeTodos();
        },
        Icon: () => <span className="text-md h-5 w-5 text-center"> 🚧 </span>,
      }
    );
  }

  if (!enabled) return listGroupRef.current;

  listGroupRef.current.items = filterBySearch(options, searchTerm);
  return listGroupRef.current;
}
