import { useRef } from 'react';
import {
  HookProps,
  ListGroup,
  ListItemTimezone,
} from 'components/QuickMenu/types';
import { useUpdateModal } from 'hooks/useModal';
import { filterTimezones } from 'hooks/useTimezoneSearch';
import { useUi24HourClock, useUpdatePreference } from 'hooks/usePreferences';
import { PreferenceName } from 'types/preference';
import { DateTime } from 'luxon';

const TIMEZONES_LIMIT = 50;

export function useTimezonesListGroup({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const updatePreference = useUpdatePreference();
  const ui24HourClock = useUi24HourClock();
  const results = filterTimezones(searchTerm || '', TIMEZONES_LIMIT);
  const { closeModal } = useUpdateModal();

  const listGroupRef = useRef<ListGroup>({
    title: 'Timezones',
    items: [],
  });

  listGroupRef.current.items.length = 0;

  if (!enabled) return listGroupRef.current;

  listGroupRef.current.items = results.map<ListItemTimezone>((timezone) => {
    const location =
      searchTerm &&
      timezone.locations.find((s) =>
        s.toLowerCase().includes(searchTerm.toLowerCase())
      );

    const date = DateTime.now().setZone(timezone.tzCode);

    return {
      id: timezone.tzCode,
      time: ui24HourClock ? date.toFormat('HH:mm') : date.toFormat('h:mm a'),
      title: location || timezone.locations[0],
      description: `${timezone.timeDiff}`,
      type: 'timezone',
      actionOverride: () => {
        updatePreference(PreferenceName.Timezone, timezone.tzCode);
        closeModal();
      },
      Icon: () => null,
    };
  });

  return listGroupRef.current;
}
