import React from 'react';
import { IconSVGProps } from 'types/icon';

const IconCheckboxMark: React.FC<IconSVGProps> = ({
  height = 16,
  width = 16,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M6 13l4 4 8-9"
      />
    </svg>
  );
};

export default IconCheckboxMark;
