import { selectedCalendarLinkAtom } from 'hooks/calendarLink/creation/useSelectedCalendarLink';
import { atom } from 'jotai';
import { useAtomValue } from 'jotai/utils';

export type CalendarMode =
  | 'default'
  | 'selecting-one-off-slots'
  | 'selecting-recurring-slots';

export const calendarModeAtom = atom<CalendarMode>((get) => {
  const selectedCalendarLink = get(selectedCalendarLinkAtom);
  if (!selectedCalendarLink) {
    return 'default';
  }
  return selectedCalendarLink.type === 'recurring'
    ? 'selecting-recurring-slots'
    : 'selecting-one-off-slots';
});

export function useCalendarModeValue() {
  return useAtomValue(calendarModeAtom);
}
