/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useDroppable } from '@dnd-kit/core';
import { usePrimaryCalendarId, userEmailAtom } from 'hooks/auth/authAtoms';
import classNames from 'classnames';
import { isDraggingAtom } from 'components/Todos/todos-dnd';
import { perfStart } from 'contexts/performance';
import { updateGridSlotAtomCallback } from 'hooks/calendarLink/creation/useUpdateSlot';
import { generateEventUUID } from 'hooks/events/helpers/eventsHelpers';

import { useCalendarModeValue } from 'hooks/useCalendarMode';
import {
  eventsSelectionAtom,
  useSetEventsSelection,
} from 'hooks/useEventsSelection';
import { useUpdateModal } from 'hooks/useModal';
import { useAtomCallback, useAtomValue } from 'jotai/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { mergeRefs } from 'react-laag';
import { DroppableId } from 'types/drag-and-drop';
import { ModalType } from 'types/modal';
import {
  cmdKeyIsDown,
  getAbsoluteDistance,
  getCoordinates,
} from 'utils/mouseEvents';
import { uuid } from 'utils/toolbox';
import { isResizingEventAtom } from '../GridResizeHandles';
import { isTimezoneModalOpenAtom } from '../GridTimezoneChanger';
import GridSelectionFrame from './GridSelectionFrame';
import {
  GridCoordinates,
  isCreatingDraft,
  useMouseEventToGridCoordinates,
} from './helpers/gridHelpers';
import { useClickToCreateEvent } from './hooks/useClickToCreateEvent';
import { useClickToCreateSlot } from './hooks/useClickToCreateSlot';
import useDragToCreate from './hooks/useDragToCreate';
import { useHandleSelectionFrame } from './hooks/useHandleSelectionFrame';
import { contextMenuAtom } from 'joy/ContextMenu';
import { updateGridEventForInteractionOnlyAtomCallback } from 'hooks/events/eventsAtomCallbacks/updateEventAtomCallback';
import { selectedSlotIdAtom } from 'hooks/calendarLink/creation/useSelectedSlotId';

const LEFT_CLICK = 0;

type GridInteractionsHandlerState =
  | 'idle'
  | 'ready'
  | 'drag-to-create'
  | 'drag-to-select';

export default React.memo(function GridInteractionsHandler() {
  const gridRef = useRef(null);
  const clickHandlerStateRef = useRef<GridInteractionsHandlerState>('idle');
  const initialClickCoordRef = useRef<GridCoordinates | null>(null);
  const [isDraggingToCreate, setIsDraggingToCreate] = useState(false);
  const calendarMode = useCalendarModeValue();
  const primaryCalendarId = usePrimaryCalendarId();
  const slotIdRef = useRef(uuid());
  const draftEventIdRef = useRef(generateEventUUID(primaryCalendarId));
  const { openModal } = useUpdateModal();
  const { setEventsSelection } = useSetEventsSelection();
  const isResizing = useAtomValue(isResizingEventAtom);
  const isDragingTodo = useAtomValue(isDraggingAtom);

  const isTimezoneModalOpen = useAtomValue(isTimezoneModalOpenAtom);
  const isTimezoneModalOpenRef = useRef<boolean>(isTimezoneModalOpen);
  useEffect(() => {
    isTimezoneModalOpenRef.current = isTimezoneModalOpen;
  }, [isTimezoneModalOpen]);

  const handleClickToCreateEvent = useClickToCreateEvent();
  const handleClickToCreateSlot = useClickToCreateSlot();
  const { updateSelectionFrame, clearSelectionFrame } =
    useHandleSelectionFrame();

  const { onDragToCreate } = useDragToCreate();
  const clickToGridCoordinates = useMouseEventToGridCoordinates();

  const iddleToReady = async (mouseEvent: React.MouseEvent<HTMLDivElement>) => {
    if (clickHandlerStateRef.current !== 'idle') return;
    initialClickCoordRef.current = await clickToGridCoordinates({
      mouseEvent,
      roundToNearestQuarterHour: true,
    });
    clickHandlerStateRef.current = 'ready';
  };

  const readyToDragToSelect = () => {
    if (clickHandlerStateRef.current !== 'ready') return;
    clickHandlerStateRef.current = 'drag-to-select';
    setIsDraggingToCreate(true);
  };

  const readyToDragToCreate = useAtomCallback(
    useCallback(
      (get, _set, mouseEvent: React.MouseEvent<HTMLDivElement>) => {
        if (clickHandlerStateRef.current !== 'ready') return;
        clickHandlerStateRef.current = 'drag-to-create';
        setIsDraggingToCreate(true);

        if (calendarMode === 'default') {
          if (isCreatingDraft(get)) return;
          const primaryCalendarId = get(userEmailAtom) || 'default';
          draftEventIdRef.current = generateEventUUID(primaryCalendarId);
          handleClickToCreateEvent({
            id: draftEventIdRef.current,
            mouseEvent,
            initialCoordinates:
              initialClickCoordRef.current || getCoordinates(mouseEvent),
            openPopoverOnCreate: false,
            durationMinutes: 15,
          });
        } else {
          slotIdRef.current = uuid();
          handleClickToCreateSlot({
            id: slotIdRef.current,
            mouseEvent,
            initialCoordinates:
              initialClickCoordRef.current || getCoordinates(mouseEvent),
          });
        }
      },
      [calendarMode, handleClickToCreateEvent, handleClickToCreateSlot]
    )
  );

  const goIdle = useCallback(() => {
    if (clickHandlerStateRef.current === 'drag-to-select') {
      clearSelectionFrame();
    }
    if (
      calendarMode === 'default' &&
      clickHandlerStateRef.current === 'drag-to-create'
    ) {
      perfStart('open-event-popover');
      openModal(ModalType.Event);
      setEventsSelection([draftEventIdRef.current]);
    }
    initialClickCoordRef.current = null;
    clickHandlerStateRef.current = 'idle';
    setIsDraggingToCreate(false);
  }, [calendarMode, clearSelectionFrame, openModal, setEventsSelection]);

  const handleMouseDown = (mouseEvent: React.MouseEvent<HTMLDivElement>) => {
    if (mouseEvent.button !== LEFT_CLICK) return;
    switch (clickHandlerStateRef.current) {
      case 'idle':
        return iddleToReady(mouseEvent);
      default:
        // do nothing
        return;
    }
  };

  const handleMouseMove = (mouseEvent: React.MouseEvent<HTMLDivElement>) => {
    let distance = 0;
    mouseEvent.preventDefault(); // Prevent other elements to react on over
    // Update the gridMouseCoordinates for other components to access
    gridMouseCoordinates.x = mouseEvent.nativeEvent.offsetX;
    gridMouseCoordinates.y = mouseEvent.nativeEvent.offsetY;

    switch (clickHandlerStateRef.current) {
      case 'ready':
        distance = getAbsoluteDistance(
          initialClickCoordRef.current,
          getCoordinates(mouseEvent)
        );
        if (distance < 4) return;
        if (cmdKeyIsDown(mouseEvent)) {
          readyToDragToSelect();
        } else {
          readyToDragToCreate(mouseEvent);
        }
        return;
      case 'drag-to-create':
        if (!initialClickCoordRef.current?.date) return;
        onDragToCreate({
          mouseEvent,
          originalClickedDate: initialClickCoordRef.current.date,
          onUpdate: ({ get, set, startAt, endAt }) => {
            if (calendarMode === 'default') {
              updateGridEventForInteractionOnlyAtomCallback(get, set, {
                id: draftEventIdRef.current,
                startAt,
                endAt,
              });
            } else {
              updateGridSlotAtomCallback(get, set, {
                id: slotIdRef.current,
                startAt,
                endAt,
              });
            }
          },
        });
        return;
      case 'drag-to-select':
        if (!initialClickCoordRef.current) return;
        updateSelectionFrame({
          x: initialClickCoordRef.current.x,
          y: initialClickCoordRef.current.y,
          mouseEvent,
        });
        return;
      default:
        // Do nothing
        return;
    }
  };

  const handleMouseUp = useAtomCallback(
    (get, set, mouseEvent: React.MouseEvent<HTMLDivElement>) => {
      // If you are editing a timezone preference and click the grid,
      // it should not create a draft event.
      if (isTimezoneModalOpenRef.current) {
        return;
      }

      // If you a context menu is open and click the grid,
      // it should not create a draft event.
      /**
       * TODO: Remove from this line after fixing Dropdown.tsx
       * We have a bug with the useLayer hook that causes
       * the onClose event that clears the context menu
       * not being called, so we have to manually clear it.
       */
      if (get(contextMenuAtom)) {
        set(contextMenuAtom, undefined);

        /**
         *  We also want to clear the events and close
         *  the event popover if it is open, otherwise,
         *  the user would have to do 2 clicks
         */
        if (get(eventsSelectionAtom).length > 0) {
          set(eventsSelectionAtom, []);
        }
        return;
      }
      /** TODO: Remove until this line after fixing Dropdown.tsx */

      switch (clickHandlerStateRef.current) {
        case 'ready':
          if (!initialClickCoordRef.current) {
            break;
          }
          if (get(eventsSelectionAtom).length > 0) {
            // unselect all events
            set(eventsSelectionAtom, []);
            break;
          }
          if (calendarMode === 'default') {
            const primaryCalendarId = get(userEmailAtom) || 'default';
            draftEventIdRef.current = generateEventUUID(primaryCalendarId);
            handleClickToCreateEvent({
              id: draftEventIdRef.current,
              mouseEvent,
              initialCoordinates: initialClickCoordRef.current,
              openPopoverOnCreate: true,
            });
          } else {
            const selectedSlotId = get(selectedSlotIdAtom);
            if (selectedSlotId) {
              // is a slot is selected, deselect it without creating a new one
              set(selectedSlotIdAtom, null);
              return;
            }

            handleClickToCreateSlot({
              id: uuid(),
              mouseEvent,
              initialCoordinates: initialClickCoordRef.current,
            });
          }
          break;
        case 'drag-to-create':
          break;
        case 'drag-to-select':
          // Do nothing? or go idle?
          break;
        default:
      }
      goIdle();
    }
  );

  // Capture the mouseUp event on the page
  useEffect(() => {
    document.addEventListener('mouseup', goIdle);
    return () => document.removeEventListener('mouseup', goIdle);
  }, [goIdle]);

  const { setNodeRef, isOver } = useDroppable({
    id: DroppableId.SCHEDULE,
  });

  const areInteractionsDisabled =
    isResizing || isDraggingToCreate || isDragingTodo || isOver;

  return (
    <div
      key="grid-interaction-handler"
      id="grid-interaction-handler"
      className={classNames('relative', {
        'cursor-ns-resize': isResizing,
        'cursor-grabbing': isOver,
        'z-40': areInteractionsDisabled,
      })}
      ref={mergeRefs(setNodeRef, gridRef)}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      style={{ gridArea: '1 / 3 / -1 / end' }}
    >
      <GridSelectionFrame />
    </div>
  );
});

const gridMouseCoordinates = {
  x: 0,
  y: 0,
};

export function getGridMouseCoordinates() {
  return gridMouseCoordinates;
}

/**
 * !!! This method should not be used in rapid interactions such as dragging
 * This getBoundingClientRect() is causing reflow
 */
export function __LowPerf__getGridInteractionHandlerBoundingRect() {
  return (
    document
      .getElementById('grid-interaction-handler')
      ?.getBoundingClientRect() || null
  );
}
