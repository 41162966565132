import { useCallback, useRef } from 'react';
import sortBy from 'lodash/sortBy';
import { ComboBoxItem } from 'joy/ComboBox';
import useFeatureFlag from 'hooks/useFeatureFlag';

import { usePreferences } from 'hooks/usePreferences';

import { DropdownItem } from 'joy/Dropdown';
import {
  getContextMenuOptions,
  getCurrentProvider,
  getVideoProviderOptions,
  DROPDOWN_TITLE_VIDEO,
} from './utils';
import { AMIE_VIDEO_LINK_PLACEHOLDER, getLocationMetadata } from 'utils/video';
import { useFullUser, useGetUserId } from 'hooks/auth/authAtoms';
import { buildAmieVideoUrl, createRoom } from 'components/room/roomService';
import { useGetGridEvent } from 'hooks/events/useGridEvents';
import { useOpenSettingsPage } from 'components/Settings/useSettings';

export const useLocationAutoComplete = ({
  value,
  onDropdownItemSelect,
  isDraft,
  readOnly,
}: {
  value: string | undefined;
  onDropdownItemSelect?: (item: string) => Promise<void>;
  isDraft?: boolean;
  readOnly?: boolean;
}) => {
  const features = useFeatureFlag();
  const fullUser = useFullUser();
  const { openSettingsTab } = useOpenSettingsPage();
  const preferences = usePreferences();

  const currentProvider = value == null ? null : getCurrentProvider(value);

  const getComboBoxItems = useCallback(() => {
    const { comboBoxItemOptions } = getVideoProviderOptions({
      features,
      fullUser,
      currentProvider,
      preferences,
      openSettingsTab,
      onDropdownItemSelect,
      isDraft,
    });

    let comboBoxItems: ComboBoxItem[] = [];

    if (!value || value.length === 0) {
      comboBoxItems = comboBoxItemOptions;

      // Disabled items at the bottom
      comboBoxItems = sortBy(comboBoxItems, 'disabled');

      // add the title of the section as first item
      comboBoxItems.unshift({ type: 'title', value: DROPDOWN_TITLE_VIDEO });
    }

    return comboBoxItems;
  }, [
    currentProvider,
    features,
    isDraft,
    onDropdownItemSelect,
    openSettingsTab,
    preferences,
    fullUser,
    value,
  ]);

  const getDropdownItems = useCallback(() => {
    const { dropdownItemOptions } = getVideoProviderOptions({
      features,
      fullUser,
      currentProvider,
      preferences,
      openSettingsTab,
      onDropdownItemSelect,
      isDraft,
    });

    let dropdownItems: DropdownItem[] = dropdownItemOptions;

    // Disabled items at the bottom
    dropdownItems = sortBy(dropdownItems, 'disabled');

    // add the title of the section as first item
    dropdownItems.unshift({ type: 'title', value: DROPDOWN_TITLE_VIDEO });
    return dropdownItems;
  }, [
    currentProvider,
    features,
    isDraft,
    onDropdownItemSelect,
    openSettingsTab,
    preferences,
    fullUser,
  ]);

  // Copy
  const { href } = getLocationMetadata(value || '');

  const copiedTimer = useRef(0);

  const onClickCopy = useCallback(() => {
    if (href) {
      navigator.clipboard.writeText(href);
      copiedTimer.current = window.setTimeout(
        () => window.clearTimeout(copiedTimer.current),
        3000
      );
    }
  }, [href]);

  const getContextMenuItems = useCallback(() => {
    const { contextMenuItems } = getContextMenuOptions({
      onClickCopy,
      onRemove: () => onDropdownItemSelect?.(''),
      value,
      readOnly,
    });
    return contextMenuItems;
  }, [onClickCopy, value, readOnly, onDropdownItemSelect]);

  return {
    getDropdownItems,
    getComboBoxItems,
    getContextMenuItems,
  };
};

export const useAmieVideo = ({
  value,
  eventId,
  onChange,
}: {
  value?: string;
  eventId?: string;
  onChange: (value: string) => void;
}) => {
  const getUserId = useGetUserId();
  const getEvent = useGetGridEvent();

  const createAmieVideoLink = useCallback(async () => {
    const userId = await getUserId();
    const event = await getEvent(eventId || '');

    if (!userId || event === null) return undefined;
    return await createRoom({ userId, event });
  }, [eventId, getEvent, getUserId]);

  const fetchAmieVideoUrl = useCallback(async () => {
    if (value === AMIE_VIDEO_LINK_PLACEHOLDER) {
      const amieVideoLink = await createAmieVideoLink();
      if (amieVideoLink) {
        const amieVideoUrl = buildAmieVideoUrl(amieVideoLink);
        onChange(amieVideoUrl);
      }
    }
  }, [createAmieVideoLink, onChange, value]);

  return { fetchAmieVideoUrl };
};
