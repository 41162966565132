export function getCursorImage(
  name: string,
  offset = { x: 10, y: 10 }
): string {
  const imagePath = `"/assets/cursors/${name}.png"`;
  const imagePath2x = `"/assets/cursors/${name}@2x.png"`;

  // Don't use image-set on Firefox, because it doesn't support it
  if (/Firefox\/\d+\.\d+$/.test(navigator?.userAgent)) {
    return `url(${imagePath}) ${offset.x} ${offset.y}, auto`;
  }

  return `-webkit-image-set(url(${imagePath}) 1x, url(${imagePath2x}) 2x) ${offset.x} ${offset.y}, default`;
}

export const addSlotCursor = (): string => getCursorImage('cursor-slot-add');
