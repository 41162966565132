import { useRouter } from 'next/router';
import { parseCookies, setCookie } from 'nookies';
import { useEffect, useMemo, useState } from 'react';
import { COOKIE_NAME_FEATURE_FLAG } from 'utils/constants';

export enum FeatureFlags {
  Video = 'video',
  CalendarSelect = 'calendarSelect',
  Feed = 'feed',
}

type FlagSet = {
  [FeatureFlags.Video]?: boolean;
  [FeatureFlags.CalendarSelect]?: boolean;
  [FeatureFlags.Feed]?: boolean;
};

const cookies = parseCookies();
const flagsFromCookie = cookies[COOKIE_NAME_FEATURE_FLAG];
const initialFlags = flagsFromCookie
  ? flagsFromCookie
      .split(',')
      .reduce<{ [key: string]: boolean }>((acc, curr) => {
        if (curr.length > 1) {
          acc[curr] = true;
        }

        return acc;
      }, {})
  : {};

function useFeatureFlag(): FlagSet {
  // apply feature flags by adding a query param to the url, e.g. /?feature=todos
  const [flags, setFlags] = useState<FlagSet>(initialFlags);
  const { query } = useRouter();
  const memoQuery = useMemo(() => query, [query]);

  useEffect(() => {
    // Get initial flags from URL query string...
    if (!memoQuery.feature) return;

    if (typeof memoQuery.feature === 'string') {
      const feature = memoQuery.feature;

      setFlags((prev) => ({
        ...prev,
        [feature]: true,
      }));
    }
  }, [memoQuery]);

  useEffect(() => {
    const flagsAsString = Object.keys(flags).join(',');

    if (!flagsAsString.length) return;

    setCookie(null, COOKIE_NAME_FEATURE_FLAG, flagsAsString, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    });
  }, [flags]);

  return flags;
}

export default useFeatureFlag;
