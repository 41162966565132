import { userEmailAtom } from 'hooks/auth/authAtoms';
import {
  confirmEventChanges,
  EventConfirmationCallback,
  eventConfirmationsAtom,
} from 'hooks/useEventConfirmations';
import { eventsSelectionAtom } from 'hooks/useEventsSelection';
import { saveServerEventsToCacheAtomCallback } from 'hooks/useFetchCurrentWeekFromCache';
import { timezoneAtom } from 'hooks/useTimeZone';
import { Getter, Setter } from 'jotai';
import { IGridEvent } from 'types/events';
import { createEventMutation } from '../api/eventsApi';
import {
  eventIdsPoolAtom,
  gridEventsFamily,
  optimisticEventsFamily,
  serverEventsAtomFamily,
} from '../eventAtoms';
import {
  addBatchEvents,
  removeEventFromPool,
} from '../helpers/eventAtomsHelpers';
import { getEventsDiff } from '../helpers/eventsDiffHelpers';
import {
  formatCreateEventPayload,
  formatServerEvent,
} from '../helpers/eventsHelpers';
import { generateEventInstances } from '../helpers/recurringEventsHelpers';

export async function createEventAtomCallback(
  get: Getter,
  set: Setter,
  props: Partial<IGridEvent>
): Promise<void> {
  if (!props.id) {
    console.error('_createGridEvent: missing id');
    return;
  }
  set(optimisticEventsFamily(props.id), (prevProps) => ({
    ...prevProps,
    ...props,
  }));
  const gridEvent = get(gridEventsFamily(props.id));
  const eventToCreate: IGridEvent = {
    ...gridEvent,
    isDraft: false,
    status: 'confirmed',
  } as IGridEvent;

  if (!eventToCreate?.title) {
    return;
  }
  const timezone = get(timezoneAtom);
  const calendarId = get(userEmailAtom);
  if (!calendarId) {
    console.error(
      '_createGridEvent: no email found on user to set the calendarId'
    );
    return;
  }
  const userEmail = get(userEmailAtom);

  const diff = getEventsDiff({
    oldEvent: {},
    newEvent: eventToCreate,
    whitelistedKeys: ['attendees'],
  });

  const setEventConfirmations = (updateValue: EventConfirmationCallback) =>
    set(eventConfirmationsAtom, updateValue);
  const { notifyGuests, cancelled } = await confirmEventChanges({
    event: eventToCreate,
    type: 'create',
    diff,
    userEmail,
    setEventConfirmations,
  });

  if (cancelled) {
    set(optimisticEventsFamily(eventToCreate.id), (draft) => ({
      ...draft,
      isDraft: true,
      status: 'cancelled',
    }));
    return;
  }

  eventToCreate.attendees = eventToCreate.attendees.map(
    ({ status, ...attendee }) => attendee
  );

  set(eventsSelectionAtom, []);

  const isRecurringEvent = !!eventToCreate.recurrenceRules?.length;
  if (isRecurringEvent) {
    removeEventFromPool(set, eventToCreate.id);
    const eventInstances = generateEventInstances(eventToCreate);
    addBatchEvents(set, eventInstances);
  } else {
    set(serverEventsAtomFamily(eventToCreate.id), eventToCreate);
    set(optimisticEventsFamily(eventToCreate.id), eventToCreate);
    set(eventIdsPoolAtom, (prevIds) => new Set([...prevIds, eventToCreate.id]));
  }

  const normalizedPayload = formatCreateEventPayload(eventToCreate);
  saveServerEventsToCacheAtomCallback(get);
  const createdServerEvent = await createEventMutation({
    ...normalizedPayload,
    notifyGuests,
  });

  if (createdServerEvent) {
    set(
      serverEventsAtomFamily(eventToCreate.id),
      formatServerEvent(createdServerEvent, calendarId, timezone)
    );
  } else {
    console.warn("_createGridEvent: event wasn't updated in the state");
  }
  if (
    createdServerEvent?.location ||
    createdServerEvent?.videoConferences?.length
  ) {
    set(optimisticEventsFamily(eventToCreate.id), (prev) => ({
      ...prev,
      location: createdServerEvent.location,
      videoConferences: createdServerEvent.videoConferences,
    }));
  }
  saveServerEventsToCacheAtomCallback(get);
  return;
}
