import { ColorFamily } from '@graphql-types@';
import { userEmailAtom } from 'hooks/auth/authAtoms';
import { useOwnCalendars } from 'hooks/useCalendars';
import { useVisibleCalendars } from 'hooks/useVisibleCalendars';
import { useAtomValue } from 'jotai/utils';
import { difference } from 'lodash';
import { useMemo } from 'react';
import { AvailableCalendar } from 'types/calendar';

export default function useAvailableCalendars(): AvailableCalendar[] {
  const calendars = useVisibleCalendars();

  const availableCalendars = useMemo(() => {
    return calendars.map((calendar) => ({
      id: calendar.email,
      name: calendar.displayName,
      color: calendar.colorFamily,
    }));
  }, [calendars]);

  return availableCalendars;
}

const colors = [
  ColorFamily.Blue,
  ColorFamily.Green,
  ColorFamily.Orange,
  ColorFamily.Pink,
  ColorFamily.Purple,
  ColorFamily.Red,
  ColorFamily.Yellow,
];

export function useCalendarColor(calendarId?: string): ColorFamily {
  const ownCalendars = useOwnCalendars();
  const primaryColor = usePrimaryColor();

  if (!calendarId || calendarId.length === 0) return ColorFamily.Gray;

  // If calendarId belongs to calendar you own -- return it's color
  const ownCalendar = ownCalendars.find((cal) => cal.id === calendarId);
  if (ownCalendar) return ownCalendar.colorFamily;

  // If it's not calendarId of calendar you own -- find free color and return it
  const freeColors = colors.filter((it) => it !== primaryColor);

  // User used all colorful colors for own calendar
  if (freeColors.length === 0) return ColorFamily.Gray;
  return freeColors[calendarId.length % freeColors.length];
}

/**
 * @returns ColorFamily of user's primary calendar
 */
export function usePrimaryColor(): ColorFamily {
  const userEmail = useAtomValue(userEmailAtom);
  const calendars = useOwnCalendars();
  const primaryCalendar = calendars.find(
    (calendar) => calendar.id === userEmail
  );

  return primaryCalendar?.colorFamily || ColorFamily.Gray;
}
