import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import IconColorSwatch from 'components/Icons/IconColorSwatch';
import {
  ListItem,
  NavItemNestedActions,
  NavItemType,
} from 'components/QuickMenu/types';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useUpdateModal } from 'hooks/useModal';
import { upperFirst } from 'lodash';
import React from 'react';
import { IGridEvent } from 'types/events';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import useSetNavItems from '../useSetNavItems';

export const useChangeEventColorAction = (
  fullEvents: IGridEvent[]
): ListItem => {
  const { addNavItem } = useSetNavItems();
  const { saveGridEvent } = useUpdateGridEvent();
  const { closeModal } = useUpdateModal();

  const changeEventColorAction = () => {
    const navItem: NavItemNestedActions = {
      type: NavItemType.nestedActions,
      title: `Colors`,
      inputPlaceholder: 'Change color',
      hidden: true,
      actions: Object.entries(EVENT_COLOR_MAP).map(
        ([colorFamily, colorProp], _idx) => ({
          id: `set-event-color-${colorFamily}`,
          title: upperFirst(colorFamily),
          keywords: [colorFamily],
          type: 'action',
          actionOverride: () => {
            Promise.all(
              fullEvents.map((fullEvent) => {
                saveGridEvent({
                  id: fullEvent.id,
                  colorFamily: colorFamily as ColorFamily,
                });
              })
            ).catch(console.error);

            closeModal();
          },
          Icon: () => (
            <span
              className={classNames(
                'h-5 w-5 scale-100 transform rounded-full border-2 transition-transform hover:scale-110 active:scale-100',
                colorProp.colorSwatchBg,
                colorProp.border,
                colorProp.ring,
                {
                  'ring-2 ring-opacity-40 ring-offset-1 ring-offset-white dark:ring-offset-gray-600':
                    colorFamily === fullEvents[0]?.colorFamily,
                }
              )}
            />
          ),
        })
      ),
    };

    addNavItem({
      type: navItem.type,
      fullObject: navItem,
    });
  };

  return {
    id: 'changeColor',
    title: 'Change color',
    keywords: ['change', 'color'],
    type: 'action',
    actionOverride: changeEventColorAction,
    Icon: () => <IconColorSwatch className="h-5 w-5 p-0.5 text-gray-400" />,
  };
};
