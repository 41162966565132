import { EmojiData } from './emojis';

export const EMOJI_SHORTCODE_REGEX = /:[A-Za-z0-9]*(?:-[A-Za-z0-9]*)*:?/;

export const searchEmoji = async (term: string): Promise<EmojiData[]> => {
  // TODO: Process search in a WebWorker. This uses a lot of main thread rn.
  const { default: emojis } = await import('./emojis');

  const results = emojis.filter((emoji) => {
    const hasMainShortcut = emoji.shortcut.indexOf(term) > -1;
    const hasAlternate = emoji.alias?.some(
      (alternate) => alternate.indexOf(term) > -1
    );

    if (hasMainShortcut || hasAlternate) {
      return emoji;
    }
  });

  return results.slice(0, 5);
};

// Matches a :shortcode: beginning inside a longer string
export const SHORTCODE_REGEX = /:(?![/\d\s])([-+\w]+)?:?/i;
