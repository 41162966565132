import React from 'react';
import { ComboBoxItem, ComboBoxItemOption } from 'joy/ComboBox';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { UserPreferences_Select_Column } from '@graphql-types@';
import { PreferenceName } from 'types/preference';
import {
  AMIE_VIDEO_LINK_PLACEHOLDER,
  AMIE_VIDEO_URL_REGEX,
  AROUND_URL_REGEX,
  MEET_LINK_PLACEHOLDER,
  MEET_LINK_PLACEHOLDER_DRAFT,
  MEET_URL_REGEX,
  ZOOM_LINK_PLACEHOLDER,
  ZOOM_URL_REGEX,
} from 'utils/video';
import { usePreferences } from 'hooks/usePreferences';
import { SettingsPageType } from 'components/Settings/types';
import { DropdownItem, DropdownItemOption } from 'joy/Dropdown';
import IconTrash from 'components/Icons/IconTrash';
import IconArrowRight from 'components/Icons/IconArrowRight';
import IconCopy from 'components/Icons/IconCopy';
import type { useFullUser } from 'hooks/auth/authAtoms';
interface VideoProviderProps {
  name: string;
  regex: RegExp;
  iconSrc: string;
  fixedValue?: string;
  preferenceLink?: UserPreferences_Select_Column;
}
interface VideoProviderOptionsProps {
  features: ReturnType<typeof useFeatureFlag>;
  fullUser: ReturnType<typeof useFullUser>;
  currentProvider?: VideoProviderProps | null;
  preferences: ReturnType<typeof usePreferences>;
  openSettingsTab?: (tab: SettingsPageType) => void;
  onDropdownItemSelect?: (item: string) => Promise<void>;
  isDraft?: boolean;
  readOnly?: boolean;
}

export const DROPDOWN_TITLE_VIDEO = 'Video call';
export const DROPDOWN_TITLE_PLACES = 'Places';

const callData: VideoProviderProps[] = [
  {
    name: 'Amie Video',
    regex: AMIE_VIDEO_URL_REGEX,
    fixedValue: AMIE_VIDEO_LINK_PLACEHOLDER,
    iconSrc: `/assets/logo.svg`,
  },
  {
    name: 'Around',
    preferenceLink: PreferenceName.AroundLink,
    regex: AROUND_URL_REGEX,
    iconSrc: `/assets/video-providers/around@3x.png`,
  },
  {
    name: 'Meet',
    fixedValue: MEET_LINK_PLACEHOLDER_DRAFT,
    regex: MEET_URL_REGEX,
    iconSrc: `/assets/video-providers/meet@3x.png`,
  },
  {
    name: 'Zoom',
    fixedValue: ZOOM_LINK_PLACEHOLDER,
    regex: ZOOM_URL_REGEX,
    iconSrc: `/assets/video-providers/zoom@3x.png`,
  },
];

export const getCurrentProvider = (value: string) =>
  callData.find(
    (data) =>
      value === data.fixedValue ||
      data.regex.test(value) ||
      value === `Loading ${data.fixedValue} link`
  );

export const getContextMenuOptions = ({
  value,
  onClickCopy,
  onRemove,
  readOnly,
}: {
  value?: string;
  onClickCopy: () => void;
  onRemove: () => void;
  readOnly?: boolean;
}) => {
  const contextMenuItems: DropdownItem[] = [];
  const canCopy = !(
    MEET_LINK_PLACEHOLDER === value ||
    MEET_LINK_PLACEHOLDER_DRAFT === value ||
    AMIE_VIDEO_LINK_PLACEHOLDER === value
  );

  if (canCopy) {
    contextMenuItems.push({
      type: 'option',
      icon: <IconCopy className="h-4 w-4" />,
      value: `Copy link`,
      onSelect: onClickCopy,
    });
  }

  if (!readOnly) {
    contextMenuItems.push({
      type: 'option',
      icon: <IconTrash className="h-4 w-4" />,
      value: `Remove link`,
      onSelect: onRemove,
    });
  }

  return {
    contextMenuItems,
  };
};

export const getVideoProviderOptions = ({
  features,
  fullUser,
  currentProvider,
  preferences,
  openSettingsTab,
  onDropdownItemSelect,
  isDraft,
}: VideoProviderOptionsProps) => {
  const comboBoxItemOptions: ComboBoxItem[] = [];
  const dropdownItemOptions: DropdownItem[] = [];
  const isVideoLink = !!currentProvider;
  const hasZoomConnection = !!fullUser?.zoomRefreshToken;
  const hasAroundLink = !!preferences.aroundLink;
  const moreProvidersAvailable = !hasZoomConnection && !hasAroundLink;

  callData.map((data) => {
    if (data.fixedValue === AMIE_VIDEO_LINK_PLACEHOLDER && !features.video)
      return;

    const isSelectedProvider = currentProvider?.name === data.name;

    const isPreferredProvider =
      preferences.videoProvider === data.name.toUpperCase();

    const preferenceLink =
      data.preferenceLink === 'zoomLink'
        ? preferences.zoomLink
        : data.preferenceLink === 'aroundLink'
        ? preferences.aroundLink
        : undefined;

    const isZoomAndConnected = hasZoomConnection && data.name === 'Zoom';
    const isAroundAndConnected = hasAroundLink && data.name === 'Around';
    const isAmieVdeoAndConnected = features.video && data.name === 'Amie Video';
    const isMeet = data.name === 'Meet';

    let link: string = preferenceLink || data.fixedValue || '';

    if (isMeet && isDraft) {
      link = MEET_LINK_PLACEHOLDER_DRAFT;
    }

    const isAvailable =
      isZoomAndConnected ||
      isAroundAndConnected ||
      isAmieVdeoAndConnected ||
      isMeet;

    const videoComboBoxItem: ComboBoxItemOption = {
      type: 'option',
      value: link || '',
      text: currentProvider
        ? `Change to ${data.name}`
        : `Add ${data.name} link`,
      icon: (
        <img
          className="mr-2 h-4 w-4"
          src={data.iconSrc}
          alt={`${data.name} icon`}
        />
      ),
    };

    const videoDropdownItem: DropdownItemOption = {
      type: 'option',
      value:
        isVideoLink && currentProvider
          ? `Change to ${data.name}`
          : `Add ${data.name} link`,

      icon: (
        <img className="h-4 w-4" src={data.iconSrc} alt={`${data.name} icon`} />
      ),
      onSelect: () => onDropdownItemSelect?.(link),
    };

    if (!isSelectedProvider && isPreferredProvider) {
      // if this is the preferred provider and not selected, place first in the list
      comboBoxItemOptions.unshift(videoComboBoxItem);
      dropdownItemOptions.unshift(videoDropdownItem);
    } else if (isAvailable && !isSelectedProvider && !isPreferredProvider) {
      // add the rest of the available providers to the list
      comboBoxItemOptions.push(videoComboBoxItem);
      dropdownItemOptions.push(videoDropdownItem);
    }
  });

  if (dropdownItemOptions.length > 0) {
    dropdownItemOptions.push({
      type: 'separator',
    });
  }

  if (moreProvidersAvailable) {
    if (comboBoxItemOptions.length > 0) {
      comboBoxItemOptions.push({
        type: 'separator',
      });
    }
    comboBoxItemOptions.push({
      type: 'option',
      value: 'settings',
      icon: <IconArrowRight width={16} height={16} className="mr-2" />,
      text: `Connect others`,
      onSelect: () => openSettingsTab?.(SettingsPageType.Meetings),
    });

    dropdownItemOptions.push({
      type: 'option',
      icon: <IconArrowRight className="h-4 w-4" />,
      value: `Connect others`,
      onSelect: () => openSettingsTab?.(SettingsPageType.Meetings),
    });
  }

  dropdownItemOptions.push({
    type: 'option',
    icon: <IconTrash className="h-4 w-4" />,
    value: `Remove link`,
    onSelect: () => onDropdownItemSelect?.(''),
  });

  return { comboBoxItemOptions, dropdownItemOptions };
};
