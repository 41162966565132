import { useUpdateEffect } from '@react-aria/utils';
import { atom, useAtom } from 'jotai';
import { LOCALSTORAGE_IS_SIDEPANEL_EXPANDED } from 'utils/constants';

const scaledAtom = atom(false);
const resizingAtom = atom(false);
export const expandedAtom = atom(getStoredExpandedValue());

export default function useInterface() {
  const [isScaled, setIsScaled] = useAtom(scaledAtom);
  const [isSidepanelResizing, setIsSidepanelResizing] = useAtom(resizingAtom);
  const [isSidepanelExpanded, setIsSidepanelExpanded] = useAtom(expandedAtom);

  useUpdateEffect(() => {
    setStoredExpandedValue(isSidepanelExpanded);
  }, [isSidepanelExpanded]);

  return {
    isScaled,
    setIsScaled,
    isSidepanelResizing,
    setIsSidepanelResizing,
    isSidepanelExpanded,
    setIsSidepanelExpanded,
  };
}

function getStoredExpandedValue(): boolean {
  if (typeof window === 'undefined') return true;
  const value = window.localStorage.getItem(LOCALSTORAGE_IS_SIDEPANEL_EXPANDED);
  if (value === 'false') return false;
  return true;
}

function setStoredExpandedValue(value: boolean): void {
  if (typeof window === 'undefined') return;

  window.localStorage.setItem(
    LOCALSTORAGE_IS_SIDEPANEL_EXPANDED,
    value.toString()
  );
}
