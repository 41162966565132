import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path
        d="M8.67 10.18c-.324.426-1.016.426-1.34 0L4.646 6.665c-.375-.492.012-1.164.67-1.164h5.365c.659 0 1.046.672.671 1.164L8.671 10.18z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgComponent = React.memo(SvgComponent);
export default MemoSvgComponent;
