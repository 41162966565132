import IconClock from 'components/Icons/IconClock';
import {
  ListItem,
  NavItemChronoActions,
  NavItemType,
} from 'components/QuickMenu/types';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useUpdateModal } from 'hooks/useModal';
import React from 'react';
import { IGridEvent } from 'types/events';
import { ChronoGroupResult } from '../NestedQuickMenuGroups/useChronoGroup';
import useSetNavItems from '../useSetNavItems';

export const useChangeTimeAction = (fullEvents: IGridEvent[]): ListItem => {
  const { saveGridEvent } = useUpdateGridEvent();
  const { closeModal } = useUpdateModal();
  const { addNavItem } = useSetNavItems();
  const eventDuration = fullEvents[0]?.endAt.diff(fullEvents[0].startAt);

  const changeEventTimeAction = () => {
    const navItem: NavItemChronoActions = {
      type: NavItemType.chronoActions,
      title: `Choose date`,
      inputPlaceholder: 'Type a date...',
      hidden: true,
      eventDurationMinutes: eventDuration.as('minutes'),
      callback: (selection: ChronoGroupResult) => {
        let newEnd = selection.end;

        // If there's no end, or the end isn't parseable (an end time that's sooner than the start time),
        // then recalculate the end time.
        if (!selection.end || selection.end <= selection.start) {
          newEnd = selection.start.plus(eventDuration);
        }

        saveGridEvent({
          id: fullEvents[0].id,
          startAt: selection.start,
          endAt: newEnd,
        });

        closeModal();
      },
    };

    addNavItem({
      type: navItem.type,
      fullObject: navItem,
    });
  };

  return {
    id: 'changeTime',
    title: `Change time/date`,
    type: 'eventsSelectionOption',
    Icon: () => <IconClock className="h-5 w-5 p-0.5 text-gray-400" />,
    actionNameOverride: 'Change',
    actionOverride: changeEventTimeAction,
  };
};
