import useSetNavItems from 'components/QuickMenu/hooks/useSetNavItems';
import { SettingsPageType } from 'components/Settings/types';
import { atom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { EventName } from 'types/analytics';
import { ModalType } from 'types/modal';
import { trackEvent } from 'utils/analytics';
import { releaseFocusTrap } from './useFocusTrap';

export const modalAtom = atom<ModalType>(ModalType.None);
export const settingsPageAtom = atom<SettingsPageType>(
  SettingsPageType.Profile
);

export function useUpdateModal() {
  const { resetNavItems } = useSetNavItems();
  const setModal = useUpdateAtom(modalAtom);
  const openModal = useCallback(
    (modalType: ModalType) => {
      if (modalType === ModalType.QuickMenu) {
        trackEvent(EventName.ToggledQuickMenu);
      }

      setModal(modalType);
    },
    [setModal]
  );

  const closeModal = useCallback(
    (modalType?: ModalType) => {
      setModal((currentModal) => {
        if (modalType && modalType !== currentModal) {
          return currentModal;
        }

        if (currentModal === ModalType.QuickMenu) {
          resetNavItems();
        }

        releaseFocusTrap();

        return ModalType.None;
      });
    },
    [resetNavItems, setModal]
  );
  return {
    openModal,
    closeModal,
  };
}

export function useIsAnyModalOpen(): boolean {
  return useAtomValue(modalAtom) !== null;
}

export function useModalValue(): ModalType {
  return useAtomValue(modalAtom);
}

export function useIsModalOpen(modal: ModalType) {
  return useAtomValue(modalAtom) === modal;
}

export function useSettingPageModal() {
  return useAtomValue(settingsPageAtom);
}

export function useUpdateSettingPageModal() {
  return useUpdateAtom(settingsPageAtom);
}
