import classNames from 'classnames';
import { ListItem } from 'components/QuickMenu/types';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useUpdateModal } from 'hooks/useModal';
import React, { useCallback, useMemo } from 'react';
import { IGridEvent } from 'types/events';
import { EVENT_COLOR_MAP } from 'utils/eventColors';

export const useAllDayAction = (fullEvents: IGridEvent[]): ListItem => {
  const { saveGridEvent } = useUpdateGridEvent();
  const { closeModal } = useUpdateModal();

  const isEveryEventAllDay = useMemo(
    () => !fullEvents.some((x) => !x.isAllDay),
    [fullEvents]
  );

  const toggleAllDay = useCallback((): void => {
    fullEvents.forEach((event) => {
      saveGridEvent({
        id: event.id,
        isAllDay: !isEveryEventAllDay,
      });
    });

    closeModal();
  }, [isEveryEventAllDay, fullEvents, saveGridEvent, closeModal]);

  const allDayEventIcon = useCallback(
    () => (
      <span
        className={classNames(
          'text-secondary -mr-0.5 rounded-md text-xs font-semibold',
          {
            [`${EVENT_COLOR_MAP['gray'].buttonAllDayEnabled} ${EVENT_COLOR_MAP['gray'].text}`]:
              isEveryEventAllDay,
            ['cursor-default']: true,
          }
        )}
      >
        24h
      </span>
    ),
    [isEveryEventAllDay]
  );

  return {
    id: 'toggleAllDay',
    title: `Toggle all day`,
    type: 'eventsSelectionOption',
    Icon: allDayEventIcon,
    actionNameOverride: 'Toggle',
    actionOverride: toggleAllDay,
  };
};
