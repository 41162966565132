/* eslint-disable react/display-name */
import { useFullEventsAtom } from 'hooks/useEventsSelection';
import { useRef } from 'react';
import { HookProps, ListGroup } from '../types';
import { filterBySearch } from './useListGroups';
import { useChangeEventColorAction } from './EventActions/useChangeEventColorAction';
import { useDoneEventActions } from './EventActions/useDoneEventActions';
import { useAllDayAction } from './EventActions/useAllDayAction';
import { useChangeTimeAction } from './EventActions/useChangeTimeAction';
import { useInviteGuestAction } from './EventActions/useInviteGuestAction';
import { useChangeLocationAction } from './EventActions/useChangeLocationAction';
import { useChangeRecurrenceAction } from './EventActions/useChangeRecurrenceAction';
import { useChangePrivacyAction } from './EventActions/useChangePrivacyAction';
import { useDeleteEventAction } from './EventActions/useDeleteEventAction';

export default function useEventsSelectionGroup({
  enabled,
  eventIds,
  searchTerm,
}: HookProps): ListGroup {
  const listGroupRef = useRef<ListGroup>({
    title: 'Options',
    items: [],
  });
  listGroupRef.current.items.length = 0;

  const fullEvents = useFullEventsAtom(eventIds);
  const editableEvents = fullEvents.filter((event) => event.canEdit);
  const attendingEvents = fullEvents.filter((event) => event.isSelfAsAttendee);

  const changeEventColorListItem = useChangeEventColorAction(attendingEvents);
  const doneListItems = useDoneEventActions(attendingEvents);
  const allDayListItem = useAllDayAction(editableEvents);
  const changeTimeAction = useChangeTimeAction(editableEvents);
  const inviteGuestAction = useInviteGuestAction(editableEvents);
  const changeLocationAction = useChangeLocationAction(editableEvents);
  const changeRecurrenceAction = useChangeRecurrenceAction(editableEvents);
  const changePrivacyAction = useChangePrivacyAction(editableEvents);
  const deleteEventAction = useDeleteEventAction(editableEvents);

  if (!enabled || !fullEvents.length) return listGroupRef.current;

  if (editableEvents.length === 1) {
    listGroupRef.current.items.push(
      changeTimeAction,
      inviteGuestAction,
      changeLocationAction,
      changeRecurrenceAction
    );
  }

  if (editableEvents.length > 0) {
    listGroupRef.current.items.push(
      changePrivacyAction,
      deleteEventAction,
      allDayListItem
    );
  }

  listGroupRef.current.items.push(...doneListItems, changeEventColorListItem);

  listGroupRef.current.items = filterBySearch(
    listGroupRef.current.items,
    searchTerm
  );
  return listGroupRef.current;
}
