import { sidebarWidthAtom } from 'components/Panels/SidePanel';
import {
  CONTACTPANEL_WIDTH,
  GRID_TIMESTAMPS_WIDTH,
  TODO_HEADER_SECTION_HEIGHT,
} from 'components/Panels/utils';
import {
  dragTodoAtom,
  ghostDraftEventIdAtom,
} from 'components/Todos/todosAtoms';
import {
  categoryFamily,
  colorFamilyToColor,
  isDraggingTodoAtom,
  useIsMouseInRange,
} from 'components/Todos/utils';
import { userEmailAtom } from 'hooks/auth/authAtoms';
import { throttle } from 'frame-throttle';
import { gridEventsFamily } from 'hooks/events/eventAtoms';
import { generateEventUUID } from 'hooks/events/helpers/eventsHelpers';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useCalendarDays } from 'hooks/useCalendar';
import { useAccentColor } from 'hooks/usePreferences';
import { useAtomCallback, useAtomValue } from 'jotai/utils';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { coordinatesToTargetDate } from 'utils/mouseEvents';
import {
  getGridMouseCoordinates,
  __LowPerf__getGridInteractionHandlerBoundingRect,
} from './clickHandler/GridInteractionsHandler';

export function GridTodoGhost() {
  const {
    createDraftEvent,
    deleteDraftEvent,
    updateGridEventForInteractionOnly,
  } = useUpdateGridEvent();
  const isDraggingTodo = useAtomValue(isDraggingTodoAtom);
  const pointDateRef = useRef<DateTime | null>();
  const days = useCalendarDays();
  const accentColor = useAccentColor();

  const gridDimensionsRef = useRef<DOMRect | null>(null);

  useEffect(() => {
    if (isDraggingTodo) {
      // TODO Get rid of this
      gridDimensionsRef.current =
        __LowPerf__getGridInteractionHandlerBoundingRect();
    }
  }, [isDraggingTodo]);

  const sidebarWidth = useAtomValue(sidebarWidthAtom);

  const isOverGrid = useIsMouseInRange({
    x: CONTACTPANEL_WIDTH + sidebarWidth + GRID_TIMESTAMPS_WIDTH + 7,
    y: TODO_HEADER_SECTION_HEIGHT,
  });

  const handleMouseMove = useAtomCallback(
    useCallback(
      (get, set) => {
        const todoDraftEventId = get(ghostDraftEventIdAtom);
        const draftEvent = get(gridEventsFamily(todoDraftEventId || ''));
        const hasDraftEvent = Boolean(draftEvent) && !!todoDraftEventId;
        const dragTodo = get(dragTodoAtom);

        if (!dragTodo || !gridDimensionsRef.current) {
          return;
        }

        if (isOverGrid) {
          // Check if our cursor position is above schedule
          const gridCoordinates = getGridMouseCoordinates();
          const pointDate = coordinatesToTargetDate({
            coordinates: gridCoordinates,
            height: gridDimensionsRef.current.height,
            width: gridDimensionsRef.current.width,
            roundToNearestQuarterHour: true,
            startDay: days[0].date,
            endDay: days[days.length - 1].date,
          });
          const prevPointDate = pointDateRef.current;
          pointDateRef.current = pointDate;

          if (pointDate) {
            // Only update draft event if the point date is different from previous
            if (
              prevPointDate &&
              Math.abs(prevPointDate.diff(pointDate).as('minutes')) < 15
            ) {
              return;
            }

            // Update draft event if it exists
            if (hasDraftEvent) {
              updateGridEventForInteractionOnly({
                id: todoDraftEventId,
                startAt: pointDate,
                endAt: pointDate.plus({
                  minutes: 30,
                }),
              });
            } else {
              // Create new draft event if it does not exists
              const category = get(categoryFamily(dragTodo.categoryId));

              const colorFamily = category?.colorFamily
                ? colorFamilyToColor(category.colorFamily)
                : accentColor;

              if (dragTodo) {
                const primaryCalendarId = get(userEmailAtom) || 'default';
                const newDraftEventId = generateEventUUID(primaryCalendarId);
                set(ghostDraftEventIdAtom, newDraftEventId);
                createDraftEvent({
                  id: newDraftEventId,
                  title: dragTodo.name || 'New scheduled todo',
                  startAt: pointDate,
                  doneAt: dragTodo.doneAt,
                  colorFamily,
                  endAt: pointDate.plus({
                    minutes: 30,
                  }),
                });
              }
            }
          }
        } else if (hasDraftEvent) {
          // Clean up existing draft event once we drag away from schedule
          deleteDraftEvent();
          set(ghostDraftEventIdAtom, null);
          return;
        }
      },
      [
        isOverGrid,
        days,
        updateGridEventForInteractionOnly,
        accentColor,
        createDraftEvent,
        deleteDraftEvent,
      ]
    )
  );

  const rafHandleMouseMove = useMemo(
    () => throttle(handleMouseMove),
    [handleMouseMove]
  );

  useEffect(() => {
    if (isDraggingTodo) {
      document.addEventListener('mousemove', rafHandleMouseMove);
    } else {
      document.removeEventListener('mousemove', rafHandleMouseMove);
    }
    return () => {
      document.removeEventListener('mousemove', rafHandleMouseMove);
    };
  }, [isDraggingTodo, rafHandleMouseMove]);

  return null;
}
