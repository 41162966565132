import { eventsSelectionAtom } from 'hooks/useEventsSelection';
import { atom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';

export const baseSlotIdAtom = atom<null | string>(null);
export const selectedSlotIdAtom = atom<null | string, null | string>(
  (get) => {
    return get(baseSlotIdAtom);
  },
  (_, set, value) => {
    set(eventsSelectionAtom, (prevEventsSelection) =>
      prevEventsSelection.length > 0 ? [] : prevEventsSelection
    );
    set(baseSlotIdAtom, value);
  }
);

export function useSelectedSlotId() {
  return useAtomValue(selectedSlotIdAtom);
}

export function useUpdateSelectedSlotId() {
  return useUpdateAtom(selectedSlotIdAtom);
}
