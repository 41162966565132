import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import {
  ListItemAction,
  NavItemNestedActions,
  NavItemType,
} from 'components/QuickMenu/types';
import { useUpdateCategory } from 'components/Todos/CategoryItem';
import { useUpdateModal } from 'hooks/useModal';
import _ from 'lodash';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import useSetNavItems from '../useSetNavItems';
import React from 'react';
import { TodosQuery } from 'graphql/queries/todos.graphql';
import IconColorSwatch from 'components/Icons/IconColorSwatch';
import { useColorNicknameMap } from 'components/Settings/rows/EventColorRename';

interface Props {
  todoCategory: TodosQuery['categories'][0] | undefined;
}

export const useChangeListColor = ({ todoCategory }: Props): ListItemAction => {
  const setCategoryValue = useUpdateCategory(todoCategory?.id);
  const { addNavItem } = useSetNavItems();
  const { closeModal } = useUpdateModal();
  const colorNicknameMap = useColorNicknameMap();

  return {
    id: 'changeListColor',
    title: 'Change list color',
    keywords: ['change', 'list', 'color'],
    type: 'action',
    actionOverride: () => {
      const navItem: NavItemNestedActions = {
        type: NavItemType.nestedActions,
        title: `Colors`,
        inputPlaceholder: 'Change color',
        hidden: true,
        actions: Object.entries(EVENT_COLOR_MAP).map(
          ([colorName, colorProp], _idx) => ({
            id: `set-list-color-${colorName}`,
            title: _.upperFirst(colorNicknameMap[colorName]),
            keywords: [colorName],
            type: 'action',
            actionOverride: () => {
              closeModal();
              setCategoryValue({ colorFamily: colorName as ColorFamily });
            },
            Icon: () => (
              <span
                className={classNames(
                  'h-5 w-5 scale-100 transform rounded-full border-2 transition-transform hover:scale-110 active:scale-100',
                  colorProp.colorSwatchBg,
                  colorProp.border,
                  colorProp.ring,
                  {
                    'ring-2 ring-opacity-40 ring-offset-1 ring-offset-white dark:ring-offset-gray-600':
                      colorName === todoCategory?.colorFamily,
                  }
                )}
              />
            ),
          })
        ),
      };

      addNavItem({
        type: navItem.type,
        fullObject: navItem,
      });
    },
    Icon: () => <IconColorSwatch className="h-5 w-5 p-0.5 text-gray-400" />,
  };
};
