import useAvailableCalendars from 'components/Panels/useAvailableCalendars';
import { HookProps, ListGroup } from 'components/QuickMenu/types';
import useContactSearch from 'hooks/useContactSearch';
import useFavorites from 'hooks/useFavorites';
import { useUpdateModal } from 'hooks/useModal';
import { useSetVisibleCalendars } from 'hooks/useVisibleCalendars';
import Avatar from 'joy/Avatar';
import React, { useCallback, useMemo, useRef } from 'react';
import { getContactDescription, getContactTitle } from './navItemsHelpers';
import { ModalType } from 'types/modal';

const SEARCH_CONTACTS_RESULT_LIMIT = 4;

export function useFavoritesListGroup({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const favorites = useFavorites();
  const include = useMemo(
    () => favorites.map((c) => c.emailAddress),
    [favorites]
  );

  const { results } = useContactSearch({
    term: searchTerm || '',
    limit: SEARCH_CONTACTS_RESULT_LIMIT,
    include,
  });
  const { closeModal } = useUpdateModal();

  const { addCalendar, removeCalendar } = useSetVisibleCalendars();
  const availableCalendars = useAvailableCalendars();

  const isAvatarActive = useCallback(
    (email: string) => availableCalendars.some((cal) => cal.name === email),
    [availableCalendars]
  );

  const toggleContactVisibility = useCallback(
    (email: string) => {
      if (isAvatarActive(email)) {
        removeCalendar(email);
      } else {
        addCalendar(email);
      }
    },
    [removeCalendar, isAvatarActive, addCalendar]
  );

  const listGroupRef = useRef<ListGroup>({
    title: 'In sidebar',
    items: [],
  });

  listGroupRef.current.items.length = 0;

  if (!enabled) return listGroupRef.current;

  results.forEach((contact) => {
    listGroupRef.current.items.push({
      id: contact.id,
      title: getContactTitle(contact),
      email: contact.emailAddress,
      description: getContactDescription(contact),
      type: 'calendar',
      actionNameOverride: isAvatarActive(contact.emailAddress)
        ? 'Close calendar'
        : 'Open calendar',
      actionOverride: async () => {
        toggleContactVisibility(contact.emailAddress);
        closeModal(ModalType.QuickMenu);
      },
      Icon: () => (
        <Avatar
          src={contact.avatar}
          name={contact.displayName || contact.emailAddress}
        />
      ),
    });
  });

  return listGroupRef.current;
}
