import hotkeys from 'hotkeys-js';
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLayer } from 'react-laag';
import { EmojiData } from 'utils/emojis';
import { EMOJI_SHORTCODE_REGEX, searchEmoji } from 'utils/emojiSearch';
import EmojiPicker from './EmojiPicker';

interface Props {
  value: string;
  onEmojiInsert: (val: string) => void;
}

type TextAreaProps = Omit<
  InputHTMLAttributes<HTMLTextAreaElement>,
  'ref' | 'height'
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function withEmojiPicker(TextComponent: any) {
  return React.forwardRef<HTMLTextAreaElement, TextAreaProps & Props>(
    ({ value, onEmojiInsert, ...domProps }, ref) => {
      // Casting it to a TypeScript friendly object, otherwise we have to resort to nastier workarounds
      // https://stackoverflow.com/questions/62238716/using-ref-current-in-react-forwardref
      const inputRef = ref as React.MutableRefObject<HTMLTextAreaElement>;
      const inputCloneRef = useRef<HTMLTextAreaElement | null>(null);

      const isInsertingEmoji = useRef<boolean>(false);
      const initialEmojiShortcodeCaretPosition = useRef<number | null>(null);
      const [emojiResults, setEmojiResults] = useState<EmojiData[]>([]);
      const [emojiShortcodeInput, setEmojiShortcodeInput] =
        useState<string>('');
      const [hasReplacedEmoji, setHasReplacedEmoji] = useState<boolean>(false);
      const [emojiPickerIndex, setEmojiPickerIndex] = useState<number>(-1);

      let bounds: DOMRect = new DOMRect();

      if (inputCloneRef && inputCloneRef.current) {
        // The layer's position should be the input's x,y + the width of the text up until the caret.
        // To achieve this, we create a hidden copy of the input, but as text, with the same styling (font-size, family, etc.)
        // in order to accurately calculate the width of the text.
        const inputBounds = inputRef.current.getBoundingClientRect();
        const inputCloneBounds = inputCloneRef.current.getBoundingClientRect();
        bounds = new DOMRect(
          inputBounds.x + inputCloneBounds.width,
          inputBounds.y,
          0,
          inputBounds.height
        );
      }

      const { renderLayer, layerProps } = useLayer({
        isOpen: isInsertingEmoji.current && emojiShortcodeInput.length > 1,
        trigger: {
          getBounds: () => bounds,
        },
        triggerOffset: -16,
        auto: true,
        possiblePlacements: ['top-start', 'bottom-start'],
      });

      const moveEmojiSelectionDown = useCallback(() => {
        setEmojiPickerIndex((currentIndex) => {
          if (currentIndex === emojiResults.length - 1) {
            return 0;
          }

          return currentIndex + 1;
        });
      }, [emojiResults]);

      const moveEmojiSelectionUp = useCallback(() => {
        setEmojiPickerIndex((currentIndex) => {
          if (currentIndex === 0) {
            return emojiResults.length - 1;
          }

          return currentIndex - 1;
        });
      }, [emojiResults]);

      const resetEmojiInput = useCallback(() => {
        isInsertingEmoji.current = false;
        initialEmojiShortcodeCaretPosition.current = null;
        setEmojiShortcodeInput('');
      }, [setEmojiShortcodeInput]);

      const insertEmoji = useCallback(
        (emoji: string) => {
          onEmojiInsert(value.replace(emojiShortcodeInput, emoji));
          setHasReplacedEmoji(true);
        },
        [emojiShortcodeInput, value, onEmojiInsert, setHasReplacedEmoji]
      );

      const setCaretToAfterEmoji = useCallback(() => {
        if (
          inputRef.current &&
          initialEmojiShortcodeCaretPosition.current !== null
        ) {
          inputRef.current.selectionStart =
            initialEmojiShortcodeCaretPosition.current + 1;
          inputRef.current.selectionEnd = inputRef.current.selectionStart;
        }
      }, [inputRef, initialEmojiShortcodeCaretPosition]);

      useEffect(() => {
        // actions after a full emoji shortcode match
        if (hasReplacedEmoji) {
          setCaretToAfterEmoji();
          setHasReplacedEmoji(false);
          resetEmojiInput();
        }
      }, [
        emojiPickerIndex,
        emojiResults,
        hasReplacedEmoji,
        resetEmojiInput,
        setCaretToAfterEmoji,
      ]);

      useEffect(() => {
        const searchForEmoji = async () => {
          const results = await searchEmoji(emojiShortcodeInput);
          setEmojiResults(results);
        };

        if (emojiShortcodeInput.length > 1) {
          // 👆🏻 first char will always be `:`
          searchForEmoji();
        }
      }, [emojiShortcodeInput]);

      const onPressEnter = useCallback(() => {
        if (isInsertingEmoji.current) {
          if (emojiResults[emojiPickerIndex]) {
            const emoji = emojiResults[emojiPickerIndex].symbol;
            insertEmoji(emoji);
            setCaretToAfterEmoji();
          } else {
            resetEmojiInput();
          }

          return;
        }
      }, [
        emojiPickerIndex,
        emojiResults,
        insertEmoji,
        resetEmojiInput,
        setCaretToAfterEmoji,
      ]);

      const onKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
          // most apps (whatsapp, telegram, notion) remove the ability to navigate text with arrows while showing the autocomplete emoji picker menu

          if (isInsertingEmoji.current && emojiShortcodeInput.length > 1) {
            if (event.key === 'Enter' && !event.metaKey) {
              event.stopPropagation();
              event.preventDefault();
              onPressEnter();
              return;
            }

            if (hotkeys.isPressed('up')) {
              moveEmojiSelectionUp();
              event.preventDefault();
            } else if (hotkeys.isPressed('down')) {
              moveEmojiSelectionDown();
              event.preventDefault();
            } else if (
              hotkeys.isPressed('left') ||
              hotkeys.isPressed('right')
            ) {
              event.preventDefault();
            }

            return;
          }
        },
        [
          emojiShortcodeInput.length,
          onPressEnter,
          moveEmojiSelectionUp,
          moveEmojiSelectionDown,
        ]
      );

      const onKeyPress = useCallback(
        (event: React.KeyboardEvent) => {
          if (event.key === ':' && !isInsertingEmoji.current) {
            isInsertingEmoji.current = true;

            if (!inputRef.current) return;

            // set the initial caret position
            initialEmojiShortcodeCaretPosition.current =
              inputRef.current.selectionStart;
          }
        },
        [inputRef]
      );

      const onInputChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
          const inputValue =
            event.target.value === '<br>' && hotkeys.isPressed('backspace')
              ? ''
              : event.target.value;

          if (!inputRef.current) return;
          if (
            isInsertingEmoji.current &&
            initialEmojiShortcodeCaretPosition.current !== null
          ) {
            // check the current caret position
            const currentCaretPosition = inputRef.current.selectionStart;

            if (
              currentCaretPosition >= initialEmojiShortcodeCaretPosition.current
            ) {
              const emojiShortcode = inputValue.slice(
                initialEmojiShortcodeCaretPosition.current,
                currentCaretPosition
              );

              const WHITESPACE_REGEX = /[\s&]+/g;
              if (
                emojiShortcode.length === 0 ||
                !EMOJI_SHORTCODE_REGEX.test(emojiShortcode) ||
                WHITESPACE_REGEX.test(emojiShortcode)
              ) {
                resetEmojiInput();
              }

              // save that range as the emoji shortcode
              setEmojiShortcodeInput(emojiShortcode);
              setEmojiPickerIndex(0);
            }
          }

          onEmojiInsert(inputValue);
        },
        [
          inputRef,
          resetEmojiInput,
          onEmojiInsert,
          setEmojiShortcodeInput,
          setEmojiPickerIndex,
        ]
      );

      useEffect(() => {
        // Effect to replace emoji shortcode full match with the real emoji
        if (!isInsertingEmoji.current) return;

        if (
          emojiShortcodeInput.length > 1 && // 👈🏻 first char will always be `:`
          emojiShortcodeInput.startsWith(':') &&
          emojiShortcodeInput.endsWith(':')
        ) {
          // check for a full match
          const lowerCaseShortcode = emojiShortcodeInput.toLowerCase();

          const fullMatch = emojiResults.find(
            (emoji) =>
              emoji.shortcut === lowerCaseShortcode ||
              emoji.alias?.includes(lowerCaseShortcode)
          );

          if (fullMatch) {
            insertEmoji(fullMatch.symbol);
          } else {
            // second `:` was typed without a full match, step out of emoji input mode
            resetEmojiInput();
          }
        }
      }, [emojiResults, emojiShortcodeInput, insertEmoji, resetEmojiInput]);

      return (
        <>
          <TextComponent
            {...domProps}
            ref={inputRef}
            value={value}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
          />
          <span
            ref={inputCloneRef}
            className={domProps.className}
            style={{
              visibility: 'hidden',
              position: 'absolute',
              width: 'fit-content',
              left: '0px',
              top: '0px',
            }}
          >
            {initialEmojiShortcodeCaretPosition?.current != null
              ? value.substring(
                  0,
                  initialEmojiShortcodeCaretPosition?.current +
                    emojiShortcodeInput.length
                )
              : null}
          </span>
          {isInsertingEmoji.current &&
            emojiShortcodeInput.length > 1 && // 👈🏻 first char on emojiShortcodeInput will always be `:`
            renderLayer(
              <div {...layerProps}>
                <EmojiPicker
                  handleClick={(emojiSymbol) => {
                    if (isInsertingEmoji.current) {
                      insertEmoji(emojiSymbol);
                    }
                  }}
                  index={emojiPickerIndex}
                  emojiOptions={emojiResults}
                />
              </div>
            )}
        </>
      );
    }
  );
}
