import IconPlus from 'components/Icons/IconPlus';
import {
  ListItemAction,
  NavItemTextInput,
  NavItemType,
} from 'components/QuickMenu/types';
import { useUpdateCategory } from 'components/Todos/CategoryItem';
import { TOP } from 'components/Todos/constants';
import {
  activeCategoryIdAtom,
  draftCategoryIdAtom,
  todoDraftPositionAtom,
} from 'components/Todos/todosAtoms';
import { TodosQuery } from 'graphql/queries/todos.graphql';
import { expandedAtom } from 'hooks/useInterface';
import { useUpdateModal } from 'hooks/useModal';
import { useUpdateAtom } from 'jotai/utils';
import React from 'react';
import { uuid } from 'utils/toolbox';
import useSetNavItems from '../useSetNavItems';

interface Props {
  todoCategory: TodosQuery['categories'][0] | undefined;
}

export const useCreateNewList = ({ todoCategory }: Props): ListItemAction => {
  const { addNavItem } = useSetNavItems();
  const { closeModal } = useUpdateModal();

  const setIsSidepanelExpanded = useUpdateAtom(expandedAtom);
  const updateActiveCategoryId = useUpdateAtom(activeCategoryIdAtom);
  const updateTodoDraftPosition = useUpdateAtom(todoDraftPositionAtom);
  const updateDraftCategoryId = useUpdateAtom(draftCategoryIdAtom);

  const setCategoryValue = useUpdateCategory(todoCategory?.id);

  return {
    id: 'createNewList',
    title: 'Add todo list',
    keywords: ['create', 'new', 'add', 'list'],
    type: 'action',
    actionNameOverride: 'Add',
    actionOverride: () => {
      const navItem: NavItemTextInput = {
        type: NavItemType.textInput,
        title: `TODO LIST`,
        inputPlaceholder: 'New list title',
        getActionNameOverride: () => 'Add',
        hidden: true,
        showEmptyMessage: false,
        callback: (text: string) => {
          // Open the sidepanel if it is closed.
          setIsSidepanelExpanded(true);

          const newId = uuid();
          setCategoryValue({
            id: newId,
            name: text?.trim(),
            createdAt: new Date().toISOString(),
            after: null,
          });

          // Refer to Todos/CategoryItem.tsx for detailed
          // explanations about each of these steps.
          updateActiveCategoryId(newId);
          updateTodoDraftPosition(TOP);
          updateDraftCategoryId(null);

          closeModal();
        },
      };

      addNavItem({
        type: navItem.type,
        fullObject: navItem,
      });
    },
    Icon: () => <IconPlus className="h-5 w-5 p-1 text-gray-400" />,
  };
};
