import { useRef } from 'react';
import {
  HookProps,
  ListGroup,
  ListItemTimezone,
} from 'components/QuickMenu/types';
import { useUpdateModal } from 'hooks/useModal';
import {
  filterTimezones,
  DisplayTimezone,
  timezoneList,
} from 'hooks/useTimezoneSearch';
import { useUi24HourClock, useUpdatePreference } from 'hooks/usePreferences';
import { PreferenceName } from 'types/preference';
import { recentTimezoneAtom } from 'hooks/useTimeZone';
import { useAtomValue } from 'jotai/utils';
import { DateTime } from 'luxon';
import _ from 'lodash';

export function useRecentTimezonesListGroup({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const TIMEZONES_LIMIT = 50;

  const ui24HourClock = useUi24HourClock();
  const updatePreference = useUpdatePreference();
  const results = filterTimezones(searchTerm || '', TIMEZONES_LIMIT);
  const { closeModal } = useUpdateModal();

  const listGroupRef = useRef<ListGroup>({
    title: 'Recent Timezones',
    items: [],
  });

  const recentTimezones = useAtomValue(recentTimezoneAtom);
  listGroupRef.current.items.length = 0;

  if (!enabled) return listGroupRef.current;

  const displayTimezones: DisplayTimezone[] = searchTerm
    ? results.filter((x) => recentTimezones?.includes(x.tzCode))
    : _.flatMap(
        recentTimezones,
        (x) => timezoneList.find((y) => y.tzCode === x) || []
      );

  const reStripName = /^\w+\//;
  listGroupRef.current.items = displayTimezones.map<ListItemTimezone>(
    (timezone, i) => {
      const location =
        searchTerm &&
        timezone.locations.find((s) => s.toLowerCase().includes(searchTerm));

      const date = DateTime.now().setZone(timezone.tzCode);

      return {
        id: i.toString(),
        time: ui24HourClock ? date.toFormat('HH:mm') : date.toFormat('h:mm a'),
        title:
          location ||
          timezone.tzCode.replace(reStripName, '').replace(/_/g, ' '),
        description: `${timezone.timeDiff}`,
        type: 'timezone',
        actionOverride: () => {
          updatePreference(PreferenceName.Timezone, timezone.tzCode);
          closeModal();
        },
        Icon: () => null,
      };
    }
  );

  return listGroupRef.current;
}
