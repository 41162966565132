/* 5284186309b9bad153feba0af9f03ec7e6048f16
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RetrieveColorNicknamesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RetrieveColorNicknamesQuery = { __typename?: 'query_root', colorNickname: Array<{ __typename?: 'colorNickname', color: string, nickname: string }> };


export const RetrieveColorNicknamesDocument = gql`
    query RetrieveColorNicknames {
  colorNickname {
    color
    nickname
  }
}
    `;

export function useRetrieveColorNicknamesQuery(options?: Omit<Urql.UseQueryArgs<RetrieveColorNicknamesQueryVariables>, 'query'>) {
  return Urql.useQuery<RetrieveColorNicknamesQuery>({ query: RetrieveColorNicknamesDocument, ...options });
};