import {
  NewEventVisibilityEnum,
  NewEventRsvpEnum,
  NewVideoConferenceProvider,
} from '@graphql-types@';
import React, { useCallback, useState } from 'react';
import { IGridEvent } from '../../types/events';
import ContextMenu, {
  ContextMenuType,
  useUpdateContextMenu,
} from 'joy/ContextMenu';
import { DropdownItem } from 'joy/Dropdown';
import EventRsvpStatus from 'components/EventPopover/EventRsvpStatus';
import IconCheckboxMark from 'components/Icons/IconCheckboxMark';
import IconLockClosed from 'components/Icons/IconLockClosed';
import IconLockOpen from 'components/Icons/IconLockOpen';
import IconCall from 'components/Icons/IconCall';
import IconExternalLink from 'components/Icons/IconExternalLink';
import IconMapPin from 'components/Icons/IconMapPin';
import IconTrash from 'components/Icons/IconTrash';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { SaveGridEventProps } from 'hooks/events/eventsAtomCallbacks/saveEventAtomCallback';
import { useGetFullEventsSelection } from 'hooks/useEventsSelection';
import { useUserEmail } from 'hooks/auth/authAtoms';
import classNames from 'classnames';
import {
  getLocationMetadata,
  getVideoProvider,
  handleVideoClick,
  MEET_LINK_PLACEHOLDER_DRAFT,
} from 'utils/video';
import { isDraftEvent } from './utils';

export default function GridEventContextMenu({
  event,
  children,
}: {
  event: IGridEvent;
  children: React.ReactElement;
}) {
  const { ctxMenuItems, onContextMenuClose, onContextMenuOpen } =
    useGridEventContextMenu(event);
  return (
    <ContextMenu
      items={ctxMenuItems}
      onClose={onContextMenuClose}
      placement={'right-start'}
      className="h-full w-full"
      contextMenuAtClickPosition={true}
    >
      <div onContextMenu={onContextMenuOpen} className="h-full w-full">
        {children}
      </div>
    </ContextMenu>
  );
}

export function useGridEventContextMenu(event?: IGridEvent | null) {
  const { openContextMenu, closeContextMenu } = useUpdateContextMenu();
  const { updateGridEvent, saveGridEvent, deleteGridEvent } =
    useUpdateGridEvent();
  const getFullEventsSelection = useGetFullEventsSelection();
  const [ctxMenuItems, setCtxMenuItems] = useState<DropdownItem[]>([]);
  const userEmail = useUserEmail();

  const onContextMenuClose = useCallback(() => {
    closeContextMenu(ContextMenuType.Event);
  }, [closeContextMenu]);

  const setItems = useCallback(async () => {
    const fullEventsSelection = await getFullEventsSelection();

    const videoConference = event?.videoConferences[0];
    const eventLocation = videoConference?.link || event?.location || '';
    const { isExternal, isVideoLink, href } =
      getLocationMetadata(eventLocation);
    const videoProvider = getVideoProvider(eventLocation);

    const allEventsPrivate = [...fullEventsSelection, event].every(
      (e) => e?.visibility == NewEventVisibilityEnum.Private
    );

    const allEventsDone = [...fullEventsSelection, event].every(
      (e) => !!e?.doneAt
    );

    const ownAllEvents = [...fullEventsSelection, event].every(
      (e) => e?.isOwnEvent
    );

    const belongToAllEvents = [...fullEventsSelection, event].every(
      (e) => e?.belongsToUserCalendar
    );

    const allEventsRsvpable = [...fullEventsSelection, event].every(
      (e) =>
        e?.attendees.length !== 0 && e?.rsvp !== NewEventRsvpEnum.NotInvited
    );

    const allEventsRsvpYes = [...fullEventsSelection, event].every(
      (e) => e?.attendees.length !== 0 && e?.rsvp === NewEventRsvpEnum.Yes
    );

    const allEventsRsvpNo = [...fullEventsSelection, event].every(
      (e) => e?.attendees.length !== 0 && e?.rsvp === NewEventRsvpEnum.No
    );

    // a single event can be both highlighted (`EventPopover` open) and right clicked
    const singleEvent =
      [...fullEventsSelection, event].reduce((acc, e) => {
        return acc.add(e?.id);
      }, new Set()).size === 1;

    function updateEvent(arg: Partial<SaveGridEventProps>) {
      const saveOrUpdate = isDraftEvent(event)
        ? updateGridEvent
        : saveGridEvent;

      if (fullEventsSelection.length > 1) {
        fullEventsSelection.forEach((selectedEvent) => {
          saveOrUpdate({
            ...arg,
            id: selectedEvent.id,
          });
        });
      } else if (event) {
        saveOrUpdate({
          ...arg,
          id: event.id,
        });
      }
    }

    const toggleCheck = () => {
      const doneAt = allEventsDone ? null : new Date().toISOString();

      updateEvent({ doneAt });
    };

    const togglePrivate = () => {
      const visibility = allEventsPrivate
        ? NewEventVisibilityEnum.Public
        : NewEventVisibilityEnum.Private;

      updateEvent({ visibility });
    };

    const setRsvp = (value: NewEventRsvpEnum) => {
      return () => updateEvent({ rsvp: value });
    };

    const openLocation = () => {
      if (!isVideoLink) {
        window.open(href, '_blank', 'noopener noreferrer');
      } else {
        handleVideoClick({
          url:
            videoProvider === MEET_LINK_PLACEHOLDER_DRAFT
              ? `${eventLocation}?authuser=${userEmail}`
              : eventLocation,
        });
      }
    };

    const deleteEvents = async () => {
      if (fullEventsSelection.length > 1) {
        fullEventsSelection.forEach((selectedEvent) =>
          deleteGridEvent({
            eventId: selectedEvent.id,
          })
        );
      } else if (event) {
        deleteGridEvent({
          eventId: event.id,
        });
      }
    };

    const items: DropdownItem[] = [
      {
        type: 'option',
        value: allEventsDone ? 'Mark as undone' : 'Mark as done',
        icon: (
          <div
            className={classNames(
              'flex items-center justify-center rounded-md',
              'shadow-checkbox-currentColor dark:shadow-checkbox-currentColor'
            )}
            style={{ height: 16, width: 16 }}
          >
            {!allEventsDone && <IconCheckboxMark className="h-4 w-4" />}
          </div>
        ),
        disabled: !belongToAllEvents,
        onSelect: toggleCheck,
      },
      {
        type: 'option',
        value: isVideoLink
          ? getVideoLinkTitle(videoConference?.provider)
          : isExternal
          ? 'Open URL'
          : 'Open in maps',
        icon: isVideoLink ? (
          <IconCall />
        ) : isExternal ? (
          <IconExternalLink />
        ) : (
          <IconMapPin />
        ),
        disabled: !belongToAllEvents || !href || !singleEvent,
        onSelect: openLocation,
      },
      {
        type: 'option',
        disabled: !ownAllEvents,
        value: allEventsPrivate ? 'Make public' : 'Make private',
        icon: allEventsPrivate ? <IconLockOpen /> : <IconLockClosed />,
        onSelect: togglePrivate,
      },
      {
        type: 'separator',
      },
      {
        type: 'option',
        value: 'Going',
        selected: allEventsRsvpable && allEventsRsvpYes,
        icon: (
          <EventRsvpStatus className="mr-1" status={NewEventRsvpEnum.Yes} />
        ),
        disabled: !belongToAllEvents || !allEventsRsvpable,
        onSelect: setRsvp(NewEventRsvpEnum.Yes),
      },
      {
        type: 'option',
        value: 'Not Going',
        selected: allEventsRsvpable && allEventsRsvpNo,
        icon: <EventRsvpStatus className="mr-1" status={NewEventRsvpEnum.No} />,
        disabled: !belongToAllEvents || !allEventsRsvpable,
        onSelect: setRsvp(NewEventRsvpEnum.No),
      },
      {
        type: 'separator',
      },
      {
        type: 'option',
        value: 'Delete',
        icon: <IconTrash />,
        variant: 'red',
        disabled: !ownAllEvents,
        onSelect: deleteEvents,
      },
    ];
    setCtxMenuItems(items);
  }, [
    getFullEventsSelection,
    event,
    deleteGridEvent,
    updateGridEvent,
    saveGridEvent,
    userEmail,
  ]);

  const onContextMenuOpen = useCallback(() => {
    openContextMenu(ContextMenuType.Event);
    setItems();
  }, [openContextMenu, setItems]);

  return {
    onContextMenuClose,
    onContextMenuOpen,
    ctxMenuItems,
  };
}

function getVideoLinkTitle(provider: NewVideoConferenceProvider | undefined) {
  if (!provider) return 'Join Call';

  switch (provider) {
    case NewVideoConferenceProvider.Around:
      return 'Join Around';
    case NewVideoConferenceProvider.GoogleMeet:
      return 'Join Meet';
    case NewVideoConferenceProvider.Jitsi:
      return 'Join Jitsi';
    case NewVideoConferenceProvider.MsTeams:
      return 'Join Teams';
    case NewVideoConferenceProvider.Whereby:
      return 'Join Whereby';
    case NewVideoConferenceProvider.Zoom:
      return 'Join Zoom';
    case NewVideoConferenceProvider.None:
      return 'Join Call';
  }
}
